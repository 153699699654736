.page-marketing {
	&__text {
		margin-bottom: vm(48);

		@include desktop {
			margin-bottom: vw(104);
		}

		@include desktop-xl {
			margin-bottom: 104px;
		}
	}

	&__benefits {
		margin-bottom: vm(48);

		&__title {
			@include desktop {
				margin-bottom: vw(50);
			}

			@include desktop-xl {
				margin-bottom: 50px;
			}
		}

		&__list {
			@include desktop {
				display: flex;
				flex-wrap: wrap;
				margin: 0 vw(-50);
			}

			@include desktop-xl {
				margin: 0 -50px;
			}
		}

		&__item {
			display: flex;
			margin-bottom: vm(24);

			&__icon {
				flex-shrink: 0;
				margin-right: vm(15);

				img {
					width: vm(46);

					@include desktop {
						width: vw(52);
					}

					@include desktop-xl {
						width: 52px;
					}
				}

				@include desktop {
					margin-right: vw(24);
				}

				@include desktop-xl {
					margin-right: 24px;
				}
			}

			&__content {
				width: 100%;

				@include desktop {
					line-height: vw(20);
				}

				@include desktop-xl {
					line-height: 20px;
				}
			}

			&__title {
				margin-bottom: vm(8);
				font-weight: 700;

				@include desktop {
					margin-bottom: vw(8);
				}

				@include desktop-xl {
					margin-bottom: 8px;
				}
			}

			&__text {
				font-size: vm(14);

				@include desktop {
					font-size: vw(14);
				}

				@include desktop-xl {
					font-size: 14px;
				}
			}

			@include desktop {
				margin-bottom: vw(32);
				padding: 0 vw(50);
				width: 50%;
			}

			@include desktop-xl {
				margin-bottom: 32px;
				padding: 0 50px;
			}
		}

		&__row {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vm(-10) 0;

			@include desktop {
				margin: vw(50) vw(-10) 0;
			}

			@include desktop-xl {
				margin: 50px -10px 0;
			}
		}

		&__col {
			margin-bottom: vm(8);
			padding: 0 vm(10);
			width: 100%;

			@include desktop {
				margin-bottom: 0;
				padding: 0 vw(10);
				width: 50%;
			}

			@include desktop-xl {
				padding: 0 10px;
			}
		}

		&__image {
			img {
				border-radius: vm(8);

				@include desktop {
					border-radius: vw(16);
				}

				@include desktop-xl {
					border-radius: 16px;
				}
			}
		}

		@include desktop {
			margin-bottom: vw(104);
		}

		@include desktop-xl {
			margin-bottom: 104px;
		}
	}

	&__support {
		margin-bottom: vm(18);

		&__title {
			@include desktop {
				margin-bottom: vw(50);
			}

			@include desktop-xl {
				margin-bottom: 50px;
			}
		}

		&__row {
			display: flex;
			flex-wrap: wrap;
			// justify-content: center;
			margin: 0 vm(-10);

			@include desktop {
				margin: 0 vw(-60);
			}

			@include desktop-xl {
				margin: 0 -60px;
			}
		}

		&__col {
			margin-bottom: vm(40);
			padding: 0 vm(10);
			width: 100%;

			@include desktop {
				margin-bottom: vw(60);
				padding: 0 vw(60);
				width: 25%;
			}

			@include desktop-xl {
				margin-bottom: 60px;
				padding: 0 60px;
			}
		}

		&__item {
			&__image {
				margin-bottom: vm(16);
				text-align: center;

				img {
					border-radius: vm(8);
					width: vm(283);

					@include desktop {
						border-radius: vw(16);
						width: 100%;
					}

					@include desktop-xl {
						border-radius: 16px;
					}
				}

				@include desktop {
					margin-bottom: vw(20);
				}

				@include desktop-xl {
					margin-bottom: 20px;
				}
			}

			&__title {
				margin-top: 0;
				text-align: center;
			}

			&__text {
				text-align: justify;
			}
		}

		@include desktop {
			margin-bottom: vw(74);
		}

		@include desktop-xl {
			margin-bottom: 74px;
		}
	}

	&__work {
		margin-bottom: vm(48);

		&__title {
			@include desktop {
				margin-bottom: vw(50);
				max-width: vw(1193);
			}

			@include desktop-xl {
				margin-bottom: 50px;
				max-width: 1193px;
			}
		}

		&__slider {
			position: relative;

			.swiper-container {
				@include desktop {
					overflow: visible;
				}
			}

			.swiper-slide {
				width: auto;
				height: auto;
			}
		}

		&__item {
			display: flex;
			flex-direction: column;
			box-sizing: border-box;

			&__head {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: vm(16);

				@include desktop {
					margin-bottom: vw(24);
				}

				@include desktop-xl {
					margin-bottom: 24px;
				}
			}

			&__number {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				margin: 0 vm(16) 0 0;
				border: 1px solid $color-monza;
				border-radius: 50%;
				width: vm(50);
				height: vm(50);

				@include desktop {
					margin: 0 vm(20) 0 0;
					width: vw(60);
					height: vw(60);
				}

				@include desktop-xl {
					margin: 0 20px 0 0;
					width: 60px;
					height: 60px;
				}
			}

			&__title {
				margin: 0;
			}

			&__text {
				margin-bottom: vm(16);

				@include desktop {
					margin-bottom: vw(24);
				}

				@include desktop-xl {
					margin-bottom: 24px;
				}
			}

			&__list {
				margin-bottom: vm(16);

				ul {
					display: flex;
					flex-wrap: wrap;

					li {
						@include desktop {
							margin: 0 0 vw(10);
							padding-right: vw(22);
							width: 50%;
						}

						@include desktop-xl {
							margin: 0 0 10px;
							padding-right: 22px;
						}
					}
				}

				@include desktop {
					margin-bottom: vw(14);
				}

				@include desktop-xl {
					margin-bottom: 14px;
				}
			}

			.styled-list ul li + li {
				@include desktop {
					margin-top: 0;
				}
			}

			&__row {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin: auto vm(-10) vm(-8);

				@include desktop {
					margin: auto vw(-10) 0;
				}

				@include desktop-xl {
					margin: auto -10px 0;
				}
			}

			&__col {
				margin-bottom: vm(8);
				padding: 0 vm(10);
				width: 100%;

				@include desktop {
					margin-bottom: 0;
					padding: 0 vw(10);
					width: 50%;
				}

				@include desktop-xl {
					padding: 0 10px;
				}
			}

			&__image {
				text-align: center;
			}

			@include desktop {
				width: vw(760);
				height: 100%;
			}

			@include desktop-xl {
				width: 760px;
			}
		}

		&__next,
		&__prev {
			position: absolute;
			top: 50%;
			z-index: 5;
			transform: translateY(-50%);

			&.swiper-button-disabled {
				opacity: 0;
				visibility: hidden;
				pointer-events: none;
			}
		}

		&__prev {
			left: vm(32);

			@include desktop {
				left: vw(48);
			}

			@include desktop-xl {
				left: 48px;
			}
		}

		&__next {
			right: vm(32);

			@include desktop {
				right: vw(48);
			}

			@include desktop-xl {
				right: 48px;
			}
		}

		@include desktop {
			margin-bottom: vw(150);
		}

		@include desktop-xl {
			margin-bottom: 150px;
		}
	}

	.services {
		margin-bottom: 0;
	}
}
