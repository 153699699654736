.page-partners-detail {
	.benefits__col {
		@include mobile {
			order: inherit;
		}
	}

	&__heading {
		margin-bottom: vm(24);

		&__title {
			margin: 0;
		}

		&__button {
			margin-top: vm(16);

			.button {
				@include desktop {
					min-width: vw(283);
				}

				@include desktop-xl {
					min-width: 283px;
				}
			}

			@include desktop {
				margin-top: 0;
				margin-left: auto;
			}
		}

		@include desktop {
			display: flex;
			align-items: center;
			margin-bottom: vw(90);
		}

		@include desktop-xl {
			margin-bottom: 90px;
		}
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 vm(-10) vm(-10);

		@include desktop {
			margin: 0 vw(-10);
		}

		@include desktop-xl {
			margin: 0 -10px;
		}
	}

	&__col {
		margin-bottom: vm(10);
		padding: 0 vm(10);
		width: 100%;

		@media screen and (orientation: landscape) {
			width: 50%;
		}

		@include desktop {
			margin-bottom: 0;
			padding: 0 vw(10);
			width: 25%;
		}

		@include desktop-xl {
			padding: 0 10px;
		}
	}

	&__item {
		position: relative;
		z-index: 5;
		display: block;
		border-radius: vm(8);
		padding: vm(20);
		height: vm(320);
		background: #f8f8f8;

		&__title {
			margin-bottom: vm(16);

			@include desktop {
				margin-bottom: vw(16);
			}

			@include desktop-xl {
				margin-bottom: 16px;
			}
		}

		&__image {
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			background: 50% 100% no-repeat;
			background-size: 85% auto;

			@include desktop {
				background: 50% 100% no-repeat;
				background-size: 100% auto;
			}
		}

		@include desktop {
			border-radius: vw(16);
			padding: vw(56);
			height: vw(542);

			&:hover {
				color: $color-white;
				background: radial-gradient(81.97% 81.27% at 52.53% 100%, rgba(255, 255, 255, 0.27) 0%, rgba(255, 255, 255, 0) 100%), #000;
			}
		}

		@include desktop-xl {
			border-radius: 16px;
			padding: 56px;
			height: 542px;
		}
	}
}
