.accordion {
	margin-bottom: vm(48);

	&__item {
		border: solid 1px #f8f8f8;
		border-radius: vm(8);
		padding: vm(40) vm(24);
		background: #f8f8f8;
		transition: $duration-normal;
		cursor: pointer;

		&__head {
			position: relative;

			&__text {
				margin: 0;
				padding-right: vm(55);
				transition: $duration-normal;

				@include mobile {
					font-size: vm(24);
					line-height: vm(30);
				}

				@include desktop {
					padding-right: vw(78);
				}

				@include desktop-xl {
					padding-right: 78px;
				}
			}

			&__arrow {
				position: absolute;
				right: 0;
				top: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				width: vm(40);
				height: vm(40);
				background: $color-monza;
				transform: translateY(-50%);

				svg {
					display: block;
					width: vm(20);
					height: vm(20);
					fill: none;
					stroke-width: 2px;
					transform: rotate(180deg);
					transition: $duration-normal;

					@include desktop {
						width: vw(24);
						height: vw(24);
					}

					@include desktop-xl {
						width: 24px;
						height: 24px;
					}
				}

				@include desktop {
					width: vw(48);
					height: vw(48);
				}

				@include desktop-xl {
					width: 48px;
					height: 48px;
				}
			}
		}

		&__content {
			display: none;
			padding-top: vm(32);

			@include desktop {
				padding-top: vw(32);
			}

			@include desktop-xl {
				padding-top: 32px;
			}
		}

		&:hover {
			border-color: $color-monza;

			.accordion__item__head__text {
				color: $color-monza;
			}
		}

		&.is-active {
			.accordion__item__head__arrow {
				svg {
					transform: rotate(0deg);
				}
			}
		}

		& + & {
			margin-top: vm(16);

			@include desktop {
				margin-top: vw(24);
			}

			@include desktop-xl {
				margin-top: 24px;
			}
		}

		@include desktop {
			border-radius: vw(16);
			padding: vw(39);
		}

		@include desktop-xl {
			border-radius: 16px;
			padding: 39px;
		}
	}

	@include desktop {
		margin-bottom: vw(104);
	}

	@include desktop-xl {
		margin-bottom: 104px;
	}
}
