.pagination {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	&__more {
		margin-bottom: vm(32);
		width: 100%;

		@include desktop {
			margin-bottom: vw(48);
		}

		@include desktop-xl {
			margin-bottom: 48px;
		}
	}

	&__prev,
	&__next {
		display: flex;
		align-items: center;
		justify-content: center;
		border: solid 1px #ccc;
		border-radius: 50%;
		width: vm(56);
		height: vm(56);
		font-weight: 700;
		text-align: center;
		background: $color-white;

		svg {
			width: vm(25);
			height: vm(24);

			@include desktop {
				width: vw(25);
				height: vw(24);
			}

			@include desktop-xl {
				width: 25px;
				height: 24px;
			}
		}

		&.is-active {
			border-color: $color-monza;
			color: $color-white;
			background: $color-monza;
		}

		@include desktop {
			width: vw(56);
			height: vw(56);

			&:hover {
				border-color: $color-monza;
				color: $color-white;
				background: $color-monza;
			}
		}

		@include desktop-xl {
			width: 56px;
			height: 56px;
		}
	}

	&__prev {
		margin-right: vm(16);

		svg {
			transform: rotate(180deg);
		}

		@include desktop {
			margin-right: vw(16);
		}

		@include desktop-xl {
			margin-right: 16px;
		}
	}

	&__next {
		margin-left: vm(16);

		@include desktop {
			margin-left: vw(16);
		}

		@include desktop-xl {
			margin-left: 16px;
		}
	}

	ul {
		display: flex;

		li {
			+ li {
				margin-left: vm(4);

				@include desktop {
					margin-left: vw(4);
				}

				@include desktop-xl {
					margin-left: 4px;
				}
			}

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				border: solid 1px #ccc;
				border-radius: 50%;
				width: vm(56);
				height: vm(56);
				font-weight: 700;
				text-align: center;
				background: $color-white;

				&.is-active {
					border-color: $color-monza;
					color: $color-white;
					background: $color-monza;
				}

				@include mobile {
					font-size: vm(16);
				}

				@include desktop {
					width: vw(56);
					height: vw(56);

					&:hover {
						border-color: $color-monza;
						color: $color-white;
						background: $color-monza;
					}
				}

				@include desktop-xl {
					width: 56px;
					height: 56px;
				}
			}
		}
	}
}
