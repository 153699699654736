/**
* Remove nth elements from the list
*
* @property {map} $list List
* @property {number} $index Item index
*
* @example
*	$list: remove-nth(10px 20px 30px 40px, 3); // 10px 20px 40px
*/

@function remove-nth($list, $index) {
	$result: null;

	@if type-of($index) != number {
		@warn "$index: #{quote($index)} is not a number for `remove-nth`.";
	} @else if $index == 0 {
		@warn "List index 0 must be a non-zero integer for `remove-nth`.";
	} @else if abs($index) > length($list) {
		@warn "List index is #{$index} but list is only #{length($list)} item long for `remove-nth`.";
	} @else {
		$result: ();
		$index: if($index < 0, length($list) + $index + 1, $index);

		@for $i from 1 through length($list) {
			@if $i != $index {
				$result: append($result, nth($list, $i));
			}
		}
	}

	@return $result;
}
