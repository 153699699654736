.table {
	margin-bottom: vm(48);

	table {
		border-collapse: collapse;
		width: 100%;
		text-align: left;

		th,
		td {
			border-bottom: solid 1px #ccc;
			padding: vm(24) vm(20);

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}

			@include desktop {
				padding: vw(24) vw(30);
			}

			@include desktop-xl {
				padding: 24px 30px;
			}
		}

		th {
			vertical-align: bottom;
			padding-top: 0;
			padding-bottom: vm(16);
			font-weight: 700;

			@include desktop {
				padding-bottom: vw(21);
			}

			@include desktop-xl {
				padding-bottom: 21px;
			}
		}

		td {
			vertical-align: top;
		}

		@include mobile {
			min-width: vm(940);
		}
	}

	a {
		border-bottom: solid 1px currentColor;

		@include desktop {
			&:hover {
				color: $color-monza;
			}
		}
	}

	@include mobile {
		overflow: auto;

		&::-webkit-scrollbar {
			width: 3px;
			height: 3px;
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $color-monza;
		}
	}

	@include desktop {
		margin-bottom: vw(104);
	}

	@include desktop-xl {
		margin-bottom: 104px;
	}
}
