.hero {
	position: relative;
	z-index: 5;
	display: flex;
	align-items: flex-end;
	margin: 0 0 vm(10);
	padding: vm(160) 0 vm(48);
	min-height: vm(340);
	color: $color-white;
	background: $color-black;

	&__image {
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		box-shadow: inset 0 vm(40) vm(120) rgba(0, 0, 0, 0.5);
		background: 55% 50% no-repeat;
		background-size: cover;

		@include desktop {
			box-shadow: inset 0 vw(40) vw(120) rgba(0, 0, 0, 0.5);
			background: 50% 50% no-repeat;
			background-size: cover;
		}

		@include desktop-xl {
			box-shadow: inset 0 40px 120px rgba(0, 0, 0, 0.5);
		}
	}

	.breadcrumbs {
		color: $color-white;

		@include desktop {
			color: rgba($color-white, 0.6);

			a {
				color: $color-white;
			}
		}
	}

	&__title {
		@include desktop {
			max-width: vw(1192);
		}

		@include desktop-xl {
			max-width: 1192px;
		}
	}

	&__text {
		@include desktop {
			max-width: vw(670);
		}

		@include desktop-xl {
			max-width: 670px;
		}
	}

	&__text--width-1192 {
		@include desktop {
			max-width: vw(1192);
		}

		@include desktop-xl {
			max-width: 1192px;
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: vm(16);

		@include desktop {
			flex-direction: row;
			margin-top: vw(24);
			align-items: center;
		}

		@include desktop-xl {
			margin-top: 24px;
		}
	}

	&__date {
		margin-bottom: 10px;

		@include desktop {
			margin-right: vm(8);
			margin-bottom: 0;
		}

		span {
			display: inline-block;
			vertical-align: middle;
			border: 1px solid $color-monza;
			border-radius: vm(16);
			padding: 0 vm(10);
			min-width: vm(110);
			font-weight: 500;
			font-size: vm(10);
			line-height: vm(22);
			letter-spacing: 0.1em;
			text-align: center;
			text-transform: uppercase;
			color: $color-white;
			background: $color-monza;

			@include desktop {
				border-radius: vw(16);
				padding: 0 vw(10);
				min-width: vw(110);
				font-size: vw(12);
				line-height: vw(22);
			}

			@include desktop-xl {
				border-radius: 16px;
				padding: 0 10px;
				min-width: 110px;
				font-size: 12px;
				line-height: 22px;
			}
		}

		@include desktop {
			margin-right: vw(8);
		}

		@include desktop-xl {
			margin-right: 8px;
		}
	}

	&__tag {
		display: inline-block;
		vertical-align: middle;
		margin-right: vm(8);
		border: solid 1px $color-white;
		border-radius: vm(16);
		padding: 0 vm(10);
		min-width: vm(110);
		font-weight: 500;
		font-size: vm(10);
		line-height: vm(22);
		letter-spacing: 0.1em;
		text-align: center;
		text-transform: uppercase;

		@include desktop {
			margin-right: vw(8);
			border-radius: vw(16);
			padding: 0 vw(10);
			min-width: vw(110);
			font-size: vw(12);
			line-height: vw(22);
		}

		@include desktop-xl {
			margin-right: 8px;
			border-radius: 16px;
			padding: 0 10px;
			min-width: 110px;
			font-size: 12px;
			line-height: 22px;
		}
	}

	@include mobile {
		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 55%;
			background: linear-gradient(0deg, #191919 0%, rgba(25, 25, 25, 0) 100%);
		}
	}

	@include desktop {
		margin: vw(40) 0 vw(80);
		padding: vw(80) 0;
		height: vw(585);
		min-height: 0;
	}

	@include desktop-xl {
		margin: 40px 0 80px;
		padding: 80px 0;
		height: 585px;
	}
}

.hero--styles {
	@include mobile {
		padding-top: vm(295);

		.hero__image {
			background: 73% 9% no-repeat;
			background-size: vm(809);
		}
	}
}

.hero--detail {
	@include desktop {
		height: vw(900);
	}

	@include desktop-xl {
		height: 900px;
	}
}

.hero--partners {
	.hero__image {
		@include mobile {
			background: 40% 50% no-repeat;
			background-size: cover;
		}
	}
}

.hero--about {
	@include mobile {
		&::before {
			height: 100%;
		}

		.hero__image {
			background: 76% 50% no-repeat;
			background-size: cover;
		}
	}
}

.hero--margin {
	margin-bottom: 0;
}
