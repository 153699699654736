.page-not-found {
	position: relative;
	z-index: 5;
	background: #050505;

	&__image {
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		background: 100% 13% no-repeat;
		background-size: 100%;

		@include desktop {
			background: 100% 50% no-repeat;
			background-size: vw(1464);
		}

		@include desktop-xl {
			background-size: 1464px;
		}
	}

	&__content {
		padding: vm(250) 0 vm(40);
		color: $color-white;

		@include desktop {
			padding: vw(319) 0 vw(349);
			max-width: vw(587);
		}

		@include desktop-xl {
			padding: 319px 0 349px;
			max-width: 587px;
		}
	}

	&__title {
		@include desktop {
			max-width: vw(363);
		}

		@include desktop-xl {
			max-width: 363px;
		}
	}

	&__text {
		margin-bottom: vm(24);

		@include desktop {
			margin-bottom: vw(40);
		}

		@include desktop-xl {
			margin-bottom: 40px;
		}
	}

	&__links {
		&__item {
			display: flex;

			&__icon {
				flex-shrink: 0;
				margin-right: vm(16);

				svg {
					width: vm(20);
					height: vm(20);
					fill: none;

					@include desktop {
						width: vw(20);
						height: vw(20);
					}

					@include desktop-xl {
						width: 20px;
						height: 20px;
					}
				}

				@include desktop {
					margin-right: vw(16);
				}

				@include desktop-xl {
					margin-right: 16px;
				}
			}

			&:hover {
				color: $color-monza;
			}

			& + & {
				margin-top: vm(16);

				@include desktop {
					margin-top: 0;
					margin-left: vw(16);
					border-left: 1px solid rgba(255, 255, 255, 0.2);
					padding-left: vw(16);
				}

				@include desktop-xl {
					margin-left: 16px;
					padding-left: 16px;
				}
			}
		}

		@include desktop {
			display: flex;
		}
	}

	@include desktop {
		display: flex;
		align-items: center;
		min-height: calc(100vh - #{vw(608)});
	}

	@include desktop-xl {
		min-height: calc(100vh - 608px);
	}
}
