.page-partners {
	&__list {
		margin-bottom: vm(40);
		padding: vm(38) 0 vm(55);
		background: #f6f6f6;

		&__block {
			@include desktop {
				display: flex;
			}
		}

		&__left {
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		&__item {
			display: flex;
			margin-bottom: vm(24);

			&__icon {
				flex-shrink: 0;
				margin-right: vm(23);

				img {
					width: vm(32);

					@include desktop {
						width: vw(32);
					}

					@include desktop-xl {
						width: 32px;
					}
				}

				@include desktop {
					margin-right: vw(23);
				}

				@include desktop-xl {
					margin-right: 23px;
				}
			}

			&__text {
				margin: 0;
				width: 100%;
				color: rgba($color-black, 0.2);
				transition: $duration-normal;

				@include desktop {
					line-height: vw(36);

					&:hover {
						color: $color-monza;
					}
				}

				@include desktop-xl {
					line-height: 36px;
				}
			}

			&.is-active {
				.page-partners__list__item__text {
					color: $color-black;
				}
			}

			@include desktop {
				margin-bottom: vw(40);
			}

			@include desktop-xl {
				margin-bottom: 40px;
			}
		}

		&__button {
			margin-top: vm(24);

			.button {
				@include desktop {
					min-width: vw(283);
				}

				@include desktop-xl {
					min-width: 283px;
				}
			}

			@include desktop {
				margin-top: auto;
			}
		}

		&__right {
			width: 100%;

			&__item {
				display: none;

				&.is-active {
					display: block;
					animation: tabShow $duration-normal;
				}
			}

			@include desktop {
				flex-shrink: 0;
				margin-left: vw(172);
				width: vw(1192);
			}

			@include desktop-xl {
				margin-left: 172px;
				width: 1192px;
			}
		}

		&__row {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vm(-5) vm(-10);

			@include desktop {
				margin: 0 vw(-10) vw(-20);
			}

			@include desktop-xl {
				margin: 0 -10px -20px;
			}
		}

		&__col {
			margin-bottom: vm(10);
			padding: 0 vm(5);
			width: 50%;

			@include desktop {
				margin-bottom: vw(20);
				padding: 0 vw(10);
				width: 25%;
			}

			@include desktop-xl {
				margin-bottom: 20px;
				padding: 0 10px;
			}
		}

		&__image {
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			border-radius: vm(8);
			height: vm(80);
			background: $color-white;

			img {
				max-width: vm(130);
				max-height: vm(60);
				object-fit: contain;
			}

			@include desktop {
				border-radius: vw(16);
				height: vw(144);
				filter: grayscale(100%);

				img {
					max-width: vw(200);
					max-height: vw(100);
				}

				&:hover {
					box-shadow: 0 vw(7) vw(10) rgba($color-black, 0.25);
					filter: grayscale(0);
				}
			}

			@include desktop-xl {
				border-radius: 16px;
				height: 144px;

				img {
					max-width: 200px;
					max-height: 100px;
				}

				&:hover {
					box-shadow: 0 7px 10px rgba($color-black, 0.25);
				}
			}
		}

		@include desktop {
			margin-bottom: vw(137);
			padding: vw(104) 0;
		}

		@include desktop-xl {
			margin-bottom: 137px;
			padding: 104px 0;
		}
	}
}
