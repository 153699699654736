.page-localization {
	&__text {
		margin-bottom: vm(48);

		@include desktop {
			margin-bottom: vw(104);
			max-width: vw(800);
		}

		@include desktop-xl {
			margin-bottom: 104px;
			max-width: 800px;
		}
	}

	&__best {
		margin-bottom: vm(8);

		&__title {
			@include desktop {
				margin-bottom: vw(50);
			}

			@include desktop-xl {
				margin-bottom: 50px;
			}
		}

		&__row {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vm(-20);

			@include desktop {
				margin: 0 vw(-20);
			}

			@include desktop-xl {
				margin: 0 -20px;
			}
		}

		&__col {
			margin-bottom: vm(40);
			padding: 0 vm(20);
			width: 100%;

			& + & {
				.page-localization__best__item {
					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: vm(-20);
						width: 100%;
						height: 1px;
						background: $color-black;

						@include desktop {
							left: vw(-20);
							top: 0;
							width: 1px;
							height: 100%;
						}

						@include desktop-xl {
							left: -20px;
						}
					}
				}
			}

			@include desktop {
				margin-bottom: 0;
				padding: 0 vw(20);
				width: 25%;
			}

			@include desktop-xl {
				padding: 0 20px;
			}
		}

		&__item {
			position: relative;
			display: flex;
			flex-direction: column;
			height: 100%;

			&__text {
				margin-top: auto;
			}
		}

		@include desktop {
			margin-bottom: vw(104);
		}

		@include desktop-xl {
			margin-bottom: 104px;
		}
	}

	&__studios {
		margin-bottom: vm(48);

		&__title {
			@include desktop {
				margin-bottom: vw(50);
				max-width: vw(1193);
			}

			@include desktop-xl {
				margin-bottom: 50px;
				max-width: 1193px;
			}
		}

		@include desktop {
			margin-bottom: vw(104);
		}

		@include desktop-xl {
			margin-bottom: 104px;
		}
	}

	&__room-tour {
		margin-bottom: vm(48);

		&__title {
			@include desktop {
				margin-bottom: vw(50);
			}

			@include desktop-xl {
				margin-bottom: 50px;
			}
		}

		&__slider {
			position: relative;

			@include mobile {
				margin: 0 vm(-20);
			}
		}

		&__image {
			img {
				@include mobile {
					height: vm(305);
					object-fit: cover;
				}
			}
		}

		&__next,
		&__prev {
			position: absolute;
			top: 50%;
			z-index: 5;
			transform: translateY(-50%);

			&.swiper-button-disabled {
				opacity: 0;
				visibility: hidden;
				pointer-events: none;
			}
		}

		&__prev {
			left: vm(32);

			@include desktop {
				left: vw(48);
			}

			@include desktop-xl {
				left: 48px;
			}
		}

		&__next {
			right: vm(32);

			@include desktop {
				right: vw(48);
			}

			@include desktop-xl {
				right: 48px;
			}
		}

		@include desktop {
			margin-bottom: vw(104);
		}

		@include desktop-xl {
			margin-bottom: 104px;
		}
	}

	.services {
		margin-bottom: 0;
	}
}
