.contacts {
	margin-bottom: vm(48);

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 vm(-10) vm(-20);

		@include desktop {
			margin: 0 vw(-10) vw(-20);
		}

		@include desktop-xl {
			margin: 0 -10px -20px;
		}
	}

	&__col {
		margin-bottom: vm(16);
		padding: 0 vm(10);
		width: 100%;

		@include desktop {
			margin-bottom: vw(20);
			padding: 0 vw(10);
			width: 33.33334%;
		}

		@include desktop-xl {
			margin-bottom: 20px;
			padding: 0 10px;
		}
	}

	&__block {
		position: relative;
		z-index: 5;
		display: flex;
		flex-direction: column;
		border-radius: vm(8);
		padding: vm(70) vm(32) vm(48);
		height: 100%;
		color: $color-white;
		background: $color-black;

		&__image {
			position: absolute;
			right: 0;
			top: 0;
			z-index: -1;
			pointer-events: none;
		}

		&__title {
			margin: 0 0 vm(32);

			@include desktop {
				margin: 0 0 vw(32);
			}

			@include desktop-xl {
				margin: 0 0 32px;
			}
		}

		&__text {
			margin-bottom: vm(24);
			max-width: vm(400);

			@include desktop {
				margin-bottom: vw(24);
				max-width: vw(400);
			}

			@include desktop-xl {
				margin-bottom: 24px;
				max-width: 400px;
			}
		}

		&__footer {
			display: flex;
			margin-top: auto;

			@include mobile {
				flex-direction: column-reverse;
			}

			@include desktop {
				align-items: center;
			}
		}

		&__bottom {
			margin-top: auto;
		}

		&__button {
			flex-shrink: 0;

			.button {
				width: 100%;
				min-width: vm(180);
				font-weight: 700;
				font-size: vm(14);
				letter-spacing: 0;

				@include desktop {
					width: auto;
					min-width: vw(180);
					font-size: vw(14);
				}

				@include desktop-xl {
					min-width: 180px;
					font-size: 14px;
				}
			}

			@include desktop {
				margin-right: vw(40);
			}

			@include desktop-xl {
				margin-right: 40px;
			}
		}

		&__list {
			margin-bottom: vm(24);
			font-weight: 300;
			font-size: vm(18);
			line-height: vm(24);

			li {
				position: relative;
				padding-left: vm(40);

				+ li {
					margin-top: vm(13);

					@include desktop {
						margin-top: vw(8);
					}

					@include desktop-xl {
						margin-top: 8px;
					}
				}

				a {
					@include desktop {
						&:hover {
							color: $color-monza;
						}
					}
				}

				@include desktop {
					padding-left: vw(50);
				}

				@include desktop-xl {
					padding-left: 50px;
				}
			}

			&__icon {
				position: absolute;
				left: 0;
				top: 0;
				width: vm(24);
				height: vm(24);

				@include desktop {
					width: vw(30);
					height: vw(30);
				}

				@include desktop-xl {
					width: 30px;
					height: 30px;
				}
			}

			@include desktop {
				margin-bottom: 0;
				font-size: vw(20);
				line-height: vw(32);
			}

			@include desktop-xl {
				font-size: 20px;
				line-height: 32px;
			}
		}

		&--cooperation {
			background: $color-monza;

			.contacts__block__image {
				img {
					width: vm(170);

					@include desktop {
						width: vw(275);
					}

					@include desktop-xl {
						width: 275px;
					}
				}

				@include mobile {
					top: vm(-15);
				}
			}
		}

		&--consultation {
			.contacts__block__image {
				img {
					width: vm(109);

					@include desktop {
						width: vw(177);
					}

					@include desktop-xl {
						width: 177px;
					}
				}
			}
		}

		&--presentation {
			.contacts__block__image {
				img {
					width: vm(102);

					@include desktop {
						width: vw(221);
					}

					@include desktop-xl {
						width: 221px;
					}
				}
			}
		}

		@include desktop {
			border-radius: vw(16);
			padding: vw(88) vw(36) vw(88) vw(72);
		}

		@include desktop-xl {
			border-radius: 16px;
			padding: 88px 36px 88px 72px;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	@include desktop {
		margin-bottom: vw(104);
	}

	@include desktop-xl {
		margin-bottom: 104px;
	}
}

.contacts--2 {
	.contacts__col {
		@include desktop {
			width: 50%;
		}
	}

	.contacts__block__list {
		li {
			+ li {
				@include desktop {
					margin-top: 0;
					margin-left: vw(40);
				}

				@include desktop-xl {
					margin-left: 40px;
				}
			}
		}

		@include desktop {
			display: flex;
		}
	}
}
