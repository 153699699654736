.page-game-detail {
	&__title {
		@include desktop {
			margin-bottom: vw(50);
		}

		@include desktop-xl {
			margin-bottom: 50px;
		}
	}

	&__text {
		margin-bottom: vm(40);

		@include desktop {
			margin-bottom: vw(90);
			max-width: vw(890);
		}

		@include desktop-xl {
			margin-bottom: 90px;
			max-width: 890px;
		}
	}

	&__row {
		@include desktop {
			display: flex;
		}
	}

	&__list {
		width: 100%;
	}

	&__item {
		display: flex;

		&__icon {
			flex-shrink: 0;
			margin-right: vm(15);

			img {
				width: vm(46);

				@include desktop {
					width: vw(52);
				}

				@include desktop-xl {
					width: 52px;
				}
			}

			@include desktop {
				margin-right: vw(24);
			}

			@include desktop-xl {
				margin-right: 24px;
			}
		}

		&__content {
			width: 100%;

			@include desktop {
				line-height: vw(20);
			}

			@include desktop-xl {
				line-height: 20px;
			}
		}

		&__title {
			margin-bottom: vm(8);
			font-weight: 700;

			@include desktop {
				margin-bottom: vw(8);
			}

			@include desktop-xl {
				margin-bottom: 8px;
			}
		}

		&__text {
			font-size: vm(14);

			@include desktop {
				font-size: vw(14);
			}

			@include desktop-xl {
				font-size: 14px;
			}
		}

		& + & {
			margin-top: vm(24);

			@include desktop {
				margin-top: vw(32);
			}

			@include desktop-xl {
				margin-top: 32px;
			}
		}
	}

	&__video {
		position: relative;
		display: block;
		align-self: flex-start;
		margin-top: vm(24);
		border-radius: vm(8);
		width: 100%;

		&__play {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: vm(10);
			transform: translate(-50%, -50%);

			svg {
				width: vm(56);
				height: vm(56);

				@include desktop {
					width: vw(86);
					height: vw(86);
				}

				@include desktop-xl {
					width: 86px;
					height: 86px;
				}
			}

			@include desktop {
				margin-left: vw(10);
			}

			@include desktop-xl {
				margin-left: 10px;
			}
		}

		@include desktop {
			flex-shrink: 0;
			margin-top: 0;
			margin-left: vw(20);
			border-radius: vw(16);
			width: vw(586);
		}

		@include desktop-xl {
			margin-left: 20px;
			border-radius: 16px;
			width: 586px;
		}
	}

	.goods {
		margin-top: vm(40);

		@include desktop {
			margin-top: vw(80);
		}

		@include desktop-xl {
			margin-top: 80px;
		}
	}
}
