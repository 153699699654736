.page-qa {
	&__kinds {
		margin-bottom: vm(48);

		&__title {
			@include desktop {
				margin-bottom: vw(50);
			}

			@include desktop-xl {
				margin-bottom: 50px;
			}
		}

		&__row {
			display: flex;

			@include mobile {
				flex-wrap: wrap;
			}
		}

		&__list {
			width: 100%;
		}

		&__item {
			display: flex;

			&__icon {
				flex-shrink: 0;
				margin-right: vm(15);

				img {
					width: vm(46);

					@include desktop {
						width: vw(52);
					}

					@include desktop-xl {
						width: 52px;
					}
				}

				@include desktop {
					margin-right: vw(24);
				}

				@include desktop-xl {
					margin-right: 24px;
				}
			}

			&__content {
				width: 100%;

				@include desktop {
					line-height: vw(20);
				}

				@include desktop-xl {
					line-height: 20px;
				}
			}

			&__title {
				margin-bottom: vm(8);
				font-weight: 700;

				@include desktop {
					margin-bottom: vw(8);
				}

				@include desktop-xl {
					margin-bottom: 8px;
				}
			}

			&__text {
				font-size: vm(14);

				@include desktop {
					font-size: vw(14);
				}

				@include desktop-xl {
					font-size: 14px;
				}
			}

			& + & {
				margin-top: vm(24);

				@include desktop {
					margin-top: vw(32);
				}

				@include desktop-xl {
					margin-top: 32px;
				}
			}
		}

		&__image {
			margin-top: vm(24);

			img {
				border-radius: vm(8);
				width: 100%;

				@include desktop {
					border-radius: vw(16);
				}

				@include desktop-xl {
					border-radius: 16px;
				}
			}

			@include desktop {
				flex-shrink: 0;
				margin-top: 0;
				margin-left: vw(160);
				width: vw(890);
			}

			@include desktop-xl {
				margin-left: 160px;
				width: 890px;
			}
		}

		@include desktop {
			margin-bottom: vw(104);
		}

		@include desktop-xl {
			margin-bottom: 104px;
		}
	}

	&__technical {
		margin-bottom: vm(40);

		&__title {
			@include desktop {
				margin-bottom: vw(50);
			}

			@include desktop-xl {
				margin-bottom: 50px;
			}
		}

		&__text {
			margin-bottom: vm(24);

			@include desktop {
				margin-bottom: vw(60);
				max-width: vw(800);
			}

			@include desktop-xl {
				margin-bottom: 60px;
				max-width: 800px;
			}
		}

		&__row {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vm(-5) vm(8);

			@include desktop {
				margin: 0 vw(-10) vw(60);
			}

			@include desktop-xl {
				margin: 0 -10px 60px;
			}
		}

		&__col {
			margin-bottom: vm(24);
			padding: 0 vm(5);
			width: 100%;

			@include desktop {
				margin-bottom: 0;
				padding: 0 vw(10);
				width: 33.33334%;
			}

			@include desktop-xl {
				padding: 0 10px;
			}
		}

		&__item {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: vm(20);
			height: 100%;
			background: #f2f2f2;

			&__image {
				margin-bottom: vm(16);

				img {
					width: vm(52);

					@include desktop {
						width: vw(52);
					}

					@include desktop-xl {
						width: 52px;
					}
				}

				@include desktop {
					margin-bottom: vw(32);
				}

				@include desktop-xl {
					margin-bottom: 32px;
				}
			}

			&__list {
				ul {
					@include mobile {
						//font-size: vm(12);
						line-height: vm(14);
						font-weight: bold;

						li {
							&::before {
								top: vm(3);
							}
						}
					}
				}
			}

			@include desktop {
				padding: vw(20);
			}

			@include desktop-xl {
				padding: 20px;
			}
		}

		&__inner {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vm(-10);

			&__col {
				margin-bottom: vm(8);
				padding: 0 vm(10);
				width: 100%;

				@include desktop {
					margin-bottom: 0;
					padding: 0 vw(10);
					width: 50%;
				}

				@include desktop-xl {
					padding: 0 10px;
				}
			}

			@include desktop {
				margin: 0 vw(-10);
			}

			@include desktop-xl {
				margin: 0 -10px;
			}
		}

		@include desktop {
			margin-bottom: vw(104);
		}

		@include desktop-xl {
			margin-bottom: 104px;
		}
	}

	.services {
		margin-bottom: 0;
	}
}
