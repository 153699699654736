.modal {
	padding: vm(40) vm(20);
	width: 100%;

	.h2 {
		text-align: center;

		@include desktop {
			margin-bottom: vw(70);
		}

		@include desktop-xl {
			margin-bottom: 70px;
		}
	}

	.input {
		margin-bottom: vm(16);

		@include desktop {
			margin-bottom: vw(16);
		}

		@include desktop-xl {
			margin-bottom: 16px;
		}
	}

	.checkbox {
		margin-top: vm(26);

		@include desktop {
			margin-top: vw(32);
		}

		@include desktop-xl {
			margin-top: 32px;
		}
	}

	@include desktop {
		border-radius: vw(16);
		padding: vw(80);
		max-width: vw(738);
	}

	@include desktop-xl {
		border-radius: 16px;
		padding: 80px;
		max-width: 738px;
	}
}

.modal--alert {
	width: auto;

	@include desktop {
		max-width: vw(460);
	}

	@include desktop-xl {
		max-width: 460px;
	}
}
