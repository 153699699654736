*,
*::before,
*::after {
	box-sizing: inherit;
}

*::selection {
	color: $color-white;
	background: $color-monza;
}

html {
	position: relative;
	min-height: 100vh;
	box-sizing: border-box;
	font-family: $font-primary;
	scroll-behavior: smooth;

	&.has-cursor,
	&.has-cursor * {
		// stylelint-disable-next-line
		cursor: none !important;
	}

	&.is-lock-scroll,
	&.is-lock-scroll body {
		overflow: hidden;
	}
}

body {
	font-family: "Root", sans-serif;
	-webkit-font-smoothing: antialiased;
	font-size: vm(14);
	line-height: vm(20);
	text-decoration-skip: objects;
	text-rendering: optimizeLegibility;
	text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
	background: $color-white;

	@include mobile {
		.is-show-menu & {
			overflow: hidden;
		}
	}

	@include desktop {
		font-size: vw(16);
		line-height: vw(24);
	}

	@include desktop-xl {
		font-size: 16px;
		line-height: 24px;
	}
}

main {
	flex: 1;
}

// p {
// 	margin: 0;
// }

a,
button {
	outline: none;
	transition: $duration-normal;
	cursor: pointer;
}

a {
	text-decoration: none;
	color: currentColor;
}

svg {
	display: block;
	flex: 0 0 auto;
	width: 100%;
	height: 100%;
	fill: currentColor;
}

figure,
picture {
	display: inline-block;
	margin: 0;
	line-height: 0;

	img {
		width: 100%;
	}
}

img {
	vertical-align: top;
	max-width: 100%;
	user-select: none;
}

img[draggable="false"] {
	pointer-events: none;
	// stylelint-disable-next-line
	-webkit-user-drag: none;
}

fieldset {
	margin: 0;
	border: 0;
	padding: 0;
}

ul,
ol,
li {
	list-style: none;
	margin: 0;
	padding: 0;
}

video {
	outline: none;
	width: 100%;
	height: 100%;
}

iframe {
	display: block;
}

.js-lazy-load:not(.is-loaded) {
	max-height: 50vh;
}

.container {
	position: relative;
	z-index: 1;
	margin: 0 auto;
	width: 100%;
	max-width: 100%;
}

.site {
	display: flex;
	flex-direction: column;
	min-height: 100vh; // fallback
	// stylelint-disable-next-line
	min-height: var(--vh);
}

/* stylelint-disable */
/*
* Немедленно переместите любую анимацию в конечную точку, если пользователь установил свое устройство в положение "prefers reduced motion".
* Это может привести к плохим(непреднамеренным) последствиям. Удалите по мере необходимости и напишите свой собственный код для prefers-reduced-motion.
*/
// @media (prefers-reduced-motion: reduce) {
// 	*,
// 	*:before,
// 	*:after {
// 		animation-duration: 0.001s !important;
// 		animation-delay: 0s !important;
// 		transition-duration: 0.001s !important;
// 		transition-delay: 0s !important;
// 	}
// }
/* stylelint-enable */
