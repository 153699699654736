.share {
	margin-top: vm(32);

	&__list {
		display: flex;
		flex-wrap: wrap;

		li {
			+ li {
				margin-left: vm(8);

				@include desktop {
					margin-left: vw(8);
				}

				@include desktop-xl {
					margin-left: 8px;
				}
			}

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				border: solid 1px #ccc;
				border-radius: 50%;
				width: vm(40);
				height: vm(40);
				fill: #f8f8f8;

				svg {
					display: block;
					width: vm(16);
					height: vm(16);
					fill: inherit;

					@include desktop {
						width: vw(16);
						height: vw(16);
					}

					@include desktop-xl {
						width: 16px;
						height: 16px;
					}
				}

				@include desktop {
					width: vw(40);
					height: vw(40);

					&:hover {
						border-color: $color-monza;
						color: $color-white;
						fill: $color-monza;
						background: $color-monza;
					}
				}

				@include desktop-xl {
					width: 40px;
					height: 40px;
				}
			}
		}

		@include desktop {
			justify-content: center;
		}
	}

	@include desktop {
		margin-top: vw(20);
		border-radius: vw(16);
		padding: vw(56);
		text-align: center;
		background: #f8f8f8;
	}

	@include desktop-xl {
		margin-top: 20px;
		border-radius: 16px;
		padding: 56px;
	}
}
