.header {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 95;
	width: 100%;

	&__top {
		position: relative;
		z-index: 5;
		background: $color-white;
		transition: $duration-normal;

		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: vw(40);

			@include desktop-xl {
				height: 40px;
			}
		}

		&__menu {
			display: flex;
			font-weight: 500;
			font-size: vw(11);
			line-height: vw(13);
			letter-spacing: 0.05em;
			text-align: center;
			text-transform: uppercase;
			color: #999;

			li {
				position: relative;

				+ li {
					margin-left: vw(30);

					@include desktop-xl {
						margin-left: 30px;
					}
				}

				a {
					color: #999;

					&:hover {
						color: $color-monza;
					}
				}
			}

			@include desktop-xl {
				font-size: 11px;
				line-height: 13px;
			}
		}

		&__socials {
			display: flex;

			li {
				+ li {
					margin-left: vw(12);

					@include desktop-xl {
						margin-left: 12px;
					}
				}

				a {
					color: $color-black;

					svg {
						width: vw(16);
						height: vw(16);

						@include desktop-xl {
							width: 16px;
							height: 16px;
						}
					}

					&:hover {
						color: $color-monza;
					}
				}
			}
		}
	}

	&__bottom {
		position: relative;
		height: vm(40);
		background: $color-white;
		transition: background-color $duration-normal;

		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 100%;
		}

		&__right {
			display: flex;
			align-items: center;
			margin-left: auto;
		}

		@include desktop {
			padding: vw(32) 0;
			height: auto;
			color: $color-white;
			background: transparent;
		}

		@include desktop-xl {
			padding: 32px 0;
		}
	}

	&__burger {
		position: relative;
		display: block;
		outline: none;
		border: none;
		padding: 0;
		width: 30px;
		height: 30px;
		background: none;

		i {
			position: absolute;
			left: vm(7);
			top: 50%;
			width: vm(16);
			height: vm(2);
			background: $color-black;
			transform: translateY(vm(-5));
			transition: $duration-normal;

			+ i {
				transform: translateY(vm(-1));

				+ i {
					transform: translateY(vm(3));
				}
			}
		}

		.is-show-menu & {
			i {
				transform: translateY(vm(-1)) rotate(45deg);

				+ i {
					opacity: 0;
					transform: translateY(vm(-1));

					+ i {
						opacity: 1;
						transform: translateY(vm(-1)) rotate(-45deg);
					}
				}
			}
		}
	}

	&__logo {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		img {
			width: vm(56);

			@include desktop {
				width: vw(84);
			}

			@include desktop-xl {
				width: 84px;
			}
		}

		svg {
			width: vm(56);
			height: vm(30);
			color: $color-monza;
			transition: $duration-normal;

			@include desktop {
				width: vw(84);
				height: vw(44);
				color: $color-white;
			}

			@include desktop-xl {
				width: 84px;
				height: 44px;
			}
		}

		@include desktop {
			position: static;
			left: auto;
			top: auto;
			flex-shrink: 0;
			margin-right: vw(46);
			transform: none;
		}

		@include desktop-xl {
			margin-right: 46px;
		}
	}

	&__menu {
		font-weight: 700;
		font-size: vw(14);
		line-height: vw(17);
		letter-spacing: 0.1em;
		text-align: center;
		text-transform: uppercase;

		ul {
			display: flex;

			li {
				position: relative;

				+ li {
					margin-left: vw(30);

					@include desktop-xl {
						margin-left: 30px;
					}
				}

				a {
					position: relative;
					display: block;
					padding: vw(6) 0;

					&::after {
						content: "";
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						height: vw(2);
						background: $color-white;
						opacity: 0;
						visibility: hidden;
						transition: $duration-normal;

						@include desktop-xl {
							height: 2px;
						}
					}

					&:hover {
						&::after {
							opacity: 1;
							visibility: visible;
						}
					}

					&.is-active {
						&::after {
							opacity: 1;
							visibility: visible;
						}
					}

					@include desktop-xl {
						padding: 6px 0;
					}
				}

				ul {
					position: absolute;
					left: 0;
					top: 100%;
					display: block;
					margin-top: vw(8);
					border-radius: vw(16);
					padding: vw(24);
					box-shadow: 0 vw(6) vw(13) rgba($color-black, 0.05);
					font-size: vw(16);
					line-height: vw(24);
					letter-spacing: 0;
					white-space: nowrap;
					text-align: left;
					text-transform: none;
					background: $color-white;
					opacity: 0;
					visibility: hidden;
					transition: $duration-normal;

					&::before {
						content: "";
						position: absolute;
						left: 0;
						bottom: 100%;
						width: 100%;
						height: vw(8);

						@include desktop-xl {
							height: 8px;
						}
					}

					li {
						+ li {
							margin: vw(8) 0 0;

							@include desktop-xl {
								margin: 8px 0 0;
							}
						}

						a {
							display: inline;
							padding: 0;
							color: $color-black;

							&::after {
								display: none;
							}

							&:hover {
								color: $color-monza;
							}
						}
					}

					@include desktop-xl {
						margin-top: 8px;
						border-radius: 16px;
						padding: 24px;
						box-shadow: 0 6px 13px rgba($color-black, 0.05);
						font-size: 16px;
						line-height: 24px;
					}
				}

				&:hover {
					> a {
						&::after {
							opacity: 1;
							visibility: visible;
						}
					}

					> ul {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}

		@include desktop-xl {
			font-size: 14px;
			line-height: 17px;
		}
	}

	&__shop {
		margin-top: vm(32);

		.button {
			@include desktop {
				padding: vw(15) vw(10);
				min-width: vw(200);
				font-size: vw(14);
				line-height: vw(18);
			}

			@include desktop-xl {
				padding: 15px 10px;
				min-width: 200px;
				font-size: 14px;
				line-height: 18px;
			}
		}

		@include desktop {
			margin-top: 0;
		}
	}

	&__search-link {
		flex-shrink: 0;
		margin-left: vw(45);

		a {
			display: block;
			padding: vw(5);

			svg {
				width: vw(14);
				height: vw(14);

				@include desktop-xl {
					width: 14px;
					height: 14px;
				}
			}

			&:hover {
				color: $color-monza;
			}

			@include desktop-xl {
				padding: 5px;
			}
		}

		@include desktop-xl {
			margin-left: 45px;
		}
	}

	&__lang {
		font-weight: 700;
		font-size: vm(14);
		line-height: vm(17);
		letter-spacing: 0.1em;
		text-align: center;
		text-transform: uppercase;

		a {
			display: block;
			padding: vm(9);

			@include desktop {
				padding: vw(5);

				&:hover {
					color: $color-monza;
				}
			}

			@include desktop-xl {
				padding: 5px;
			}
		}

		@include desktop {
			margin-left: vw(25);
			font-size: vw(14);
			line-height: vw(17);
		}

		@include desktop-xl {
			margin-left: 25px;
			font-size: 14px;
			line-height: 17px;
		}
	}

	&__search {
		position: relative;
		margin-bottom: vm(8);

		&__field {
			input {
				outline: none;
				border: solid 1px $color-black;
				border-radius: vm(8);
				padding: 0 vm(15);
				width: 100%;
				height: vm(40);
				background: $color-white;

				&::placeholder {
					color: $color-black;
				}
			}
		}

		&__submit {
			position: absolute;
			right: vm(10);
			top: 50%;
			z-index: 5;
			outline: none;
			border: none;
			padding: vm(5);
			color: $color-black;
			background: none;
			transform: translateY(-50%);

			svg {
				display: block;
				width: vm(16);
				height: vm(16);
			}
		}
	}

	&__socials {
		display: flex;
		justify-content: center;
		margin-top: vm(30);

		li {
			+ li {
				margin-left: vm(20);
			}

			svg {
				display: block;
				width: vm(20);
				height: vm(20);
			}
		}
	}

	&__contacts {
		&__phone {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: vm(16);
			font-weight: 500;
			font-size: vm(16);
			line-height: vm(19);
			letter-spacing: 0.05em;
			text-align: center;

			a {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			svg {
				flex-shrink: 0;
				margin-right: vm(8);
				width: vm(21);
				height: vm(21);
			}
		}

		&__links {
			display: flex;
			justify-content: space-around;
			font-weight: 500;
			font-size: vm(13);
			line-height: vm(16);
			letter-spacing: 0.05em;
			text-align: center;
			text-transform: uppercase;
			color: #999;
		}
	}

	&__mobile {
		position: absolute;
		left: 0;
		top: vm(39);
		overflow: auto;
		padding: vm(24) vm(10) vm(32);
		width: 100%;
		height: calc(100vh - #{vm(39)});
		background: $color-white;
		opacity: 0;
		visibility: hidden;
		transition: $duration-normal;

		&__menu {
			margin-bottom: vm(32);
			font-weight: 700;
			font-size: vm(14);
			line-height: vm(16);
			letter-spacing: 0.1em;
			text-transform: uppercase;

			ul {
				li {
					position: relative;
					border-bottom: solid 1px #f8f8f8;
					padding: vm(16) 0;

					a {
						display: inline-flex;

						svg {
							flex-shrink: 0;
							margin-top: vm(-1);
							margin-left: vm(8);
							width: vm(16);
							height: vm(16);
							fill: none;
						}
					}

					ul {
						display: none;
						padding-top: vm(16);
						font-weight: 400;
						line-height: vm(20);
						letter-spacing: 0;
						text-transform: none;

						li {
							border-bottom: none;
							padding: 0;

							+ li {
								margin-top: vm(12);
							}
						}
					}
				}
			}

			&__open {
				position: absolute;
				right: 0;
				top: vm(12);
				z-index: 5;
				outline: none;
				border: none;
				padding: vm(10);
				background: none;

				svg {
					display: block;
					width: vm(11);
					height: vm(6);
					fill: none;
					transform: rotate(180deg);
					transition: $duration-normal;
				}

				&.is-active {
					svg {
						transform: rotate(0);
					}
				}
			}
		}

		.is-show-menu & {
			opacity: 1;
			visibility: visible;
		}
	}

	&--dark {
		@include desktop {
			.header__top {
				background: #f8f8f8;
			}

			.header__bottom {
				color: $color-black;
			}

			.header__logo {
				svg {
					color: $color-monza;
				}
			}

			.header__menu {
				ul {
					li {
						a {
							&::after {
								background: $color-monza;
							}
						}
					}
				}
			}
		}
	}

	&.is-fixed {
		.header__bottom {
			box-shadow: 0 vm(6) vm(13) rgba($color-black, 0.05);
		}

		@include desktop {
			box-shadow: 0 vw(6) vw(13) rgba($color-black, 0.05);
			color: $color-black;

			.header__top {
				background: #f8f8f8;
			}

			.header__bottom {
				color: $color-black;
				background: $color-white;
			}

			.header__logo {
				svg {
					color: $color-monza;
				}
			}

			.header__menu {
				ul {
					li {
						a {
							&::after {
								background: $color-monza;
							}
						}
					}
				}
			}
		}

		@include desktop-xl {
			.header__bottom {
				box-shadow: 0 6px 13px rgba($color-black, 0.05);
			}
		}
	}

	.compensate-for-scrollbar & {
		transform: translateX(var(--compensate-scrollbar));
	}
}