.page-distribution {
	.services__distribution {
		margin-top: vm(60);
		margin-bottom: vm(48);

		&__content {
			@include desktop {
				max-width: vw(839);
			}

			@include desktop-xl {
				max-width: 839px;
			}
		}

		&__text {
			@include desktop {
				margin-bottom: vw(48);
				max-width: vw(663);
			}

			@include desktop-xl {
				margin-bottom: 48px;
				max-width: 663px;
			}
		}

		&__list {
			margin-bottom: vm(24);

			@include desktop {
				margin-bottom: vw(24);
			}

			@include desktop-xl {
				margin-bottom: 24px;
			}
		}

		&__col {
			@include desktop {
				margin-bottom: vw(40);
				width: 33.33334%;
			}

			@include desktop-xl {
				margin-bottom: 40px;
			}
		}

		&__image {
			@include desktop {
				width: vw(959);
			}

			@include desktop-xl {
				width: 959px;
			}
		}

		@include desktop {
			margin-top: 0;
			margin-bottom: vw(104);
			padding: vw(72) vw(64);
		}

		@include desktop-xl {
			margin-bottom: 104px;
			padding: 72px 64px;
		}
	}

	&__stores {
		margin-bottom: vm(48);

		&__content {
			width: 100%;
		}

		&__text {
			margin-bottom: vm(24);

			@include desktop {
				margin-bottom: vw(48);
				max-width: vw(418);
			}

			@include desktop-xl {
				margin-bottom: 48px;
				max-width: 418px;
			}
		}

		&__tab {
			ul {
				li {
					+ li {
						margin-top: vm(20);

						@include desktop {
							margin-top: vw(20);
						}

						@include desktop-xl {
							margin-top: 20px;
						}
					}

					a {
						color: rgba($color-black, 0.2);

						&:hover {
							color: $color-monza;
						}

						&.is-active {
							color: $color-black;
						}
					}
				}
			}
		}

		&__list {
			margin-top: vm(24);

			&__item {
				display: none;

				&.is-active {
					display: block;
					animation: tabShow $duration-normal;
				}
			}

			@include desktop {
				flex-shrink: 0;
				margin-top: 0;
				margin-left: vw(127);
				width: vw(1192);
			}

			@include desktop-xl {
				margin-left: 127px;
				width: 1192px;
			}
		}

		&__row {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vm(-5) vm(-10);

			@include desktop {
				margin: 0 vw(-10) vw(-20);
			}

			@include desktop-xl {
				margin: 0 -10px -20px;
			}
		}

		&__col {
			margin-bottom: vm(10);
			padding: 0 vm(5);
			width: 50%;

			@include desktop {
				margin-bottom: vw(20);
				padding: 0 vw(10);
				width: 25%;
			}

			@include desktop-xl {
				margin-bottom: 20px;
				padding: 0 10px;
			}
		}

		&__item {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: vm(8);
			height: vm(80);
			background: #f8f8f8;

			img {
				position: absolute;
				left: 50%;
				top: 50%;
				max-width: vm(100);
				max-height: vm(80);
				opacity: 0;
				transform: translate(-50%, -50%) scale(0.5);
				transition: 0.5s;

				&.is-active {
					z-index: 5;
					opacity: 1;
					transform: translate(-50%, -50%) scale(1);
					transition: 1s;
				}

				@include desktop {
					max-width: vw(195);
					max-height: vw(80);
				}

				@include desktop-xl {
					max-width: 195px;
					max-height: 80px;
				}
			}

			@include desktop {
				border-radius: vw(16);
				height: vw(144);
			}

			@include desktop-xl {
				border-radius: 16px;
				height: 144px;
			}
		}

		@include desktop {
			display: flex;
			margin-bottom: vw(104);
		}

		@include desktop-xl {
			margin-bottom: 104px;
		}
	}

	&__brands {
		margin-bottom: vm(60);
		padding: vm(40) 0 vm(30);
		background: #f6f6f6;

		&__title {
			@include desktop {
				margin-bottom: vw(72);
			}

			@include desktop-xl {
				margin-bottom: 72px;
			}
		}

		&__row {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vm(-5);

			@include desktop {
				margin: 0 vw(-10);
			}

			@include desktop-xl {
				margin: 0 -10px;
			}
		}

		&__col {
			margin-bottom: vm(10);
			padding: 0 vm(5);
			width: 50%;

			@include desktop {
				margin-bottom: vw(20);
				padding: 0 vw(10);
				width: 16.6667%;
			}

			@include desktop-xl {
				margin-bottom: 20px;
				padding: 0 10px;
			}
		}

		&__item {
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: visible;
			border-radius: vm(8);
			height: vm(80);
			background: $color-white;

			img {
				max-width: vm(145);
				max-height: vm(74);

				@include desktop {
					max-width: vw(283);
					max-height: vw(144);
				}

				@include desktop-xl {
					max-width: 283px;
					max-height: 144px;
				}
			}

			@include desktop {
				border-radius: vw(16);
				height: vw(144);
				opacity: 0.7;
				filter: grayscale(100%);

				&:hover {
					box-shadow: 0 vw(7) vw(10) rgba($color-black, 0.25);
					opacity: 1;
					filter: grayscale(0);
				}
			}

			@include desktop-xl {
				border-radius: 16px;
				height: 144px;

				&:hover {
					box-shadow: 0 7px 10px rgba($color-black, 0.25);
				}
			}
		}

		@include desktop {
			margin-bottom: vw(104);
			padding: vw(80) 0 vw(60);
		}

		@include desktop-xl {
			margin-bottom: 104px;
			padding: 80px 0 60px;
		}
	}

	.services {
		margin-bottom: 0;
	}
}
