.goods {
	margin-bottom: vm(-36);
	padding: vm(32) 0 vm(16);
	background: #f8f8f8;

	&__title {
		max-width: vm(113);

		@include desktop {
			margin-bottom: vw(80);
			max-width: none;
		}

		@include desktop-xl {
			margin-bottom: 80px;
		}
	}

	.carousel__nav {
		@include desktop {
			top: vw(-140);
		}

		@include desktop-xl {
			top: -140px;
		}
	}

	.swiper-container {
		@include desktop {
			overflow: hidden;
			margin: 0 vw(-18) vw(-18);
			padding: 0 vw(18) vw(18);
		}

		@include desktop-xl {
			margin: 0 -18px -18px;
			padding: 0 18px 18px;
		}
	}

	.swiper-slide {
		height: auto;
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 vm(-10);

		@include desktop {
			margin: 0 vw(-10);
		}

		@include desktop-xl {
			margin: 0 -10px;
		}
	}

	&__col {
		margin-bottom: vm(16);
		padding: 0 vm(10);
		width: 100%;

		@include desktop {
			margin-bottom: vw(20);
			padding: 0 vw(10);
			width: 50%;
		}

		@include desktop-xl {
			margin-bottom: 20px;
			padding: 0 10px;
		}
	}

	&__item {
		display: block;
		border-radius: vm(8);
		padding: vm(8) vm(8) vm(24);
		height: 100%;
		box-sizing: border-box;
		background: $color-white;

		&__image {
			flex-shrink: 0;
			margin-bottom: vm(24);
			border-radius: vm(8);
			padding-bottom: 73.67%;
			width: 100%;
			background: 50% 50% no-repeat;
			background-size: cover;

			@include desktop {
				margin-right: vw(48);
				margin-bottom: 0;
				border-radius: vw(16);
				padding-bottom: 0;
				width: vw(387);
				min-height: vw(324);
			}

			@include desktop-xl {
				margin-right: 48px;
				border-radius: 16px;
				width: 387px;
				min-height: 324px;
			}
		}

		&__content {
			padding: 0 vm(16);
			width: 100%;

			@include desktop {
				padding: vm(16) 0 0;
			}

			@include desktop-xl {
				padding: 16px 0 0;
			}
		}

		&__title {
			margin-bottom: vm(8);

			@include desktop {
				margin-bottom: vw(16);
			}

			@include desktop-xl {
				margin-bottom: 16px;
			}
		}

		&__info {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: vm(22);

			@include desktop {
				margin-bottom: vw(22);
			}

			@include desktop-xl {
				margin-bottom: 22px;
			}
		}

		&__date {
			margin-right: vm(8);

			span {
				display: inline-block;
				vertical-align: middle;
				border: 1px solid $color-monza;
				border-radius: vm(16);
				padding: 0 vm(10);
				font-weight: 500;
				font-size: vm(10);
				line-height: vm(22);
				letter-spacing: 0.1em;
				text-transform: uppercase;
				color: $color-white;
				background: $color-monza;

				@include desktop {
					border-radius: vw(16);
					padding: 0 vw(10);
					font-size: vw(12);
					line-height: vw(22);
				}

				@include desktop-xl {
					border-radius: 16px;
					padding: 0 10px;
					font-size: 12px;
					line-height: 22px;
				}
			}

			@include desktop {
				margin-right: vw(8);
			}

			@include desktop-xl {
				margin-right: 8px;
			}
		}

		&__tag {
			display: inline-block;
			vertical-align: middle;
			margin-right: vm(8);
			border: solid 1px $color-black;
			border-radius: vm(16);
			padding: 0 vm(9);
			font-weight: 500;
			font-size: vm(10);
			line-height: vm(22);
			letter-spacing: 0.1em;
			text-transform: uppercase;

			@include desktop {
				margin-right: vw(8);
				border-radius: vw(16);
				padding: 0 vw(9);
				font-size: vw(12);
				line-height: vw(22);
			}

			@include desktop-xl {
				margin-right: 8px;
				border-radius: 16px;
				padding: 0 9px;
				font-size: 12px;
				line-height: 22px;
			}
		}

		&__list {
			margin: vm(18) vm(-8) vm(10);
			min-width: 0;

			table {
				border-collapse: collapse;
				width: 100%;

				td {
					padding: vm(6) vm(8);

					@include desktop {
						padding: vw(2) vw(18);
					}

					@include desktop-xl {
						padding: 2px 18px;
					}
				}
			}

			&__key {
				color: #808080;
			}

			&__value {
				width: 100%;
			}

			@include mobile {
				font-size: vm(13);
				line-height: vm(16);
			}

			@include desktop {
				margin: vw(22) vw(-18);
			}

			@include desktop-xl {
				margin: 22px -18px;
			}
		}

		&__limit {
			display: flex;
			align-items: center;
			margin-top: vm(22);

			&__age {
				display: flex;
				flex-shrink: 0;
				align-items: center;
				justify-content: center;
				margin-right: vm(12);
				border: solid 2px $color-black;
				border-radius: 50%;
				width: vm(38);
				height: vm(38);
				font-weight: 700;
				text-align: center;

				@include desktop {
					margin-right: vw(12);
					width: vw(41);
					height: vw(41);
				}

				@include desktop-xl {
					margin-right: 12px;
					width: 41px;
					height: 41px;
				}
			}

			&__text {
				width: 100%;
				font-weight: 700;
			}

			@include desktop {
				margin-top: vw(22);
			}

			@include desktop-xl {
				margin-top: 22px;
			}
		}

		@include desktop {
			display: flex;
			border-radius: vw(16);
			padding: vw(32);

			&:hover {
				box-shadow: 0 vw(7) vw(10) rgba($color-black, 0.25);
			}
		}

		@include desktop-xl {
			border-radius: 16px;
			padding: 32px;

			&:hover {
				box-shadow: 0 7px 10px rgba($color-black, 0.25);
			}
		}
	}

	.pagination {
		justify-content: center;
		margin-top: vm(20);
		padding: 0 vm(10);
		width: 100%;
		text-align: center;

		@include desktop {
			margin-top: vw(60);
			padding: 0;
		}

		@include desktop-xl {
			margin-top: 60px;
		}
	}

	@include desktop {
		margin-bottom: vw(-160);
		padding: vw(80) 0 vw(100);
	}

	@include desktop-xl {
		margin-bottom: -160px;
		padding: 80px 0 100px;
	}
}
