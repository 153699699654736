.cases {
	position: relative;
	margin-bottom: vm(48);
	color: $color-white;

	&__title {
		position: absolute;
		left: vm(20);
		right: vm(30);
		top: vm(20);
		z-index: 10;

		@include desktop {
			left: vw(104);
			right: 0;
			top: vw(88);
			max-width: vw(328);
		}

		@include desktop-xl {
			left: 104px;
			top: 88px;
			max-width: 328px;
		}
	}

	&__slider {
		position: relative;

		.swiper-slide {
			height: auto;
		}
	}

	&__prev,
	&__next {
		position: absolute;
		top: 50%;
		z-index: 5;
		transform: translateY(-50%);

		&.swiper-button-disabled {
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
		}
	}

	&__prev {
		left: vm(32);

		@include desktop {
			left: vw(48);
		}

		@include desktop-xl {
			left: 48px;
		}
	}

	&__next {
		right: vm(32);

		@include desktop {
			right: vw(48);
		}

		@include desktop-xl {
			right: 48px;
		}
	}

	&__item {
		position: relative;
		z-index: 1;
		display: flex;
		align-items: flex-end;
		overflow: hidden;
		border-radius: vm(8);
		padding: vm(115) vm(20) vm(35);
		height: 100%;
		box-sizing: border-box;

		&__image {
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			background: 50% 50% no-repeat;
			background-size: cover;
		}

		&__content {
			@include desktop {
				max-width: vw(500);
			}

			@include desktop-xl {
				max-width: 500px;
			}
		}

		&__title {
			@include desktop {
				margin-bottom: vw(33);
			}

			@include desktop-xl {
				margin-bottom: 33px;
			}
		}

		&__list {
			ul {
				li {
					+ li {
						@include desktop {
							margin-top: 0;
						}
					}
				}

				@include desktop {
					font-size: vw(16);
				}

				@include desktop-xl {
					font-size: 16px;
				}
			}
		}

		@include desktop {
			border-radius: vw(16);
			padding: vw(88) vw(104);
			height: vw(720);
		}

		@include desktop-xl {
			border-radius: 16px;
			padding: 88px 104px;
			height: 720px;
		}
	}

	@include desktop {
		margin-bottom: vw(104);
	}

	@include desktop-xl {
		margin-bottom: 104px;
	}
}
