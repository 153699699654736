.input {
	margin-bottom: vm(16);

	&__label {
		margin-bottom: vm(8);

		&__imp {
			color: $color-monza;
		}

		@include mobile {
			font-size: vm(16);
		}

		@include desktop {
			margin-bottom: vw(8);
		}

		@include desktop-xl {
			margin-bottom: 8px;
		}
	}

	&__field {
		position: relative;
		display: flex;
		flex-direction: column;

		&__text {
			margin-top: vm(8);

			@include desktop {
				margin-top: vw(8);
				font-size: vw(14);
				line-height: vw(20);
			}

			@include desktop-xl {
				margin-top: 8px;
				font-size: 14px;
				line-height: 20px;
			}
		}

		input,
		textarea {
			appearance: none;
			outline: none;
			border: solid 1px #ccc;
			border-radius: vm(8);
			padding: 0 vm(50) 0 vm(20);
			width: 100%;
			height: vm(64);
			background: $color-white;
			transition: $duration-normal;

			&::-ms-clear {
				display: none;
			}

			&::placeholder {
				color: #787878;
				transition: $duration-normal;
			}

			&:focus {
				border-color: $color-black;

				&::placeholder {
					color: $color-black;
				}
			}

			&:disabled {
				border-color: #ccc;
				background: #f8f8f8;
				pointer-events: none;
			}

			&.error {
				border-color: #e30613;
				background: #ffe6ef;
			}

			@include mobile {
				font-size: vm(16);
			}

			@include desktop {
				border-radius: vw(8);
				padding: 0 vw(50) 0 vw(20);
				height: vw(64);
			}

			@include desktop-xl {
				border-radius: 8px;
				padding: 0 24px;
				height: 64px;
			}
		}

		textarea {
			padding-top: vm(20);
			height: vm(184);
			resize: none;

			@include desktop {
				padding-top: vw(20);
				height: vw(184);
			}

			@include desktop-xl {
				padding-top: 20px;
				height: 184px;
			}
		}

		select {
			appearance: none;
			border: solid 1px #ccc;
			border-radius: vm(8);
			padding: 0 vm(50) 0 vm(20);
			width: 100%;
			height: vm(64);
			background: url("../images/triangle.svg") #fff 95% 55% no-repeat;
			background-size: vw(10) vw(7);

			&::placeholder {
				.select2-container--default {
					.select2-selection {
						&__rendered {
							color: #ccc;
						}
					}
				}
			}

			&:disabled ~ {
				.select2-container--default {
					.select2-selection {
						&__rendered {
							border-color: #ccc;
							background: #f8f8f8;
						}
					}
				}
			}

			&.error ~ {
				.select2-container--default {
					.select2-selection {
						&__rendered {
							border-color: #e30613;
							background: #ffe6ef;
						}
					}
				}
			}

			@include desktop {
				border-radius: vw(8);
				padding: 0 vw(50) 0 vw(20);
				height: vw(64);
				background-size: vw(10) vw(7);
			}

			@include desktop-xl {
				border-radius: 8px;
				padding: 0 50px 0 20px;
				height: 64px;
				background-size: 10px 7px;
			}
		}

		.select2-container {
			display: inline-block;
			vertical-align: middle;
			width: 100% !important;

			&--open {
				&.select2-container--default {
					.select2-selection {
						&__rendered {
							border-radius: vm(8) vm(8) 0 0;

							@include desktop {
								border-radius: vw(8) vw(8) 0 0;
							}

							@include desktop-xl {
								border-radius: 8px 8px 0 0;
							}
						}

						&__arrow {
							transform: rotate(180deg);
						}
					}
				}
			}
		}

		.select2-container--default {
			.select2-selection {
				outline: none;
				border: none;
				border-radius: 0;
				background-color: transparent;

				&__rendered {
					border: solid 1px #ccc;
					border-radius: vm(8);
					padding: vm(18) vm(24);
					height: 100%;
					background: $color-white;
					transition: $duration-normal;

					@include mobile {
						font-size: vm(16);
					}

					@include desktop {
						border-radius: vw(8);
						padding: vw(18) vw(24);
					}

					@include desktop-xl {
						border-radius: 8px;
						padding: 18px 24px;
					}
				}

				&__placeholder {
					color: $color-black;
				}

				&__arrow {
					right: vm(24);
					top: 50%;
					border-top: solid vm(6) #ccc;
					border-right: solid vm(4) transparent;
					border-left: solid vm(4) transparent;
					width: 0;
					height: 0;
					transform: translateY(-50%);
					transform-origin: 50% 25%;
					transition: $duration-normal;

					b {
						display: none;
					}

					@include desktop {
						right: vw(24);
						border-top-width: vw(6);
						border-right-width: vw(4);
						border-left-width: vw(4);
					}

					@include desktop-xl {
						right: 24px;
						border-top-width: 6px;
						border-right-width: 4px;
						border-left-width: 4px;
					}
				}

				&--single {
					height: vm(64);

					@include desktop {
						height: vw(64);
					}

					@include desktop-xl {
						height: 64px;
					}
				}
			}

			.select2-dropdown {
				margin-top: -1px;
				border: solid 1px #ccc;
				border-radius: 0 0 vm(8) vm(8);
				padding: vm(16) vm(24);
				background: $color-white;

				@include mobile {
					font-size: vm(16);
					line-height: vm(24);
				}

				@include desktop {
					border-radius: 0 0 vw(8) vw(8);
					padding: vw(16) vw(24);
				}

				@include desktop-xl {
					border-radius: 0 0 8px 8px;
					padding: 16px 24px;
				}
			}

			.select2-results__options {
				max-height: vm(190);

				&::-webkit-scrollbar {
					width: vm(8);
					height: vm(8);
					background-color: transparent;

					@include desktop {
						width: vw(8);
						height: vw(8);
					}

					@include desktop-xl {
						width: 8px;
						height: 8px;
					}
				}

				&::-webkit-scrollbar-track {
					background-color: transparent;
				}

				&::-webkit-scrollbar-thumb {
					border-right: solid vm(4) rgba($color-black, 0.3);
					background-color: rgba($color-black, 0.3);

					@include desktop {
						border-right: solid vw(4) rgba($color-black, 0.3);
					}

					@include desktop-xl {
						border-right: solid 4px $color-white;
					}
				}

				@include desktop {
					max-height: vw(190);
				}

				@include desktop-xl {
					max-height: 190px;
				}
			}

			.select2-results__option {
				padding: 0;
				background: none;
				transition: $duration-normal;

				+ .select2-results__option {
					margin-top: vm(8);

					@include desktop {
						margin-top: vw(8);
					}

					@include desktop-xl {
						margin-top: 8px;
					}
				}

				&--selected,
				&--highlighted {
					color: $color-monza;
				}
			}
		}

		.select2-search--dropdown {
			padding: 0;

			.select2-search__field {
				outline: none;
				border: none;
				padding: 0 vm(50) 0 vm(20);

				@include desktop {
					padding: 0 vw(50) 0 vw(20);
				}

				@include desktop-xl {
					padding: 0 50px 0 20px;
				}
			}
		}

		label.error {
			display: block;
			order: 2;
			margin-top: vm(8);
			font-size: vm(12);
			color: $color-monza;

			@include desktop {
				margin-top: vw(8);
				font-size: vw(14);
				line-height: vw(20);
			}

			@include desktop-xl {
				margin-top: 8px;
				font-size: 14px;
				line-height: 20px;
			}
		}
	}

	@include desktop {
		margin-bottom: vw(24);
	}

	@include desktop-xl {
		margin-bottom: 24px;
	}
}
