.banner {
	position: relative;
	z-index: 5;
	overflow: hidden;
	margin: 0 vm(-10) vm(48);
	padding: vm(32) vm(10) 0;
	color: $color-white;
	background: #353535;

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		background: 50% 50% no-repeat;
		background-size: cover;
	}

	&__content {
		@include desktop {
			max-width: vw(593);
		}

		@include desktop-xl {
			max-width: 593px;
		}
	}

	&__title {
		@include mobile {
			max-width: vm(240);
		}
	}

	&__text {
		@include desktop {
			max-width: vw(385);
		}

		@include desktop-xl {
			max-width: 385px;
		}
	}

	&__benefits {
		margin-top: vm(24);

		&__label {
			margin: 0 0 vm(18);
			font-weight: 700;

			@include mobile {
				font-size: vm(16);
			}

			@include desktop {
				margin: 0 0 vw(38);
			}

			@include desktop-xl {
				margin: 0 0 38px;
			}
		}

		&__row {
			margin-bottom: vm(-16);

			@include desktop {
				display: flex;
				margin-bottom: 0;
				margin-left: vw(-35);
			}

			@include desktop-xl {
				margin-left: -35px;
			}
		}

		&__col {
			margin-bottom: vm(16);

			@include desktop {
				margin-bottom: 0;
				margin-left: vw(35);
			}

			@include desktop-xl {
				margin-left: 35px;
			}
		}

		&__item {
			&__icon {
				display: flex;
				flex-shrink: 0;
				align-items: center;
				justify-content: center;
				margin-right: vm(16);
				border: 2px solid $color-white;
				border-radius: vm(5);
				width: vm(48);
				height: vm(48);

				img {
					max-width: vm(27);

					@include desktop {
						max-width: vw(43);
					}

					@include desktop-xl {
						max-width: 43px;
					}
				}

				@include desktop {
					margin-right: 0;
					margin-bottom: vw(16);
					border-radius: vw(8);
					width: vw(72);
					height: vw(72);
				}

				@include desktop-xl {
					margin-bottom: 16px;
					border-radius: 8px;
					width: 72px;
					height: 72px;
				}
			}

			&__text {
				max-width: vm(190);
				font-size: vm(14);
				line-height: vm(20);

				@include desktop {
					max-width: none;
					font-size: vw(14);
					line-height: vw(20);
				}

				@include desktop-xl {
					font-size: 14px;
					line-height: 20px;
				}
			}

			@include mobile {
				display: flex;
				align-items: center;
			}

			@include desktop {
				max-width: vw(145);
			}

			@include desktop-xl {
				max-width: 145px;
			}
		}

		@include desktop {
			margin-top: vw(79);
		}

		@include desktop-xl {
			margin-top: 79px;
		}
	}

	&__image {
		@include mobile {
			margin: 0 vm(-10);

			img {
				width: 100%;
			}
		}
	}

	@include desktop {
		margin: 0 0 vw(112);
		border-radius: vw(16);
		padding: vw(92) vw(103);
		min-height: vw(757);
	}

	@include desktop-xl {
		margin: 0 0 112px;
		border-radius: 16px;
		padding: 92px 103px;
		min-height: 757px;
	}
}
