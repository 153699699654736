.file {
	margin-bottom: vm(24);

	&__link {
		display: inline-flex;
		align-items: flex-start;
		vertical-align: top;
	}

	&__icon {
		position: relative;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		margin-right: vm(28);
		border-radius: 50%;
		width: vm(72);
		height: vm(72);
		background: $color-monza;
		transition: $duration-normal;

		svg {
			display: block;
			width: vm(26);
			height: vm(34);
			fill: none;

			@include desktop {
				width: vw(26);
				height: vw(34);
			}
		
			@include desktop-xl {
				width: 26px;
				height: 34px;
			}
		}

		@include desktop {
			margin-right: vw(28);
			width: vw(72);
			height: vw(72);
		}
	
		@include desktop-xl {
			margin-right: 28px;
			width: 72px;
			height: 72px;
		}
	}

	&__arrow {
		position: absolute;
		left: vm(52);
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		width: vm(32);
		height: vm(32);
		background: $color-monza;
		transition: $duration-normal;

		svg {
			display: block;
			width: vm(8);
			height: vm(11);

			@include desktop {
				width: vw(8);
				height: vw(11);
			}
		
			@include desktop-xl {
				width: 8px;
				height: 11px;
			}
		}

		@include desktop {
			left: vw(52);
			width: vw(32);
			height: vw(32);
		}
	
		@include desktop-xl {
			left: 52px;
			width: 32px;
			height: 32px;
		}
	}

	&__content {
		padding-top: vm(8);

		@include desktop {
			padding-top: vw(8);
		}
	
		@include desktop-xl {
			padding-top: 8px;
		}
	}

	&__title {
		margin: 0 0 vm(4);
		transition: $duration-normal;

		@include desktop {
			margin: 0 0 vw(4);
		}
	
		@include desktop-xl {
			margin: 0 0 4px;
		}
	}

	&__info {
		font-weight: 500;
		font-size: vm(12);
		line-height: vm(14);
		letter-spacing: 0.1em;
		text-transform: uppercase;

		@include desktop {
			font-size: vw(12);
			line-height: vw(14);
		}
	
		@include desktop-xl {
			font-size: 12px;
			line-height: 14px;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	@include desktop {
		margin-bottom: vw(24);

		&:hover {
			.file__title {
				color: $color-monza;
			}
	
			.file__icon {
				opacity: 0.8;
			}
		}
	}

	@include desktop-xl {
		margin-bottom: 24px;
	}
}
