.benefits {
	position: relative;
	z-index: 5;
	margin-bottom: vm(40);

	&__title {
		@include desktop {
			margin-bottom: vw(64);
		}

		@include desktop-xl {
			margin-bottom: 64px;
		}
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 vm(-4) vm(-8);

		@include desktop {
			margin: 0 vw(-10) vw(-20);
		}

		@include desktop-xl {
			margin: 0 -10px -20px;
		}
	}

	&__row--width-33 {
		.benefits__col {
			@include desktop {
				width: 33.33334%;
			}
		}

		.benefits__col--big {
			@include desktop {
				width: 50%;
			}
		}
	}

	&__col {
		margin-bottom: vm(8);
		padding: 0 vm(4);
		width: 50%;

		@include mobile {
			&:nth-child(1) {
				order: -2;
			}

			&:nth-child(3) {
				order: -1;
			}
		}

		@include desktop {
			margin-bottom: vw(20);
			padding: 0 vw(10);
			width: 25%;
		}

		@include desktop-xl {
			margin-bottom: 20px;
			padding: 0 10px;
		}
	}

	&__col--big {
		width: 100%;

		@include desktop {
			width: 50%;
		}
	}

	&__item {
		position: relative;
		z-index: 5;
		display: flex;
		align-items: flex-end;
		overflow: hidden;
		border-radius: vm(8);
		padding: vm(20);
		height: vm(180);
		color: $color-white;
		transition: $duration-normal;

		&__title {
			width: 100%;
			max-width: vm(144);
			font-weight: 700;
			font-size: vm(14);
			line-height: vm(20);

			@include desktop {
				max-width: vw(418);
				font-size: vw(32);
				line-height: vw(36);
			}

			@include desktop-xl {
				max-width: 418px;
				font-size: 32px;
				line-height: 36px;
			}
		}

		&__image {
			position: absolute;
			z-index: -1;

			img {
				width: 100%;
			}
		}

		.is-tilted & {
			transition: none;

			&:hover {
				box-shadow: none;
			}
		}

		@include desktop {
			border-radius: vw(16);
			padding: vw(60);
			height: vw(435);

			&:hover {
				z-index: 10;
				box-shadow: 0 vw(7) vw(10) rgba($color-black, 0.25);
			}
		}

		@include desktop-xl {
			border-radius: 16px;
			padding: 60px;
			height: 435px;

			&:hover {
				box-shadow: 0 7px 10px rgba($color-black, 0.25);
			}
		}
	}

	&__item--xbox {
		background: #201f25;

		.benefits__item__image {
			right: 0;
			top: vm(9);
			width: vm(167);

			@include desktop {
				right: vw(40);
				top: vw(30);
				width: vw(391);
			}

			@include desktop-xl {
				right: 40px;
				top: 30px;
				width: 391px;
			}
		}
	}

	&__item--shop {
		background: #e30613;

		.benefits__item__image {
			left: vm(20);
			top: vm(-16);
			width: vm(108);

			@include desktop {
				left: vw(38);
				top: vw(-52);
				width: vw(363);
			}

			@include desktop-xl {
				left: 38px;
				top: -52px;
				width: 363px;
			}
		}
	}

	&__item--steam {
		background: #191919;

		.benefits__item__image {
			left: vm(24);
			top: 0;
			width: vm(100);

			@include desktop {
				left: vw(38);
				top: vw(-52);
				width: vw(363);
			}

			@include desktop-xl {
				left: 38px;
				top: -52px;
				width: 363px;
			}
		}
	}

	&__item--leader {
		background: #e30613;

		.benefits__item__image {
			left: vm(30);
			top: vm(5);
			width: vm(89);

			@include desktop {
				left: vw(65);
				top: vw(-28);
				width: vw(304);
			}

			@include desktop-xl {
				left: 65px;
				top: -28px;
				width: 304px;
			}
		}
	}

	&__item--partner {
		color: $color-black;
		background: #f8f8f8;

		.benefits__item__image {
			right: 0;
			top: 0;
			width: vm(100);

			@include desktop {
				width: vw(351);
			}

			@include desktop-xl {
				width: 351px;
			}
		}
	}

	&__item--years {
		.benefits__item__image {
			left: 0;
			top: 0;
			border-radius: vm(8);
			width: 100%;
			height: 100%;
			background: 50% 50% no-repeat;
			background-size: cover;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				border-radius: vm(8);
				width: 100%;
				height: 100%;
				background: linear-gradient(90deg, rgba($color-black, 0.5) 0%, rgba($color-black, 0) 50%);

				@include desktop {
					border-radius: vw(16);
				}

				@include desktop-xl {
					border-radius: 16px;
				}
			}

			@include desktop {
				border-radius: vw(16);
			}

			@include desktop-xl {
				border-radius: 16px;
			}
		}
	}

	&__item--realize {
		.benefits__item__image {
			left: 0;
			top: 0;
			border-radius: vm(8);
			width: 100%;
			height: 100%;
			background: 50% 50% no-repeat;
			background-size: cover;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				border-radius: vm(8);
				width: 100%;
				height: 100%;
				background: linear-gradient(90deg, rgba($color-black, 0.5) 0%, rgba($color-black, 0) 50%);

				@include desktop {
					border-radius: vw(16);
				}

				@include desktop-xl {
					border-radius: 16px;
				}
			}

			@include desktop {
				border-radius: vw(16);
			}

			@include desktop-xl {
				border-radius: 16px;
			}
		}
	}

	&__item--online {
		.benefits__item__image {
			left: 0;
			top: 0;
			border-radius: vm(8);
			width: 100%;
			height: 100%;
			background: 50% 50% no-repeat;
			background-size: cover;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				border-radius: vm(8);
				width: 100%;
				height: 100%;
				background: linear-gradient(90deg, rgba($color-black, 0.5) 0%, rgba($color-black, 0) 50%);

				@include desktop {
					border-radius: vw(16);
				}

				@include desktop-xl {
					border-radius: 16px;
				}
			}

			@include desktop {
				border-radius: vw(16);
			}

			@include desktop-xl {
				border-radius: 16px;
			}
		}
	}

	&__item--embody {
		color: $color-black;
		background: #f8f8f8;

		.benefits__item__image {
			right: 0;
			top: 0;
			width: vm(85);

			@include desktop {
				width: vw(301);
			}

			@include desktop-xl {
				width: 301px;
			}
		}
	}

	&__item--partnership {
		color: $color-white;
		background: $color-black;

		.benefits__item__image {
			right: 0;
			top: 0;
			width: vm(120);

			@include desktop {
				width: vw(392);
			}

			@include desktop-xl {
				width: 392px;
			}
		}
	}

	&__item--satisfied {
		color: $color-white;
		background: $color-monza;

		.benefits__item__image {
			right: 0;
			top: 0;
			width: vm(100);

			@include desktop {
				width: vw(301);
			}

			@include desktop-xl {
				width: 301px;
			}
		}
	}

	@include desktop {
		margin-bottom: vw(137);
	}

	@include desktop-xl {
		margin-bottom: 137px;
	}
}
