.radio {
	margin-bottom: vm(16);

	label {
		position: relative;
		display: inline-block;
		padding: 0 0 0 vm(40);
		transition: $duration-normal;
		cursor: pointer;

		input {
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: -1;
			width: 1px;
			height: 1px;
			opacity: 0;
			transform: translate(-50%, -50%);

			&:checked ~ i {
				border-color: $color-monza;
				background: $color-monza;

				&::before {
					opacity: 1;
					visibility: visible;
				}
			}

			&:disabled {
				~ i {
					border-color: #ccc;
					background: #f8f8f8;

					&::before {
						background: #ccc;
					}
				}
			}

			&.error ~ i {
				border-color: $color-monza;
			}
		}

		i {
			position: absolute;
			left: 0;
			top: -2px;
			border: solid 1px #ccc;
			border-radius: 50%;
			width: vm(24);
			height: vm(24);
			background: $color-white;
			transition: $duration-normal;

			&::before {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				border-radius: 50%;
				width: vm(8);
				height: vm(8);
				background: $color-white;
				opacity: 0;
				visibility: hidden;
				transform: translate(-50%, -50%);

				@include desktop {
					width: vw(8);
					height: vw(8);
				}

				@include desktop-xl {
					width: 8px;
					height: 8px;
				}
			}

			@include desktop {
				width: vw(24);
				height: vw(24);
			}

			@include desktop-xl {
				width: 24px;
				height: 24px;
			}
		}

		@include desktop {
			padding: 0 0 0 vw(40);

			&:hover {
				i {
					border-color: $color-black;
				}
			}
		}

		@include desktop-xl {
			padding: 0 0 0 40px;
		}
	}

	label.error {
		display: none !important;
	}

	@include desktop {
		margin-bottom: vw(16);
	}

	@include desktop-xl {
		margin-bottom: 16px;
	}
}
