/**
* Split string into a list
*
* @property {string} $string String
* @property {string} $separator Separator
*
* @example
*	$list: str-split("hello+world", "+"); // (hello, world)
*/

@function str-split($string, $separator) {
	$split-arr: ();
	$index: str-index($string, $separator);

	@while $index != null {
		$item: str-slice($string, 1, $index - 1);
		$split-arr: append($split-arr, $item);
		$string: str-slice($string, $index + 1);
		$index: str-index($string, $separator);
	}

	// stylelint-disable-next-line
	$split-arr: append($split-arr, $string);

	@return $split-arr;
}
