.page-porting {
	&__text {
		margin-bottom: vm(48);

		@include desktop {
			margin-bottom: vw(104);
			max-width: vw(800);
		}

		@include desktop-xl {
			margin-bottom: 104px;
			max-width: 800px;
		}
	}

	&__include {
		margin-bottom: vm(48);

		&__title {
			@include desktop {
				margin-bottom: vw(50);
			}

			@include desktop-xl {
				margin-bottom: 50px;
			}
		}

		&__row {
			display: flex;

			@include mobile {
				flex-wrap: wrap;
			}
		}

		&__list {
			@include desktop {
				flex-shrink: 0;
				margin-right: vw(60);
				width: vw(350);
			}

			@include desktop-xl {
				margin-right: 60px;
				width: 350px;
			}
		}

		&__item {
			display: flex;
			align-items: center;
			margin-bottom: vm(24);

			&__icon {
				flex-shrink: 0;
				margin-right: vm(15);

				img {
					width: vm(46);

					@include desktop {
						width: vw(52);
					}

					@include desktop-xl {
						width: 52px;
					}
				}

				@include desktop {
					margin-right: vw(24);
				}

				@include desktop-xl {
					margin-right: 24px;
				}
			}

			&__text {
				margin: 0;
			}

			@include desktop {
				margin-bottom: vw(32);
			}

			@include desktop-xl {
				margin-bottom: 32px;
			}
		}

		&__text {
			max-width: 800px;
			width: 100%;
		}

		@include desktop {
			margin-bottom: vw(104);
		}

		@include desktop-xl {
			margin-bottom: 104px;
		}
	}

	.services {
		margin-bottom: 0;
	}
}
