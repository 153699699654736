.page-search {
	&__field {
		position: relative;
		margin-bottom: vm(24);

		input {
			display: block;
			outline: none;
			border: 1px solid $color-black;
			border-radius: vm(8);
			padding: 0 vm(24);
			width: 100%;
			height: vm(64);
			background: $color-white;

			@include desktop {
				border-radius: vw(8);
				padding: 0 vw(24);
				height: vw(64);
			}

			@include desktop-xl {
				border-radius: 8px;
				padding: 0 24px;
				height: 64px;
			}
		}

		&__submit {
			position: absolute;
			right: vm(14);
			top: 50%;
			outline: 0;
			border: none;
			padding: vm(10);
			color: $color-black;
			background: $color-white;
			transform: translateY(-50%);

			svg {
				display: block;
				width: vm(16);
				height: vm(16);

				@include desktop {
					width: vw(16);
					height: vw(16);
				}
			
				@include desktop-xl {
					width: 16px;
					height: 16px;
				}
			}

			@include desktop {
				right: vw(14);
				padding: vw(10);

				&:hover {
					color: $color-monza;
				}
			}
		
			@include desktop-xl {
				right: 14px;
				padding: 10px;
			}
		}

		@include desktop {
			margin-bottom: vw(32);
		}
	
		@include desktop-xl {
			margin-bottom: 32px;
		}
	}

	&__found {
		margin-bottom: vm(32);
		font-weight: 700;

		@include desktop {
			margin-bottom: vw(64);
		}
	
		@include desktop-xl {
			margin-bottom: 64px;
		}
	}

	&__item {
		display: block;
		border-radius: vm(8);
		padding: vm(24);
		background: #f8f8f8;

		&__text {
			margin-bottom: vm(24);

			@include desktop {
				margin-bottom: vw(24);
			}
		
			@include desktop-xl {
				margin-bottom: 24px;
			}
		}

		&__info {
			font-weight: 500;
			font-size: vm(8);
			line-height: vm(10);
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: #aeaeae;
			transition: $duration-normal;

			li {
				display: inline;

				+ li {
					position: relative;
					padding-left: vm(22);

					&::before {
						content: "";
						position: absolute;
						left: vm(8);
						top: vm(4);
						border-radius: 50%;
						width: vm(2);
						height: vm(2);
						background: currentColor;

						@include desktop {
							left: vw(8);
							top: vw(5);
							width: vw(3);
							height: vw(3);
						}

						@include desktop-xl {
							left: 8px;
							top: 5px;
							width: 3px;
							height: 3px;
						}
					}

					@include desktop {
						padding-left: vw(23);
					}

					@include desktop-xl {
						padding-left: 23px;
					}
				}
			}

			@include desktop {
				font-size: vw(12);
				line-height: vw(14);
			}

			@include desktop-xl {
				font-size: 12px;
				line-height: 14px;
			}
		}

		& + & {
			margin-top: vm(16);

			@include desktop {
				margin-top: vw(16);
			}
		
			@include desktop-xl {
				margin-top: 16px;
			}
		}

		@include desktop {
			border-radius: vw(16);
			padding: vw(40);

			&:hover {
				color: $color-white;
				background: $color-black;
	
				.page-search__item__info {
					color: #e5e5e5;
				}
			}
		}
	
		@include desktop-xl {
			border-radius: 16px;
			padding: 40px;
		}
	}

	.pagination {
		justify-content: center;
		margin-top: vm(48);
		text-align: center;

		@include desktop {
			margin-top: vw(56);
		}
	
		@include desktop-xl {
			margin-top: 56px;
		}
	}

	@include desktop {
		max-width: vw(1193);
	}

	@include desktop-xl {
		max-width: 1193px;
	}
}
