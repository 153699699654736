.page-publisher {
	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 vm(-10) vm(48);

		@include desktop {
			margin: 0 vw(-10) vw(84);
		}

		@include desktop-xl {
			margin: 0 -10px 84px;
		}
	}

	&__col {
		margin-bottom: vm(20);
		padding: 0 vm(10);
		width: 100%;

		@include desktop {
			margin-bottom: vw(20);
			padding: 0 vw(10);
			width: 33.33334%;
		}

		@include desktop-xl {
			margin-bottom: 20px;
			padding: 0 10px;
		}
	}

	&__col--big {
		@include desktop {
			width: 66.667%;
		}
	}

	&__about {
		&__title {
			@include desktop {
				margin-bottom: vw(48);
			}

			@include desktop-xl {
				margin-bottom: 48px;
			}
		}

		&__text {
			.text-lead {
				margin-bottom: 0;
			}

			@include desktop {
				max-width: vw(491);
			}

			@include desktop-xl {
				max-width: 491px;
			}
		}
	}

	&__item {
		position: relative;
		z-index: 5;
		display: flex;
		flex-direction: column;
		overflow: auto;
		border-radius: vm(8);
		padding: vm(20);
		height: vm(350);
		color: $color-white;

		&__content {
			margin-top: auto;
		}

		&__title {
			@include desktop {
				max-width: vw(400);
			}

			@include desktop-xl {
				max-width: 400px;
			}
		}

		&__text {
			@include desktop {
				display: -webkit-box;
				overflow: hidden;
				height: vw(72);
				max-width: vw(553);
				text-overflow: ellipsis;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
			}

			@include desktop-xl {
				height: 72px;
				max-width: 553px;
			}
		}

		&__image {
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			background: 50% 100% no-repeat;
			background-size: cover;
		}

		@include desktop {
			border-radius: vw(16);
			padding: vw(56);
			height: vw(538);

			&:hover {
				z-index: 10;
			}
		}

		@include desktop-xl {
			border-radius: 16px;
			padding: 56px;
			height: 538px;
		}
	}

	.services {
		margin-bottom: 0;
	}
}
