.footer {
	color: $color-white;
	background: #191919;

	&__top {
		padding: vm(36) 0 vm(13);

		@include desktop {
			display: flex;
			padding: vw(100) 0 vw(68);
		}

		@include desktop-xl {
			padding: 100px 0 68px;
		}
	}

	&__bottom {
		padding: vm(13) 0 vm(35);
		color: #8c8c8c;

		&__menu {
			li {
				+ li {
					margin-top: vm(13);

					@include desktop {
						margin: 0 0 0 vw(30);
					}

					@include desktop-xl {
						margin-left: 30px;
					}
				}

				a {
					color: #8c8c8c;

					@include desktop {
						&:hover {
							color: $color-monza;
						}
					}
				}

				@include mobile {
					&.for-desktop {
						+ li {
							margin-top: 0;
						}
					}
				}
			}

			& + & {
				@include mobile {
					margin-top: vm(13);
				}
			}

			@include desktop {
				display: flex;
			}
		}

		@include desktop {
			display: flex;
			justify-content: space-between;
			padding: vw(28) 0;
		}

		@include desktop-xl {
			padding: 28px 0;
		}
	}

	&__left {
		flex-shrink: 0;
		margin-right: vw(90);
		width: vw(214);

		@include desktop-xl {
			margin-right: 90px;
			width: 214px;
		}
	}

	&__logo {
		margin-bottom: vw(30);

		img {
			width: vw(100);

			@include desktop-xl {
				width: 100px;
			}
		}

		@include desktop-xl {
			margin-bottom: 30px;
		}
	}

	&__slogan {
	}

	&__menu {
		display: flex;
		font-weight: 300;

		ul {
			+ ul {
				margin-left: vw(123);

				@include desktop-xl {
					margin-left: 123px;
				}
			}

			li {
				+ li {
					margin-top: vw(24);

					@include desktop-xl {
						margin-top: 24px;
					}
				}

				a {
					&:hover {
						color: $color-monza;
					}
				}
			}
		}
	}

	&__contacts {
		flex-shrink: 0;
		margin-left: auto;

		&__list {
			font-weight: 300;
			font-size: vm(14);
			line-height: vm(30);

			li {
				position: relative;
				padding-left: vm(33);

				+ li {
					margin-top: vm(10);

					@include desktop {
						margin-top: vw(40);
					}

					@include desktop-xl {
						margin-top: 40px;
					}
				}

				a {
					@include desktop {
						&:hover {
							color: $color-monza;
						}
					}
				}

				@include desktop {
					padding-left: vw(50);
				}

				@include desktop-xl {
					padding-left: 50px;
				}
			}

			&__icon {
				position: absolute;
				left: 0;
				top: vm(5);
				width: vm(21);
				height: vm(21);

				@include desktop {
					top: 0;
					width: vw(30);
					height: vw(30);
				}

				@include desktop-xl {
					width: 30px;
					height: 30px;
				}
			}

			@include desktop {
				font-size: vw(24);
				line-height: vw(30);
			}

			@include desktop-xl {
				font-size: 24px;
				line-height: 30px;
			}
		}

		&__callback {
			margin-top: vm(22);

			@include desktop {
				margin-top: vw(60);
			}

			@include desktop-xl {
				margin-top: 60px;
			}
		}

		@include desktop {
			width: vw(588);
		}

		@include desktop-xl {
			width: 588px;
		}
	}

	@include desktop {
		font-size: vw(18);
		line-height: vw(30);
	}

	@include desktop-xl {
		font-size: 18px;
		line-height: 30px;
	}
}
