.news {
	margin-bottom: vm(36);

	.swiper-container {
		@include desktop {
			margin: 0 vw(-18);
			padding: 0 vw(18);
		}

		@include desktop-xl {
			margin: 0 -18px;
			padding: 0 18px;
		}
	}

	&__title {
		margin-bottom: vm(25);

		@include mobile {
			max-width: vm(211);
			font-size: vm(22);
			line-height: vm(26);
		}

		@include desktop {
			margin-bottom: vw(50);
		}

		@include desktop-xl {
			margin-bottom: 50px;
		}
	}

	&__item {
		display: block;

		&__image {
			position: relative;
			overflow: hidden;
			margin-bottom: vm(16);
			border-radius: vm(8);
			padding-bottom: 54.6%;
			background: 50% 50% no-repeat;
			background-size: cover;
			transition: $duration-normal;

			@include desktop {
				margin-bottom: vw(16);
				border-radius: vw(16);
			}

			@include desktop-xl {
				margin-bottom: 16px;
				border-radius: 16px;
			}
		}

		&__play {
			position: absolute;
			left: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: vm(8);
			width: 100%;
			height: 100%;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));

			&__icon {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				width: vm(36);
				height: vm(36);
				background: #e30613;

				svg {
					width: vm(12);
					height: vm(14);

					@include desktop {
						width: vw(14);
						height: vw(16);
					}

					@include desktop-xl {
						width: 14px;
						height: 16px;
					}
				}

				@include desktop {
					width: vw(48);
					height: vw(48);
				}

				@include desktop-xl {
					width: 48px;
					height: 48px;
				}
			}

			@include desktop {
				border-radius: vw(16);
			}

			@include desktop-xl {
				border-radius: 16px;
			}
		}

		&__preview {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 5;
			width: 100%;
			height: 100%;
			opacity: 0;
			visibility: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&.is-show {
				opacity: 1;
				visibility: visible;
				transition: $duration-normal;
			}
		}

		&__title {
			margin: 0 0 vm(12);
			max-width: vm(184);
			font-weight: 700;
			font-size: vm(14);
			line-height: vm(16);

			@include desktop {
				margin: 0 0 vw(20);
				max-width: none;
				font-size: vw(24);
				line-height: vw(30);
			}

			@include desktop-xl {
				margin: 0 0 20px;
				font-size: 24px;
				line-height: 30px;
			}
		}

		&__info {
			font-weight: 500;
			font-size: vm(8);
			line-height: vm(10);
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: #b3b3b3;

			li {
				display: inline;

				+ li {
					position: relative;
					padding-left: vm(22);

					&::before {
						content: "";
						position: absolute;
						left: vm(8);
						top: vm(4);
						border-radius: 50%;
						width: vm(2);
						height: vm(2);
						background: #b3b3b3;

						@include desktop {
							left: vw(8);
							top: vw(5);
							width: vw(3);
							height: vw(3);
						}

						@include desktop-xl {
							left: 8px;
							top: 5px;
							width: 3px;
							height: 3px;
						}
					}

					@include desktop {
						padding-left: vw(23);
					}

					@include desktop-xl {
						padding-left: 23px;
					}
				}
			}

			@include desktop {
				font-size: vw(12);
				line-height: vw(14);
			}

			@include desktop-xl {
				font-size: 12px;
				line-height: 14px;
			}
		}

		@include desktop {
			width: auto;

			&:hover {
				.news__item__image {
					box-shadow: 0 vw(7) vw(10) rgba($color-black, 0.25);
				}
			}
		}

		@include desktop-xl {
			&:hover {
				.news__item__image {
					box-shadow: 0 7px 10px rgba($color-black, 0.25);
				}
			}
		}
	}

	@include desktop {
		margin-bottom: vw(127);
	}

	@include desktop-xl {
		margin-bottom: 127px;
	}
}
