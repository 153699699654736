/**
* Returns deeply nested property from a map
*
* @function mapGetDeep Deep get for sass maps
* @author https://css-tricks.com/snippets/sass/deep-getset-maps/
* @param {Map} $map - Map
* @param {Arglist} $keys - Key chain
*
* @example
*	$paddings: mapGetDeep($grid-containers, default, paddings, xs);
*/

@function mapGetDeep($map, $keys...) {
	@each $key in $keys {
		$map: map-get($map, $key);
	}

	@return $map;
}
