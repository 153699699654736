.page-cooperation {
	&__subtitle {
		@include desktop {
			margin-bottom: vw(55);
		}

		@include desktop-xl {
			margin-bottom: 55px;
		}
	}

	&__rule {
		margin: 0 0 vm(48);

		&__row {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			margin: 0 vm(-25);

			@include desktop {
				margin: 0 vw(-25);
			}

			@include desktop-xl {
				margin: 0 -25px;
			}
		}

		&__col {
			padding: 0 vm(25);
			width: 100%;

			& + & {
				.page-cooperation__rule__item__point {
					&::before {
						content: "";
						position: absolute;
						right: 50%;
						bottom: 100%;
						margin-bottom: vm(13);
						border-left: 1px dashed #ccc;
						width: 1px;
						height: vm(20);

						@include desktop {
							right: 100%;
							top: 50%;
							margin-right: vw(55);
							margin-bottom: 0;
							border-bottom: 1px dashed #ccc;
							border-left: none;
							width: vw(183);
							height: 1px;
						}

						@include desktop-xl {
							margin-right: 55px;
							width: 183px;
						}
					}
				}

				@include mobile {
					margin-top: vm(40);
				}
			}

			@include desktop {
				padding: 0 vw(25);
				width: 20%;
			}

			@include desktop-xl {
				padding: 0 25px;
			}
		}

		&__item {
			text-align: center;

			&__point {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 auto vm(12);
				border: 1px solid $color-monza;
				border-radius: 50%;
				width: vm(60);
				height: vm(60);

				svg {
					margin-top: vm(5);
					width: vm(43);
					height: vm(24);

					@include desktop {
						margin-top: 0;
						width: vw(47);
						height: vw(28);
					}

					@include desktop-xl {
						width: 47px;
						height: 28px;
					}
				}

				@include desktop {
					margin: 0 auto vw(30);
					width: vw(72);
					height: vw(72);
				}

				@include desktop-xl {
					margin: 0 auto 30px;
					width: 72px;
					height: 72px;
				}
			}

			&__point--red {
				background: $color-monza;
			}

			&__text {
				margin-bottom: 0;
			}
		}

		@include desktop {
			margin: vw(110) 0 vw(160);
		}

		@include desktop-xl {
			margin: 110px 0 160px;
		}
	}

	&__button {
		text-align: center;
		margin-bottom: vm(48);

		@include desktop {
			margin-bottom: vw(160);
		}

		@include desktop-xl {
			margin-bottom: 160px;
		}
	}

	.poster {
		margin-bottom: 0;
	}
}
