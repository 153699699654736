.breadcrumbs {
	margin: 0 0 vm(16);
	font-weight: 500;
	font-size: vm(10);
	line-height: vm(12);
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #999;

	li {
		display: inline;

		+ li {
			position: relative;
			padding-left: vm(22);

			&::before {
				content: "";
				position: absolute;
				left: vm(8);
				top: vm(5);
				border-radius: 50%;
				width: vm(3);
				height: vm(3);
				background: currentColor;

				@include desktop {
					left: vw(8);
					top: vw(5);
					width: vw(3);
					height: vw(3);
				}

				@include desktop-xl {
					left: 8px;
					top: 5px;
					width: 3px;
					height: 3px;
				}
			}

			@include desktop {
				padding-left: vw(22);
			}

			@include desktop-xl {
				padding-left: 22px;
			}
		}

		a {
			&:not([href]) {
				color: #999;
				cursor: text;
			}

			@include desktop {
				&:hover {
					color: $color-monza;
				}
			}
		}
	}

	@include desktop {
		margin: 0 0 vw(8);
		font-size: vw(12);
		line-height: vw(14);
	}

	@include desktop-xl {
		margin: 0 0 8px;
		font-size: 12px;
		line-height: 14px;
	}
}
