.services {
	margin-bottom: vm(46);

	&__title {
		margin-bottom: vm(22);

		@include mobile {
			font-size: vm(22);
			line-height: vm(26);
		}

		@include desktop {
			margin-bottom: vw(50);
		}

		@include desktop-xl {
			margin-bottom: 50px;
		}
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 vm(-8);

		@include desktop {
			margin: 0 vw(-10) vw(-20);
		}

		@include desktop-xl {
			margin: 0 -10px -20px;
		}
	}

	&__col {
		margin-bottom: vm(8);
		width: 100%;

		@include mobile {
			&:nth-child(1) {
				order: -4;
			}

			&:nth-child(2) {
				order: -3;

				.services__item {
					height: vm(364);
				}
			}

			&:nth-child(4) {
				order: -2;
			}

			&:nth-child(3) {
				order: -1;
			}

			&:nth-child(6) {
				order: -1;
			}
		}

		@include desktop {
			margin-bottom: vw(20);
			padding: 0 vw(10);
			width: 25%;
		}

		@include desktop-xl {
			margin-bottom: 20px;
			padding: 0 10px;
		}
	}

	&__col--distribution {
		width: 100%;

		@include desktop {
			width: 75%;
		}
	}

	&__item {
		position: relative;
		z-index: 5;
		display: block;
		border-radius: vm(8);
		padding: vm(20);
		height: vm(320);
		box-sizing: border-box;
		transition: $duration-normal;

		&__title {
			margin: 0 0 vm(10);
			font-weight: 700;
			font-size: vm(18);
			line-height: vm(24);

			@include desktop {
				margin: 0 0 vw(30);
				font-size: vw(32);
				line-height: vw(36);
			}

			@include desktop-xl {
				margin: 0 0 30px;
				font-size: 32px;
				line-height: 36px;
			}
		}

		&__text {
			@include desktop {
				font-size: vw(18);
				line-height: vw(30);
			}

			@include desktop-xl {
				font-size: 18px;
				line-height: 30px;
			}
		}

		&__image {
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			border-radius: vm(8);
			width: 100%;
			height: 100%;
			background: #f6f6f6 50% 100% no-repeat;
			background-size: 100% auto;

			@include desktop {
				border-radius: vw(16);
				background: #f6f6f6 50% 100% no-repeat;
				background-size: 100% auto;
			}

			@include desktop-xl {
				border-radius: 16px;
			}
		}

		&--dark {
			color: $color-white;

			.services__item__image {
				background-color: #191919;
			}
		}

		@include desktop {
			border-radius: vw(16);
			padding: vw(60);
			height: vw(600);

			&:hover {
				z-index: 10;
				box-shadow: 0 vw(7) vw(10) rgba($color-black, 0.25);
			}
		}

		@include desktop-xl {
			border-radius: 16px;
			padding: 60px;
			height: 600px;

			&:hover {
				box-shadow: 0 7px 10px rgba($color-black, 0.25);
			}
		}
	}

	&__distribution {
		position: relative;
		z-index: 5;
		display: block;
		border-radius: vm(8);
		padding: vm(190) vm(20) vm(32);
		height: 100%;
		color: $color-white;
		background: #e30613;
		transition: $duration-normal;

		&__content {
			@include desktop {
				max-width: vw(549);
			}

			@include desktop-xl {
				max-width: 549px;
			}
		}

		&__image {
			position: absolute;
			right: 0;
			bottom: 0;
			z-index: -1;
			width: vw(781);
			pointer-events: none;

			img {
				width: 100%;
			}

			@include mobile {
				top: vm(-47);
				bottom: auto;
				width: vm(310);

				&::after {
					content: "";
					position: absolute;
					right: 0;
					bottom: 0;
					width: vm(300);
					height: vm(135);
					background: linear-gradient(180deg, rgba(227, 6, 19, 0) 0%, $color-monza 100%);
				}
			}

			@include desktop {
				width: vw(781);
			}

			@include desktop-xl {
				width: 781px;
			}
		}

		&__title {
			margin: 0 0 vm(10);
			font-weight: 700;
			font-size: vm(18);
			line-height: vm(24);

			@include desktop {
				margin: 0 0 vw(30);
				font-size: vw(32);
				line-height: vw(36);
			}

			@include desktop-xl {
				margin: 0 0 30px;
				font-size: 32px;
				line-height: 36px;
			}
		}

		&__text {
			margin-bottom: vm(32);

			@include desktop {
				margin-bottom: vw(60);
				max-width: vw(468);
				font-size: vw(18);
				line-height: vw(30);
			}

			@include desktop-xl {
				margin-bottom: 60px;
				max-width: 468px;
				font-size: 18px;
				line-height: 30px;
			}
		}

		&__list {
			@include desktop {
				display: flex;
				flex-wrap: wrap;
				margin: 0 vw(-15);
			}

			@include desktop-xl {
				margin: 0 -15px;
			}
		}

		&__col {
			& + & {
				@include mobile {
					margin-top: vm(20);
				}
			}

			@include desktop {
				padding: 0 vw(15);
				width: 50%;
			}

			@include desktop-xl {
				padding: 0 15px;
			}
		}

		&__item {
			display: flex;
			align-items: center;

			&__image {
				flex-shrink: 0;
				margin-right: vm(20);

				img {
					width: vm(43);

					@include desktop {
						width: vw(74);
					}

					@include desktop-xl {
						width: 74px;
					}
				}

				@include desktop {
					margin-right: vw(24);
				}

				@include desktop-xl {
					margin-right: 24px;
				}
			}

			&__text {
				width: 100%;
				max-width: vm(200);
				font-weight: 700;
				font-size: vm(14);
				line-height: vm(18);

				@include desktop {
					max-width: vw(150);
					font-size: vw(16);
					line-height: vw(20);
				}

				@include desktop-xl {
					max-width: 150px;
					font-size: 16px;
					line-height: 20px;
				}
			}

			& + & {
				margin-top: vm(20);

				@include desktop {
					margin-top: vw(60);
				}

				@include desktop-xl {
					margin-top: 60px;
				}
			}
		}

		&__button {
			.button {
				@include desktop {
					min-width: vw(250);
				}

				@include desktop-xl {
					min-width: 250px;
				}
			}
		}

		@include desktop {
			border-radius: vw(16);
			padding: vw(60);

			&:hover {
				z-index: 10;
				box-shadow: 0 vw(7) vw(10) rgba($color-black, 0.25);
			}
		}

		@include desktop-xl {
			border-radius: 16px;
			padding: 60px;

			&:hover {
				box-shadow: 0 7px 10px rgba($color-black, 0.25);
			}
		}
	}

	@include desktop {
		margin-bottom: vw(160);
	}

	@include desktop-xl {
		margin-bottom: 160px;
	}
}

.services--slider {
	margin-bottom: vm(48);

	.swiper-container {
		@include desktop {
			overflow: hidden;
			margin: vw(-25) vw(-18);
			padding: vw(25) vw(18);
		}

		@include desktop-xl {
			margin: -25px -18px;
			padding: 25px 18px;
		}
	}

	@include desktop {
		margin-bottom: vw(104);
	}

	@include desktop-xl {
		margin-bottom: 104px;
	}
}
