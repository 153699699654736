.button {
	display: inline-block;
	vertical-align: middle;
	border: none;
	border-radius: vm(8);
	padding: vm(21) vm(10);
	min-width: 100%;
	font-weight: 700;
	font-size: vm(14);
	line-height: vm(18);
	letter-spacing: 0.1em;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	color: $color-white;
	background: $color-monza;
	transition: $duration-normal;

	&:disabled,
	&.is-disabled {
		background: #ccc;
		pointer-events: none;
	}

	&.is-loading {
		position: relative;
		color: transparent;
		pointer-events: none;
		user-select: none;

		&::before {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			margin: vm(-9) 0 0 vm(-9);
			border: solid vm(2) $color-white;
			border-bottom-color: rgba($color-white, 0.2);
			border-radius: 50%;
			width: vm(18);
			height: vm(18);
			animation: buttonLoading linear 1s infinite;

			@include desktop {
				margin: vw(-9) 0 0 vw(-9);
				border: solid vw(2) $color-white;
				border-bottom-color: rgba($color-white, 0.2);
				width: vw(18);
				height: vw(18);
			}

			@include desktop-xl {
				margin: -9px 0 0 -9px;
				border: solid 2px $color-white;
				border-bottom-color: rgba($color-white, 0.2);
				width: 18px;
				height: 18px;
			}
		}
	}

	@include desktop {
		border-radius: vw(8);
		padding: vw(19) vw(10);
		min-width: vw(283);
		font-size: vw(18);
		line-height: vw(22);

		&:hover {
			background: #ff5050;
		}
	}

	@include desktop-xl {
		border-radius: 8px;
		padding: 19px 10px;
		min-width: 283px;
		font-size: 18px;
		line-height: 22px;
	}
}

.button--small {
	letter-spacing: 0;

	@include desktop {
		min-width: vw(160);
		font-size: vw(14);
	}

	@include desktop-xl {
		min-width: 160px;
		font-size: 14px;
	}
}

.button--dark {
	background: $color-black;

	@include desktop {
		&:hover {
			background: lighten($color-black, 15%);
		}
	}
}

.button--outline {
	border: solid 2px $color-black;
	color: $color-black;
	background: transparent;

	&:disabled,
	&.is-disabled {
		border-color: #ccc;
		color: #ccc;
		background: transparent;
		pointer-events: none;
	}

	@include desktop {
		padding: vw(17) vw(20);
		min-width: vw(212);

		&:hover {
			border-color: $color-monza;
			color: $color-monza;
			background: transparent;
		}
	}

	@include desktop-xl {
		padding: 17px 20px;
		min-width: 212px;
	}
}

.button--outline-light {
	border: solid 2px $color-white;
	color: $color-white;
	background: transparent;

	&:disabled,
	&.is-disabled {
		border-color: #ccc;
		color: #ccc;
		background: transparent;
		pointer-events: none;
	}

	@include desktop {
		padding: vw(17) vw(20);
		min-width: vw(212);

		&:hover {
			border-color: $color-monza;
			color: $color-monza;
			background: transparent;
		}
	}

	@include desktop-xl {
		padding: 17px 20px;
		min-width: 212px;
	}
}

@keyframes buttonLoading {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
