.page-contacts {
	&__side {
		margin-top: vm(32);

		@include desktop {
			flex-shrink: 0;
			margin: 0 vw(20) 0 0;
			width: vw(435);
		}

		@include desktop-xl {
			margin-right: 20px;
			width: 435px;
		}
	}

	&__map {
		position: relative;
		width: 100%;
		min-width: 0;

		&__container {
			overflow: hidden;
			border-radius: 0 0 vm(16) vm(16);
			height: vm(400);

			@include desktop {
				border-radius: vw(16);
				height: 100%;
				min-height: vw(800);
			}

			@include desktop-xl {
				border-radius: 16px;
				min-height: 800px;
			}
		}

		&__content {
			border-radius: vm(16) vm(16) 0 0;
			padding: vm(24);
			background: #f8f8f8;

			@include desktop {
				position: absolute;
				left: vw(88);
				right: vw(88);
				top: vw(88);
				z-index: 5;
				display: flex;
				border-radius: vw(16);
				padding: vw(56) vw(64);
				background: $color-white;
			}

			@include desktop-xl {
				left: 88px;
				right: 88px;
				top: 88px;
				border-radius: 16px;
				padding: 56px 64px;
			}
		}

		&__offices {
			margin-bottom: vm(32);
			font-weight: 700;
			font-size: vm(20);
			line-height: 1.12;

			li {
				+ li {
					margin-top: vm(16);

					@include desktop {
						margin-top: vw(20);
					}

					@include desktop-xl {
						margin-top: 20px;
					}
				}

				a {
					opacity: 0.2;

					&.is-active {
						opacity: 1;
					}

					@include desktop {
						&:hover {
							opacity: 1;
						}
					}
				}
			}

			@include desktop {
				margin: 0 vw(56) 0 0;
				font-size: vw(32);
			}

			@include desktop-xl {
				margin-right: 56px;
				font-size: 32px;
			}
		}

		&__list {
			&__item {
				display: none;

				li {
					position: relative;
					padding: vm(1) 0 vm(1) vm(33);

					+ li {
						margin-top: vm(16);

						@include desktop {
							margin-top: vw(24);
						}

						@include desktop-xl {
							margin-top: 24px;
						}
					}

					a {
						@include desktop {
							&:hover {
								color: $color-monza;
							}
						}
					}

					@include desktop {
						padding: vw(3) 0 vw(3) vw(50);
					}

					@include desktop-xl {
						padding: 3px 0 3px 50px;
					}
				}

				&__icon {
					position: absolute;
					left: 0;
					top: 0;
					width: vm(21);
					height: vm(21);

					@include desktop {
						width: vw(30);
						height: vw(30);
					}

					@include desktop-xl {
						width: 30px;
						height: 30px;
					}
				}

				&.is-active {
					display: block;
				}

				@include desktop {
					font-size: vw(20);
				}

				@include desktop-xl {
					font-size: 20px;
				}
			}
		}
	}

	&__support {
		margin-bottom: vm(24);
		border-radius: vm(16);
		padding: vm(24);
		color: $color-white;
		background: $color-black;

		&__phone {
			margin-bottom: vm(8);
			font-weight: 300;
			font-size: vm(20);
			line-height: vm(24);

			a {
				@include desktop {
					&:hover {
						color: $color-monza;
					}
				}
			}

			@include desktop {
				margin-bottom: vw(8);
				font-size: vw(24);
				line-height: vw(29);
			}

			@include desktop-xl {
				margin-bottom: 8px;
				font-size: 24px;
				line-height: 29px;
			}
		}

		&__text {
			font-size: vm(12);
			line-height: vm(19);
			color: #b3b3b3;

			@include desktop {
				font-size: vw(16);
				line-height: vw(19);
			}

			@include desktop-xl {
				font-size: 16px;
				line-height: 19px;
			}
		}

		&__email {
			margin-top: vm(16);
			font-weight: 300;
			font-size: vm(18);
			line-height: vm(22);

			a {
				@include desktop {
					&:hover {
						color: $color-monza;
					}
				}
			}

			@include desktop {
				margin-top: vw(24);
				font-size: vw(20);
				line-height: vw(24);
			}

			@include desktop-xl {
				margin-top: 24px;
				font-size: 20px;
				line-height: 24px;
			}
		}

		@include desktop {
			margin-bottom: vw(24);
			border-radius: vw(16);
			padding: vw(56) vw(48);
		}

		@include desktop-xl {
			margin-bottom: 24px;
			border-radius: 16px;
			padding: 56px 48px;
		}
	}

	&__list {
		border-radius: vm(16);
		padding: vm(24);
		background: #f8f8f8;

		&__item {
			&__key {
				margin-bottom: 8px;
				font-weight: 700;
				font-size: vm(20);
				line-height: 1.2;

				@include desktop {
					margin-bottom: vw(8);
					font-size: vw(24);
					line-height: 1.33;
				}

				@include desktop-xl {
					margin-bottom: 8px;
					font-size: 24px;
				}
			}

			&__value {
				font-weight: 300;

				@include desktop {
					font-size: vw(20);
				}

				@include desktop-xl {
					font-size: 20px;
				}
			}

			a {
				@include desktop {
					&:hover {
						color: $color-monza;
					}
				}
			}

			& + & {
				margin-top: vm(12);
				border-top: solid 1px #e9e9e9;
				padding-top: vm(12);

				@include desktop {
					margin-top: vw(24);
					padding-top: vw(24);
				}

				@include desktop-xl {
					margin-top: 24px;
					padding-top: 24px;
				}
			}
		}

		&__callback {
			margin-top: vm(32);

			.button {
				width: 100%;
			}

			@include desktop {
				margin-top: vw(40);
			}

			@include desktop-xl {
				margin-top: 40px;
			}
		}

		@include desktop {
			border-radius: vw(16);
			padding: vw(56) vw(48);
		}

		@include desktop-xl {
			border-radius: 16px;
			padding: 56px 48px;
		}
	}

	@include desktop {
		display: flex;
		flex-direction: row-reverse;
	}
}
