.media {
	margin-bottom: vm(48);

	&__video {
		position: relative;
		margin: 0 vm(-10) vm(16);

		video {
			display: block;
			width: 100%;
			object-fit: cover;

			@include mobile {
				height: vm(300);
			}
		}

		&__play {
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: 5;
			outline: none;
			border: none;
			padding: 0;
			background: none;
			transform: translate(-50%, -50%);

			svg {
				display: block;
				width: vm(65);
				height: vm(65);

				@include desktop {
					width: vw(74);
					height: vw(86);
				}

				@include desktop-xl {
					width: 74px;
					height: 86px;
				}
			}

			.is-playing & {
				opacity: 0;
				visibility: hidden;
			}

			@include desktop {
				&:hover {
					transform: translate(-50%, -50%) scale(1.2);
				}
			}
		}

		@include desktop {
			margin: 0 0 vw(16);
		}

		@include desktop-xl {
			margin: 0 0 16px;
		}
	}

	&__description {
		margin-bottom: vm(24);
		font-size: vm(13);
		line-height: vm(16);

		@include desktop {
			margin-bottom: vw(32);
			font-size: vw(14);
			line-height: vw(20);
		}

		@include desktop-xl {
			margin-bottom: 32px;
			font-size: 14px;
			line-height: 20px;
		}
	}

	&__text {
		margin-top: vm(24);

		@include desktop {
			margin-top: vw(32);
			max-width: vw(890);
		}

		@include desktop-xl {
			margin-top: 32px;
			max-width: 890px;
		}
	}

	@include desktop {
		margin-bottom: vw(104);
	}

	@include desktop-xl {
		margin-bottom: 104px;
	}
}
