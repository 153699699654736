.page-news {
	&__menu {
		margin-bottom: vm(24);
		padding: vm(22) 0;
		background: #f8f8f8;

		&__list {
			ul {
				text-align: center;

				li {
					display: inline-block;
					vertical-align: middle;

					+ li {
						margin-left: vm(16);

						@include desktop {
							margin-left: vw(48);
						}

						@include desktop-xl {
							margin-left: 48px;
						}
					}

					label {
						position: relative;
						display: inline-block;
						cursor: pointer;

						input {
							position: absolute;
							left: 50%;
							top: 50%;
							z-index: -1;
							width: 1px;
							height: 1px;
							opacity: 0;
							transform: translate(-50%, -50%);

							&:checked ~ span {
								color: $color-monza;
							}
						}

						span {
							font-weight: 700;
							font-size: vm(18);
							color: rgba($color-black, 0.3);
							transition: $duration-normal;

							@include desktop {
								font-size: vw(24);
							}

							@include desktop-xl {
								font-size: 24px;
							}
						}

						@include desktop {
							&:hover {
								span {
									color: $color-monza;
								}
							}
						}
					}
				}

				@include mobile {
					overflow: auto;
					margin: 0 vm(-10);
					padding: 0 vm(10) vm(12);
					white-space: nowrap;

					// &::-webkit-scrollbar {
					// 	display: none;
					// 	width: 0;
					// 	height: 0;
					// }
				}
			}
		}

		@include desktop {
			margin-bottom: vw(48);
			padding: vw(42) 0;
		}

		@include desktop-xl {
			margin-bottom: 48px;
			padding: 42px 0;
		}
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 vm(-10);

		@include desktop {
			margin: 0 vw(-10);
		}

		@include desktop-xl {
			margin: 0 -10px;
		}
	}

	&__col {
		margin-bottom: vm(20);
		padding: 0 vm(10);
		width: 100%;

		.subscribe {
			margin-bottom: 0;
		}

		@include desktop {
			margin-bottom: vw(20);
			padding: 0 vw(10);
			width: 25%;
		}

		@include desktop-xl {
			margin-bottom: 20px;
			padding: 0 10px;
		}
	}

	&__col--big {
		@include desktop {
			width: 50%;
		}
	}

	&__item {
		position: relative;
		display: block;
		height: 100%;

		&__image {
			position: relative;
			overflow: hidden;
			margin-bottom: vm(16);
			border-radius: vm(8);
			padding-bottom: 73.603%;

			span {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: 50% 50% no-repeat;
				background-size: cover;
			}

			@include desktop {
				margin-bottom: vw(16);
				border-radius: vw(16);
			}

			@include desktop-xl {
				margin-bottom: 16px;
				border-radius: 16px;
			}
		}

		&__title {
			margin: 0 0 vm(16);
			transition: $duration-normal;

			@include desktop {
				margin: 0 0 vw(16);
			}

			@include desktop-xl {
				margin: 0 0 16px;
			}
		}

		&__text {
			margin-bottom: vm(24);

			@include desktop {
				margin-bottom: vw(24);
			}

			@include desktop-xl {
				margin-bottom: 24px;
			}
		}

		&__info {
			font-weight: 500;
			font-size: vm(12);
			line-height: vm(14);
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: rgba($color-black, 0.3);

			ul {
				li {
					display: inline;

					+ li {
						position: relative;
						padding-left: vm(22);

						&::before {
							content: "";
							position: absolute;
							left: vm(8);
							top: vm(5);
							border-radius: 50%;
							width: vm(3);
							height: vm(3);
							background: currentColor;

							@include desktop {
								left: vw(8);
								top: vw(5);
								width: vw(3);
								height: vw(3);
							}

							@include desktop-xl {
								left: 8px;
								top: 5px;
								width: 3px;
								height: 3px;
							}
						}

						@include desktop {
							padding-left: vw(22);
						}

						@include desktop-xl {
							padding-left: 22px;
						}
					}
				}
			}

			@include desktop {
				font-size: vw(12);
				line-height: vw(14);
			}

			@include desktop-xl {
				font-size: 12px;
				line-height: 14px;
			}
		}

		&:hover {
			.page-news__item__title {
				color: $color-monza;
			}
		}
	}

	&__item--big {
		@include desktop {
			display: flex;
			flex-direction: column;
			height: vw(559);
			color: $color-white;

			.page-news__item {
				&__image {
					position: absolute;
					z-index: -1;
					padding-bottom: 62.81%;
					width: 100%;
				}

				&__content {
					margin-top: auto;
					padding: 0 vw(411) vw(56) vw(56);
				}

				&__info {
					color: rgba($color-white, 0.3);
				}
			}
		}

		@include desktop-xl {
			height: 559px;

			.page-news__item__content {
				padding: 0 411px 56px 56px;
			}
		}
	}

	.pagination {
		justify-content: center;
		margin-top: vm(20);
		padding: 0 vm(10);
		width: 100%;
		text-align: center;

		@include desktop {
			margin-top: vw(68);
			padding: 0;
		}

		@include desktop-xl {
			margin-top: 68px;
		}
	}
}
