.poster {
	position: relative;
	margin-bottom: vm(48);

	.swiper-slide {
		height: auto;
	}

	&__item {
		position: relative;
		z-index: 5;
		display: flex;
		align-items: center;
		overflow: hidden;
		border-radius: vm(8);
		padding: vm(32) vm(20);
		height: 100%;
		box-sizing: border-box;
		color: $color-white;

		&__image {
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			width: 200%;
			height: 100%;
			background: 50% 100% no-repeat;
			background-size: cover;

			@include mobile {
				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: rgba($color-black, 0.7);
				}
			}

			@include desktop {
				width: 100%;
			}
		}

		&__content {
			@include desktop {
				margin-left: auto;
				width: vw(586);
			}

			@include desktop-xl {
				width: 586px;
			}
		}

		&__subtitle {
			@include desktop {
				margin-bottom: vw(16);
			}

			@include desktop-xl {
				margin-bottom: 16px;
			}
		}

		&__title {
			margin-top: 0;

			@include desktop {
				margin-bottom: vw(60);
			}

			@include desktop-xl {
				margin-bottom: 60px;
			}
		}

		&__point {
			display: flex;
			align-items: center;

			&__icon {
				flex-shrink: 0;
				margin-right: vm(16);

				img {
					width: vm(38);

					@include desktop {
						width: vw(48);
					}

					@include desktop-xl {
						width: 48px;
					}
				}

				@include desktop {
					margin-right: vw(24);
				}

				@include desktop-xl {
					margin-right: 24px;
				}
			}

			& + & {
				margin-top: vm(24);

				@include desktop {
					margin-top: vw(32);
				}

				@include desktop-xl {
					margin-top: 32px;
				}
			}
		}

		&__text {
			margin-top: vm(24);

			@include desktop {
				margin-top: vw(32);
			}

			@include desktop-xl {
				margin-top: 32px;
			}
		}

		@include desktop {
			border-radius: vw(16);
			padding: vw(40) vw(152) vw(40) 0;
			height: vw(765);
		}

		@include desktop-xl {
			border-radius: 16px;
			padding: 40px 152px 40px 0;
			height: 765px;
		}
	}

	&__prev,
	&__next {
		position: absolute;
		top: 50%;
		z-index: 5;
		transform: translateY(-50%);

		&.swiper-button-disabled {
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
		}
	}

	&__prev {
		left: vm(32);

		@include desktop {
			left: vw(48);
		}

		@include desktop-xl {
			left: 48px;
		}
	}

	&__next {
		right: vm(32);

		@include desktop {
			right: vw(48);
		}

		@include desktop-xl {
			right: 48px;
		}
	}

	@include desktop {
		margin-bottom: vw(104);
	}

	@include desktop-xl {
		margin-bottom: 104px;
	}
}
