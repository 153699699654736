.subscribe {
	position: relative;
	z-index: 5;
	margin: 0 vm(-10) vm(48);
	padding: vm(56) vm(10) vm(37);
	color: $color-white;
	background: $color-black;

	&__image {
		position: absolute;
		right: vm(13);
		top: vm(-28);
		z-index: -1;
		pointer-events: none;

		img {
			width: vm(117);

			@include desktop {
				width: vw(127);
			}

			@include desktop-xl {
				width: 127px;
			}
		}

		@include desktop {
			right: vw(13);
			top: vw(-28);
		}

		@include desktop-xl {
			right: 13px;
			top: -28px;
		}
	}

	&__title {
		margin-top: 0;
		max-width: vm(240);

		@include desktop {
			margin: 0 0 vw(33);
			max-width: none;
		}

		@include desktop-xl {
			margin: 0 0 33px;
		}
	}

	&__input {
		margin-bottom: vm(24);

		.input {
			margin-bottom: 0;

			&__field {
				input {
					border-color: #303030;
					color: $color-white;
					background: #303030;

					&:focus {
						border-color: $color-white;
					}

					&::placeholder {
						color: $color-white;
					}

					&.error {
						border-color: $color-monza;
					}
				}

				label.error {
					// position: absolute;
					// top: 100%;
				}
			}
		}

		@include desktop {
			margin-bottom: vw(32);
		}

		@include desktop-xl {
			margin-bottom: 32px;
		}
	}

	&__checkbox {
		margin-bottom: vm(24);

		.checkbox {
			margin-bottom: 0;
			font-size: vm(14);
			line-height: vm(20);

			label {
				i {
					top: vm(3);
					border-color: $color-white;
					border-radius: vm(6);
					background: $color-black;

					@include desktop {
						top: vw(3);
						border-radius: vw(6);
					}

					@include desktop-xl {
						top: 3px;
						border-radius: 6px;
					}
				}

				a {
					font-weight: 700;

					&:hover {
						color: $color-monza;
					}
				}
			}

			@include desktop {
				font-size: vw(14);
				line-height: vw(20);
			}

			@include desktop-xl {
				font-size: 14px;
				line-height: 20px;
			}
		}

		@include desktop {
			margin-bottom: vw(48);
		}

		@include desktop-xl {
			margin-bottom: 48px;
		}
	}

	&__button {
		margin-bottom: vm(24);

		.button {
			width: 100%;
			font-weight: 700;
			font-size: vm(14);
			letter-spacing: normal;

			@include desktop {
				font-size: vw(14);
			}

			@include desktop-xl {
				font-size: 14px;
			}
		}

		@include desktop {
			margin-bottom: vw(32);
		}

		@include desktop-xl {
			margin-bottom: 32px;
		}
	}

	&__text {
		font-size: vm(14);
		line-height: vm(20);

		@include desktop {
			font-size: vw(14);
			line-height: vw(20);
		}

		@include desktop-xl {
			font-size: 14px;
			line-height: 20px;
		}
	}

	@include desktop {
		margin: 0;
		border-radius: vw(16);
		padding: vw(56);
	}

	@include desktop-xl {
		border-radius: 16px;
		padding: 56px;
	}
}
