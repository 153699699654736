.search {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999;
	overflow: hidden;
	padding: vw(52) 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	transition: $duration-normal;

	&__close {
		position: absolute;
		right: vm(20);
		top: vm(17);
		z-index: 10;
		outline: none;
		border: none;
		padding: 0;
		width: vm(24);
		height: vm(24);
		color: $color-white;
		background: none;

		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -1px;
			width: 100%;
			height: 1px;
			background: currentColor;
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}

		svg {
			display: none;
		}

		@include desktop {
			right: vw(50);
			top: vw(40);
			width: vw(24);
			height: vw(24);

			&:hover {
				color: $color-monza;
			}
		}

		@include desktop-xl {
			right: 50px;
			top: 40px;
			width: 24px;
			height: 24px;
		}
	}

	&__overlay {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: $color-black;
		opacity: 0.8;
		cursor: pointer;
	}

	&__container {
		position: relative;
		z-index: 5;
		margin: 0 auto;
		max-width: vw(1193);

		@include desktop-xl {
			max-width: 1193px;
		}
	}

	&__box {
		position: relative;
		margin-bottom: vm(10);

		@include desktop {
			margin-bottom: vw(10);
		}

		@include desktop-xl {
			margin-bottom: 10px;
		}
	}

	&__field {
		input {
			display: block;
			outline: none;
			border: 1px solid $color-black;
			border-radius: vm(8);
			padding: 0 vm(24);
			width: 100%;
			height: vm(64);
			background: $color-white;

			@include desktop {
				border-radius: vw(8);
				padding: 0 vw(24);
				height: vw(64);
			}

			@include desktop-xl {
				border-radius: 8px;
				padding: 0 24px;
				height: 64px;
			}
		}
	}

	&__submit {
		position: absolute;
		right: vm(14);
		top: 50%;
		outline: 0;
		border: none;
		padding: vm(10);
		color: $color-black;
		background: $color-white;
		transform: translateY(-50%);

		svg {
			display: block;
			width: vm(16);
			height: vm(16);

			@include desktop {
				width: vw(16);
				height: vw(16);
			}

			@include desktop-xl {
				width: 16px;
				height: 16px;
			}
		}

		@include desktop {
			right: vw(14);
			padding: vw(10);

			&:hover {
				color: $color-monza;
			}
		}

		@include desktop-xl {
			right: 14px;
			padding: 10px;
		}
	}

	&__result {
		display: none;
		border-radius: vw(16);
		padding: vw(40);
		color: $color-white;
		background: $color-black;

		&__item {
			display: block;
			border-bottom: solid 1px #3d3d3d;
			padding-bottom: vw(24);

			&__link {
				@include desktop {
					&:hover {
						.search__result__item__title {
							color: $color-monza;
						}
					}
				}
			}

			&__title {
				transition: $duration-normal;
			}

			&__text {
				margin-bottom: vm(24);

				@include desktop {
					margin-bottom: vw(24);
				}

				@include desktop-xl {
					margin-bottom: 24px;
				}
			}

			&__info {
				font-weight: 500;
				font-size: vm(8);
				line-height: vm(10);
				letter-spacing: 0.1em;
				text-transform: uppercase;
				transition: $duration-normal;

				li {
					display: inline;

					+ li {
						position: relative;
						padding-left: vm(22);

						&::before {
							content: "";
							position: absolute;
							left: vm(8);
							top: vm(4);
							border-radius: 50%;
							width: vm(2);
							height: vm(2);
							background: currentColor;

							@include desktop {
								left: vw(8);
								top: vw(5);
								width: vw(3);
								height: vw(3);
							}

							@include desktop-xl {
								left: 8px;
								top: 5px;
								width: 3px;
								height: 3px;
							}
						}

						@include desktop {
							padding-left: vw(23);
						}

						@include desktop-xl {
							padding-left: 23px;
						}
					}
				}

				@include desktop {
					font-size: vw(12);
					line-height: vw(14);
				}

				@include desktop-xl {
					font-size: 12px;
					line-height: 14px;
				}
			}

			& + & {
				margin-top: vm(16);

				@include desktop {
					margin-top: vw(24);
				}

				@include desktop-xl {
					margin-top: 24px;
				}
			}

			@include desktop-xl {
				padding-bottom: 24px;
			}
		}

		&__all {
			margin-top: vw(40);
			text-align: center;

			@include desktop-xl {
				margin-top: 40px;
			}
		}

		.is-show-result & {
			display: block;
		}

		@include desktop-xl {
			border-radius: 16px;
			padding: 40px;
		}
	}

	.is-show-search & {
		opacity: 1;
		visibility: visible;
	}

	@include desktop-xl {
		padding: 52px 0;
	}
}
