.page-about {
	&__menu {
		margin-bottom: vm(36);
		padding: vm(21) 0;
		background: #f8f8f8;

		&__list {
			ul {
				text-align: center;

				li {
					display: inline-block;
					vertical-align: middle;

					+ li {
						margin-left: vm(16);

						@include desktop {
							margin-left: vw(48);
						}

						@include desktop-xl {
							margin-left: 48px;
						}
					}

					a {
						margin: 0;
						color: rgba($color-black, 0.3);

						&:hover {
							color: $color-monza;
						}
					}
				}

				@include mobile {
					overflow: auto;
					margin: 0 vm(-10);
					padding: 0 vm(10);
					white-space: nowrap;

					&::-webkit-scrollbar {
						display: none;
						width: 0;
						height: 0;
					}
				}
			}
		}

		@include desktop {
			margin-bottom: vw(85);
			padding: vw(41) 0;
		}

		@include desktop-xl {
			margin-bottom: 85px;
			padding: 41px 0;
		}
	}

	&__mission {
		margin-bottom: vm(48);

		&__row {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vm(-10);

			@include desktop {
				margin: 0 vw(-10);
			}

			@include desktop-xl {
				margin: 0 -10px;
			}
		}

		&__col {
			padding: 0 vm(10);
			width: 100%;

			& + & {
				@include mobile {
					margin-top: vm(16);
				}
			}

			@include desktop {
				padding: 0 vw(10);
				width: 50%;
			}

			@include desktop-xl {
				padding: 0 10px;
			}
		}

		&__item {
			margin: 0 vm(-10);
			padding: vm(32) vm(10);
			height: 100%;
			background: #f2f2f2;

			&__title {
				margin-bottom: vm(24);
				font-weight: 700;
				font-size: vm(32);
				line-height: vm(32);

				@include desktop {
					margin-bottom: vw(32);
					font-size: vw(40);
					line-height: vw(48);
				}

				@include desktop-xl {
					margin-bottom: 32px;
					font-size: 40px;
					line-height: 48px;
				}
			}

			&__text {
				margin-bottom: 0;
			}

			@include desktop {
				margin: 0;
				border-radius: vw(16);
				padding: vw(72);
			}

			@include desktop-xl {
				border-radius: 16px;
				padding: 72px;
			}
		}

		@include desktop {
			margin-bottom: vw(180);
		}

		@include desktop-xl {
			margin-bottom: 180px;
		}
	}

	&__today {
		margin-bottom: vm(48);

		&__title {
			@include desktop {
				margin-bottom: vw(70);
			}

			@include desktop-xl {
				margin-bottom: 70px;
			}
		}

		&__content {
			position: relative;

			@include desktop {
				display: flex;
				justify-content: space-between;
			}
		}

		&__item {
			width: 100%;

			@include desktop {
				width: vw(412);
			}

			@include desktop-xl {
				width: 412px;
			}
		}

		&__image {
			margin: vm(16) 0;

			img {
				width: 100%;
			}

			@include desktop {
				position: absolute;
				left: 50%;
				top: vw(-106);
				z-index: -1;
				margin: 0;
				width: vw(1080);
				transform: translateX(-50%);
			}

			@include desktop-xl {
				top: -106px;
				width: 1080px;
			}
		}

		&__text {
			margin: 0;
			font-weight: 400;

			a {
				&:hover {
					color: $color-monza;
				}
			}

			& + & {
				margin-top: vm(16);

				@include desktop {
					margin-top: vw(56);
				}

				@include desktop-xl {
					margin-top: 56px;
				}
			}
		}

		@include desktop {
			margin-bottom: vw(255);
		}

		@include desktop-xl {
			margin-bottom: 255px;
		}
	}

	&__growth {
		margin-bottom: vm(48);

		&__title {
			@include desktop {
				margin-bottom: vw(64);
			}

			@include desktop-xl {
				margin-bottom: 64px;
			}
		}

		&__text {
			margin-bottom: vm(32);

			@include desktop {
				margin-bottom: vw(60);
				max-width: vw(800);
			}

			@include desktop-xl {
				margin-bottom: 60px;
				max-width: 800px;
			}
		}

		&__row {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vm(-10);

			@include desktop {
				margin: 0 vw(-10)
			}

			@include desktop-xl {
				margin: 0 -10px;
			}
		}

		&__row--width-25 {
			.page-about__growth__col {
				@include desktop {
					width: 25%;
				}
			}
		}

		&__col {
			padding: 0 vm(10);
			width: 100%;

			& + & {
				@include mobile {
					margin-top: vm(16);
				}
			}

			@include desktop {
				padding: 0 vw(10);
				width: 33.33334%;
			}

			@include desktop-xl {
				padding: 0 10px;
			}
		}

		&__item {
			position: relative;
			z-index: 5;
			border-radius: vm(8);
			padding: vm(75) vm(32) vm(48);
			height: 100%;
			background: #f8f8f8;

			&__image {
				position: absolute;
				right: 0;
				top: 0;
				z-index: -1;
				pointer-events: none;

				img {
					width: vm(85);

					@include desktop {
						width: vw(152);
					}

					@include desktop-xl {
						width: 152px;
					}
				}
			}

			&__title {
				margin-top: 0;

				@include desktop {
					margin-bottom: vw(24);
					max-width: vw(335);
				}

				@include desktop-xl {
					margin-bottom: 24px;
					max-width: 335px;
				}
			}

			@include desktop {
				border-radius: vw(16);
				padding: vw(45) vw(152) vw(48) vw(52);
			}

			@include desktop-xl {
				border-radius: 16px;
				padding: 45px 152px 48px 52px;
			}
		}

		&__item--dark {
			color: $color-white;
			background: $color-black;
		}

		&__item--red {
			color: $color-white;
			background: $color-monza;
		}

		@include desktop {
			margin-bottom: vw(104);
		}

		@include desktop-xl {
			margin-bottom: 104px;
		}
	}

	&__team {
		margin-bottom: vm(48);

		&__title {
			@include desktop {
				margin-bottom: vw(48);
			}

			@include desktop-xl {
				margin-bottom: 48px;
			}
		}

		&__heading {
			margin-bottom: vm(24);

			@include desktop {
				display: flex;
				align-items: flex-end;
				margin-bottom: vw(64);
			}

			@include desktop-xl {
				margin-bottom: 64px;
			}
		}

		&__text {
			margin-bottom: vm(24);

			@include desktop {
				margin-right: auto;
				margin-bottom: 0;
				max-width: vw(706);
			}

			@include desktop-xl {
				max-width: 706px;
			}
		}

		&__menu {
			ul {
				text-align: center;

				li {
					display: inline-block;
					vertical-align: middle;

					+ li {
						margin-left: vm(16);

						@include desktop {
							margin-left: vw(24);
						}

						@include desktop-xl {
							margin-left: 24px;
						}
					}

					a {
						font-weight: 700;
						font-size: vm(20);
						color: rgba($color-black, 0.5);

						&:hover {
							color: $color-monza;
						}

						&.is-active {
							color: $color-monza;
						}

						@include desktop {
							font-size: vw(20);
						}

						@include desktop-xl {
							font-size: 20px;
						}
					}
				}

				@include mobile {
					overflow: auto;
					margin: 0 vm(-10);
					padding: 0 vm(10);
					white-space: nowrap;

					&::-webkit-scrollbar {
						display: none;
						width: 0;
						height: 0;
					}
				}
			}
		}

		&__tab {
			display: none;

			&.is-active {
				display: block;
				animation: tabShow $duration-normal;
			}
		}

		&__row {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vm(-10);

			@include desktop {
				margin: 0 vw(-10);
			}

			@include desktop-xl {
				margin: 0 -10px;
			}
		}

		&__col {
			padding: 0 vm(10);
			width: 100%;

			& + & {
				@include mobile {
					margin-top: vm(24);
				}
			}

			@include desktop {
				padding: 0 vw(10);
				width: 25%;
			}

			@include desktop-xl {
				padding: 0 10px;
			}
		}

		&__item {
			&__image {
				overflow: hidden;
				margin-bottom: vm(16);
				border-radius: vm(8);

				@include desktop {
					margin-bottom: vw(32);
					border-radius: vw(16);
				}

				@include desktop-xl {
					margin-bottom: 32px;
					border-radius: 16px;
				}
			}

			&__name {
				margin-top: 0;
				margin-bottom: vm(16);

				span {
					display: block;
				}

				@include desktop {
					margin-bottom: vw(16);
				}

				@include desktop-xl {
					margin-bottom: 16px;
				}
			}
		}

		@include desktop {
			margin-bottom: vw(155);
		}

		@include desktop-xl {
			margin-bottom: 155px;
		}
	}

	&__history {
		margin-bottom: vm(48);

		&__title {
			@include desktop {
				margin-bottom: vw(48);
			}

			@include desktop-xl {
				margin-bottom: 48px;
			}
		}

		&__container {
			display: flex;
			// align-items: flex-start;
			padding: 0 153px 0 196px;

			@include desktop {
				padding: 0 vw(153) 0 vw(196);
			}

			@include desktop-xl {
				padding: 0 153px 0 196px;
			}
		}

		&__years {
			display: flex;
			flex-shrink: 0;
			align-items: flex-start;
			justify-content: center;
			margin-right: 176px;
			width: 538px;
			font-weight: 700;
			font-size: 168px;
			line-height: 0.86;
			color: #e30613;

			&__container {
				position: relative;
				z-index: 5;
				display: flex;
				align-items: center;
				padding: 300px 0;
				height: calc(100vh - #{vw(152)});

				@include desktop {
					padding: vw(300) 0;
				}

				@include desktop-xl {
					padding: 300px 0;
					height: calc(100vh - 152px);
				}

				@media (min-height: 1200px) {
					.is-device-ipad & {
						margin-top: vw(-850);
						margin-bottom: vw(-850);
					}
				}
			}

			&__circle {
				position: absolute;
				left: 50%;
				top: 50%;
				z-index: 5;
				width: 538px;
				height: 538px;
				transform: translate3d(-50%, -50%, 0) rotate(90deg);

				@include desktop {
					width: vw(538);
					height: vw(538);
				}

				@include desktop-xl {
					width: 538px;
					height: 538px;
				}
			}

			&__progress {
				stroke-dasharray: 1687;
				stroke-dashoffset: 1687;
			}

			&__left {
				position: relative;
				width: 200px;
				height: 145px;
				text-align: right;

				&__list {
					position: absolute;

					&__item {
						color: #f8f8f8;
						transition: $duration-normal;

						&.is-active {
							color: #e30613;
						}
					}
				}

				@include desktop {
					width: vw(200);
					height: vw(145);
				}

				@include desktop-xl {
					width: 200px;
					height: 145px;
				}
			}

			&__right {
				position: relative;
				width: 200px;
				height: 145px;

				&__list {
					position: absolute;

					&__item {
						color: #f8f8f8;
						transition: $duration-normal;

						&.is-active {
							color: #e30613;
						}
					}
				}

				@include desktop {
					width: vw(200);
					height: vw(145);
				}

				@include desktop-xl {
					width: 200px;
					height: 145px;
				}
			}

			@include desktop {
				margin-right: vw(176);
				width: vw(538);
				font-size: vw(168);
			}

			@include desktop-xl {
				margin-right: 176px;
				width: 538px;
				font-size: 168px;
			}
		}

		&__list {
			padding: 300px 0 230px;
			width: 100%;

			&__item {
				padding: 48px 0;
				color: #ccc;
				transition: $duration-normal;

				&__text {
					font-size: 20px;
					line-height: 1.6;

					@include desktop {
						font-size: vw(20);
					}

					@include desktop-xl {
						font-size: 20px;
					}
				}

				&.is-active {
					color: $color-black;
				}

				& + & {
					border-top: 1px solid #e0e0e0;
				}

				@include desktop {
					padding: vw(48) 0;
				}

				@include desktop-xl {
					padding: 48px 0;
				}
			}

			@include desktop {
				padding: vw(300) 0 vw(230);
			}

			@include desktop-xl {
				padding: 300px 0 230px;
			}
		}

		&__slider {
			position: relative;
			z-index: 5;

			.swiper-container {
				overflow: visible;
			}

			.swiper-slide {
				width: vm(200);
			}

			&__circle {
				position: absolute;
				left: 50%;
				top: 50%;
				z-index: 5;
				width: vm(180);
				height: vm(180);
				transform: translate3d(-50%, -50%, 0) rotate(90deg);
				pointer-events: none;
			}

			&__progress {
				stroke-dasharray: 1687;
				stroke-dashoffset: 1687;
				transition: $duration-normal;
			}

			&__year {
				padding: vm(80) 0;
				font-weight: 700;
				font-size: vm(70);
				line-height: 0.86;
				text-align: center;
				color: #f8f8f8;
				transition: $duration-normal;

				.swiper-slide-active & {
					color: #e30613;
				}
			}
		}

		&__list-mobile {
			&__tab {
				display: none;

				&.is-active {
					display: block;
					animation: historyTab 0.6s;
				}
			}

			&__item {
				font-size: vm(16);
				line-height: 1.6;

				& + & {
					margin-top: vm(24);
					border-top: 1px solid #e0e0e0;
					padding-top: vm(24);
				}
			}
		}

		@include desktop {
			margin-bottom: vw(100);
		}

		@include desktop-xl {
			margin-bottom: 100px;
		}
	}

	&__show {
		margin-bottom: vm(48);
		padding: vm(30) 0;
		color: $color-white;
		background: $color-black;

		&__container {
			display: flex;
			align-items: center;

			@include mobile {
				flex-direction: column-reverse;
			}
		}

		&__slider {
			position: relative;
			margin-top: vm(24);
			width: 100%;

			@include desktop {
				flex-shrink: 0;
				margin-top: 0;
				margin-right: vw(162);
				width: vw(812);
			}

			@include desktop-xl {
				margin-right: 162px;
				width: 812px;
			}
		}

		&__image {
			overflow: hidden;
			border-radius: vm(16);

			@include desktop {
				border-radius: vw(16);
			}

			@include desktop-xl {
				border-radius: 16px;
			}
		}

		&__prev,
		&__next {
			position: absolute;
			top: 50%;
			z-index: 5;
			transform: translateY(-50%);

			&.swiper-button-disabled {
				opacity: 0;
				visibility: hidden;
				pointer-events: none;
			}
		}

		&__prev {
			@include desktop {
				left: vw(24);
			}

			@include desktop-xl {
				left: 24px;
			}
		}

		&__next {
			@include desktop {
				right: vw(24);
			}

			@include desktop-xl {
				right: 24px;
			}
		}

		&__content {
			width: 100%;
		}

		&__title {
			@include desktop {
				margin-bottom: vw(32);
			}

			@include desktop-xl {
				margin-bottom: 32px;
			}
		}

		&__text {
			margin-bottom: vm(24);

			@include desktop {
				margin-bottom: vw(46);
			}

			@include desktop-xl {
				margin-bottom: 46px;
			}
		}

		&__logo {
			display: flex;

			&__igro-mir {
				img {
					width: vm(165);

					@include desktop {
						width: vw(165);
					}

					@include desktop-xl {
						width: 165px;
					}
				}
			}

			&__buka {
				position: relative;
				margin-top: vm(4);
				margin-left: vm(50);

				&::before,
				&::after {
					content: "";
					position: absolute;
					left: vm(-42);
					top: 50%;
					margin-top: vm(-8);
					width: vm(35);
					height: 2px;
					background: $color-white;
					transform: translateY(-50%) rotate(45deg);

					@include desktop {
						left: vw(-60);
						margin-top: vw(-8);
						width: vw(35);
						height: 2px;
					}

					@include desktop-xl {
						left: -60px;
						margin-top: -8px;
						width: 35px;
					}
				}

				&::after {
					transform: translateY(-50%) rotate(135deg);
				}

				img {
					width: vm(84);

					@include desktop {
						width: vw(84);
					}

					@include desktop-xl {
						width: 84px;
					}
				}

				@include desktop {
					margin-top: vw(4);
					margin-left: vw(81);
				}

				@include desktop-xl {
					margin-top: 4px;
					margin-left: 81px;
				}
			}
		}

		@include desktop {
			margin-bottom: vw(196);
			padding: vw(104) vw(139);
		}

		@include desktop-xl {
			margin-bottom: 196px;
			padding: 104px 139px;
		}
	}

	&__life {
		margin-bottom: vm(48);

		&__content {
			position: relative;
			z-index: 10;
			display: flex;
			flex-direction: column;
		}

		&__title {
			@include desktop {
				margin-bottom: vw(64);
			}

			@include desktop-xl {
				margin-bottom: 64px;
			}
		}

		&__subtitle {
			margin-top: 0;

			@include desktop {
				max-width: vw(435);
			}

			@include desktop-xl {
				max-width: 435px;
			}
		}

		&__text {
			@include desktop {
				max-width: vw(435);
			}

			@include desktop-xl {
				max-width: 435px;
			}
		}

		&__nav {
			margin-top: auto;

			@include desktop {
				margin-bottom: vw(52);
			}

			@include desktop-xl {
				margin-bottom: 52px;
			}
		}

		&__slider {
			position: relative;
			width: 100%;

			.swiper-slide-active {
				.page-about__life__image {
					@include desktop {
						transform: scale(1);
					}
				}
			}

			@include desktop {
				flex-shrink: 0;
				margin-left: vw(99);
				width: vw(738);

				&::before {
					content: "";
					position: absolute;
					right: 100%;
					top: 0;
					z-index: 5;
					width: 100vh;
					height: 100%;
					background: $color-white;
				}

				.swiper-container {
					overflow: visible;
				}

				.swiper-wrapper {
					align-items: center;
				}
			}

			@include desktop-xl {
				margin-left: 99px;
				width: 738px;
			}
		}

		&__image {
			transition: $duration-normal;

			@include desktop {
				width: vw(738);
				transform: scale(0.85);
			}

			@include desktop-xl {
				width: 738px;
			}
		}

		&__scrollbar {
			margin-top: vm(24);
			width: 100%;
			height: 1px;
			background: #ccc;

			.swiper-scrollbar-drag {
				height: 1px;
				background: $color-monza;
			}

			@include desktop {
				margin-top: vw(51);
			}

			@include desktop-xl {
				margin-top: 51px;
			}
		}

		@include desktop {
			display: flex;
			margin-bottom: vw(135);
		}

		@include desktop-xl {
			margin-bottom: 135px;
		}
	}

	&__pairs {
		margin-bottom: vm(48);

		&__row {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vm(-10);

			@include desktop {
				margin: 0 vw(-10);
			}

			@include desktop-xl {
				margin: 0 -10px;
			}
		}

		&__col {
			padding: 0 vm(10);
			width: 100%;

			& + & {
				@include mobile {
					margin-top: vm(16);
				}
			}

			@include desktop {
				padding: 0 vw(10);
				width: 50%;
			}

			@include desktop-xl {
				padding: 0 10px;
			}
		}

		&__item {
			position: relative;
			z-index: 5;
			border-radius: vm(8);
			padding: vm(75) vm(32) vm(48);
			height: 100%;
			color: $color-white;
			background: $color-black;

			&__image {
				position: absolute;
				right: 0;
				top: 0;
				z-index: -1;
				pointer-events: none;

				img {
					width: vm(120);

					@include desktop {
						width: vw(375);
					}

					@include desktop-xl {
						width: 375px;
					}
				}
			}

			&__content {
				@include desktop {
					max-width: vw(364);
				}

				@include desktop-xl {
					max-width: 364px;
				}
			}

			&__title {
				margin-top: 0;

				@include desktop {
					margin: 0 0 32px;
					margin-bottom: vw(32);
					max-width: vw(260);
				}

				@include desktop-xl {
					margin-bottom: 32px;
					max-width: 260px;
				}
			}

			&__text {
				margin-bottom: vm(24);

				@include desktop {
					margin-bottom: vw(48);
				}

				@include desktop-xl {
					margin-bottom: 48px;
				}
			}

			&__button {
				.button {
					width: 100%;

					@include desktop {
						width: auto;
						min-width: vw(180);
					}

					@include desktop-xl {
						min-width: 180px;
					}
				}
			}

			@include desktop {
				border-radius: vw(16);
				padding: vw(72);
			}

			@include desktop-xl {
				border-radius: 16px;
				padding: 72px;
			}
		}

		@include desktop {
			margin-bottom: vw(104);
		}

		@include desktop-xl {
			margin-bottom: 104px;
		}
	}

	.news {
		margin-bottom: 0;
	}
}

@keyframes historyTab {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
