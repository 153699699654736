.cooperation {
	margin-bottom: vm(36);

	&__container {
		@include desktop {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			padding-left: vw(152);
		}

		@include desktop-xl {
			padding-left: 152px;
		}
	}

	&__content {
		width: 100%;
	}

	&__title {
		margin-bottom: vm(20);

		@include mobile {
			font-size: vm(22);
			line-height: vm(26);
		}

		@include desktop {
			margin-bottom: vw(30);
		}

		@include desktop-xl {
			margin-bottom: 30px;
		}
	}

	&__text {
		@include desktop {
			font-size: vw(18);
			line-height: vw(30);
		}

		@include desktop-xl {
			font-size: 18px;
			line-height: 30px;
		}
	}

	&__button {
		margin-top: vm(30);

		@include desktop {
			margin-top: vw(60);
		}

		@include desktop-xl {
			margin-top: 60px;
		}
	}

	&__list {
		flex-shrink: 0;
		margin-bottom: vm(28);

		&__row {
			display: flex;
			flex-wrap: wrap;
			margin: 0 vm(-4) vm(-8);

			@include desktop {
				margin: 0 vw(-10) vw(-20);
			}

			@include desktop-xl {
				margin: 0 -10px -20px;
			}
		}

		&__col {
			margin-bottom: vm(8);
			padding: 0 vm(4);
			width: 50%;

			@include desktop {
				margin-bottom: vw(20);
				padding: 0 vw(10);
			}

			@include desktop-xl {
				margin-bottom: 20px;
				padding: 0 10px;
			}
		}

		@include desktop {
			margin: 0 0 0 vw(172);
			width: vw(890);
		}

		@include desktop-xl {
			margin-left: 172px;
			width: 890px;
		}
	}

	&__item {
		position: relative;
		display: flex;
		align-items: flex-end;
		overflow: hidden;
		border-radius: vm(8);
		padding: vm(20);
		height: vm(170);
		background: #f6f6f6;
		transition: none;

		&__image {
			position: absolute;
			top: 0;

			img {
				width: 100%;
				max-width: none;
			}
		}

		&__title {
			width: 100%;
			font-weight: 700;

			@include desktop {
				max-width: vw(250);
				font-size: vw(32);
				line-height: vw(36);
			}

			@include desktop-xl {
				max-width: 250px;
				font-size: 32px;
				line-height: 36px;
			}
		}

		@include desktop {
			border-radius: vw(16);
			padding: vw(60);
			width: vw(435);
			height: vw(435);

			&:hover {
				z-index: 10;
			}
		}

		@include desktop-xl {
			border-radius: 16px;
			padding: 60px;
			width: 435px;
			height: 435px;
		}
	}

	&__item--support {
		color: $color-white;
		background: #e30613;

		.cooperation__item__image {
			left: vm(5);

			img {
				width: vm(126);

				@include desktop {
					width: vw(391);
				}

				@include desktop-xl {
					width: 391px;
				}
			}

			@include desktop {
				left: vw(14);
			}

			@include desktop-xl {
				left: 14px;
			}
		}
	}

	&__item--banner {
		.cooperation__item__image {
			left: vm(27);

			img {
				width: vm(98);

				@include desktop {
					width: vw(279);
				}

				@include desktop-xl {
					width: 279px;
				}
			}

			@include desktop {
				left: vw(80);
			}

			@include desktop-xl {
				left: 80px;
			}
		}
	}

	&__item--discount {
		.cooperation__item__image {
			left: vm(18);

			img {
				width: vm(108);

				@include desktop {
					width: vw(316);
				}

				@include desktop-xl {
					width: 316px;
				}
			}

			@include desktop {
				left: vw(60);
			}

			@include desktop-xl {
				left: 60px;
			}
		}
	}

	&__item--programs {
		color: $color-white;
		background: #191919;

		.cooperation__item__image {
			left: vm(19);

			img {
				width: vm(108);

				@include desktop {
					width: vw(304);
				}

				@include desktop-xl {
					width: 304px;
				}
			}

			@include desktop {
				left: vw(65);
			}

			@include desktop-xl {
				left: 65px;
			}
		}
	}

	@include desktop {
		margin-bottom: vw(160);
	}

	@include desktop-xl {
		margin-bottom: 160px;
	}
}
