.gallery {
	margin-bottom: vm(48);

	&__image {
		position: relative;
		margin: 0 vm(-10);

		&__item {
			padding-bottom: 95.313%;
			background: 50% 50% no-repeat;
			background-size: cover;

			@include desktop {
				border-radius: vw(16);
				padding-bottom: 50%;
			}

			@include desktop-xl {
				border-radius: 16px;
			}
		}

		@include desktop {
			margin: 0 0 vw(20);
		}

		@include desktop-xl {
			margin: 0 0 20px;
		}
	}

	&__prev,
	&__next {
		position: absolute;
		top: 50%;
		z-index: 5;
		transform: translateY(-50%);
	}

	&__prev {
		left: vm(32);

		@include desktop {
			left: vw(32);
		}

		@include desktop-xl {
			left: 32px;
		}
	}

	&__next {
		right: vm(32);

		@include desktop {
			right: vw(32);
		}

		@include desktop-xl {
			right: 32px;
		}
	}

	&__thumbs {
		&__item {
			border-radius: vm(16);
			padding-bottom: 67%;
			background: 50% 50% no-repeat;
			background-size: cover;
			transition: $duration-normal;
			cursor: pointer;

			.swiper-slide-thumb-active & {
				opacity: 0.5;
			}

			@include desktop {
				border-radius: vw(16);
			}

			@include desktop-xl {
				border-radius: 16px;
			}
		}
	}

	@include desktop {
		margin-bottom: vw(104);
	}

	@include desktop-xl {
		margin-bottom: 104px;
	}
}
