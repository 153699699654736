.tabs {
	margin-bottom: vm(48);

	&__list {
		margin-bottom: vm(24);
		font-weight: 700;
		font-size: vm(14);
		line-height: vm(20);

		ul {
			display: flex;

			li {
				flex-shrink: 0;

				+ li {
					@include desktop {
						margin-left: vw(24);
					}

					@include desktop-xl {
						margin-left: 24px;
					}
				}

				a {
					color: #7c7c7c;

					&.is-active {
						color: $color-monza;
					}

					@include desktop {
						&:hover {
							color: $color-monza;
						}
					}
				}

				@include mobile {
					padding: vm(15) vm(8);
					background: #f8f8f8;

					&:first-child {
						border-radius: vm(8) 0 0 vm(8);
						padding-left: vm(16);
					}

					&:last-child {
						border-radius: 0 vm(8) vm(8) 0;
						padding-right: vm(16);
					}
				}
			}

			@include mobile {
				overflow: auto;
				margin: 0 vm(-10);
				padding: 0 vm(10);
			}
		}

		@include desktop {
			margin-bottom: vw(40);
			border-radius: vw(8);
			padding: vw(25) vw(32);
			font-size: vw(20);
			line-height: vw(32);
			background: #f8f8f8;
		}

		@include desktop-xl {
			margin-bottom: 40px;
			border-radius: 8px;
			padding: 25px 32px;
			font-size: 20px;
			line-height: 32px;
		}
	}

	&__item {
		display: none;

		&.is-active {
			display: block;
			animation: tabShow $duration-normal;
		}
	}

	@include desktop {
		margin-bottom: vw(104);
	}

	@include desktop-xl {
		margin-bottom: 104px;
	}
}

@keyframes tabShow {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
