.page-news-detail {
	&__text {
		margin-bottom: vm(48);

		@include desktop {
			margin-bottom: vw(56);
			max-width: vw(890);
		}

		@include desktop-xl {
			margin-bottom: 56px;
			max-width: 890px;
		}
	}

	&__title {
		@include desktop {
			margin: vw(80) 0 vw(48);
		}

		@include desktop-xl {
			margin: 80px 0 48px;
		}
	}

	&__row {
		margin-bottom: vm(48);

		@include desktop {
			display: flex;
			align-items: flex-start;
			margin-bottom: vw(54);
		}

		@include desktop-xl {
			margin-bottom: 54px;
		}
	}

	&__feature {
		margin: vm(24) vm(-10);
		padding: vm(32) vm(10);
		background: #f8f8f8;

		@include desktop {
			flex-shrink: 0;
			margin: 0 0 0 vw(171);
			border-radius: vw(16);
			padding: vw(40);
			width: vw(435);
		}

		@include desktop-xl {
			margin: 0 0 0 171px;
			border-radius: 16px;
			padding: 40px;
			width: 435px;
		}
	}

	&__slider {
		position: relative;
		margin: 0 vm(-10) vm(24);

		&__prev,
		&__next {
			position: absolute;
			top: 50%;
			z-index: 5;
			transform: translateY(-50%);

			&.swiper-button-disabled {
				opacity: 0;
				visibility: hidden;
				pointer-events: none;
			}
		}

		&__prev {
			@include desktop {
				left: vw(32);
			}

			@include desktop-xl {
				left: 32px;
			}
		}

		&__next {
			@include desktop {
				right: vw(32);
			}

			@include desktop-xl {
				right: 32px;
			}
		}

		@include desktop {
			margin: 0 0 vw(80);
			border-radius: vw(16);
		}

		@include desktop-xl {
			margin: 0 0 80px;
			border-radius: 16px;
		}
	}
}
