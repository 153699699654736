.page-styles {
	&__title {
		@include desktop {
			margin-bottom: vw(32);
		}

		@include desktop-xl {
			margin-bottom: 32px;
		}
	}

	&__wrapper {
		display: flex;
		flex-direction: column-reverse;
		margin-bottom: vm(48);

		&__content {
			max-width: 100%;

			.share {
				@include mobile {
					margin-top: vm(32);
				}
			}

			@include desktop {
				width: vw(890);
			}

			@include desktop-xl {
				width: 890px;
			}
		}

		&__side {
			&__content {
				@include mobile {
					margin-bottom: vm(48);
				}
			}

			@include desktop {
				flex-shrink: 0;
				width: vw(435);
			}

			@include desktop-xl {
				width: 435px;
			}
		}

		@include desktop {
			flex-direction: inherit;
			justify-content: space-between;
			margin-bottom: vw(104);
		}

		@include desktop-xl {
			margin-bottom: 104px;
		}
	}

	&__content {
		@include desktop {
			max-width: vw(1192);
		}

		@include desktop-xl {
			max-width: 1192px;
		}
	}

	&__lists {
		margin: 0 0 vm(48);

		&__col {
			margin-bottom: vm(24);

			@include desktop {
				margin-bottom: 0;
				margin-left: vw(100);
				max-width: vw(355);
			}

			@include desktop-xl {
				margin-left: 100px;
				max-width: 355px;
			}
		}

		@include desktop {
			display: flex;
			margin: 0 0 vw(104) vw(-100);
		}

		@include desktop-xl {
			margin: 0 0 104px -100px;
		}
	}

	&__news {
		.news__title {
			@include mobile {
				font-size: vm(32);
				line-height: vm(32);
			}
		}
	}

	&__buttons {
		&__col {
			& + & {
				@include mobile {
					margin-top: vm(32);
				}
			}

			@include desktop {
				margin-left: vw(200);
			}

			@include desktop-xl {
				margin-left: 200px;
			}
		}

		&__item {
			.prev {
				@include mobile {
					width: vm(48);
					height: vm(48);
				}
			}

			.next {
				margin-left: vm(8);

				@include mobile {
					width: vm(48);
					height: vm(48);
				}

				@include desktop {
					margin-left: vw(8);
				}

				@include desktop-xl {
					margin-left: 8px;
				}
			}

			& + & {
				margin-top: vm(24);

				@include desktop {
					margin-top: vw(24);
				}

				@include desktop-xl {
					margin-top: 24px;
				}
			}

			@include mobile {
				.button + .button {
					margin-top: vm(16);
				}
			}
		}

		&__dark {
			margin: 0 vm(-10);
			padding: vm(32) vm(10);
			background: $color-black;

			@include desktop {
				margin: 0;
				border-radius: vw(16);
				padding: vw(32);
			}

			@include desktop-xl {
				border-radius: 16px;
				padding: 32px;
			}
		}

		@include desktop {
			display: flex;
			margin: 0 0 vw(104) vw(-200);
		}

		@include desktop-xl {
			margin: 0 0 104px -200px;
		}
	}

	&__fields {
		&__col {
			@include desktop {
				margin-left: vw(170);
				min-width: vw(435);
			}

			@include desktop-xl {
				margin-left: 170px;
				min-width: 435px;
			}
		}

		@include desktop {
			display: flex;
			margin-left: vw(-170);
		}

		@include desktop-xl {
			margin-left: -170px;
		}
	}

	&__check {
		&__col {
			@include desktop {
				margin-left: vw(80);
			}

			@include desktop-xl {
				margin-left: 80px;
			}
		}

		@include desktop {
			display: flex;
			margin-left: vw(-80);
		}

		@include desktop-xl {
			margin-left: -80px;
		}
	}
}
