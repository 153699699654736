.checkbox {
	margin-bottom: vm(16);

	label {
		position: relative;
		display: inline-block;
		padding: 0 0 0 vm(40);
		transition: $duration-normal;
		cursor: pointer;

		input {
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: -1;
			width: 1px;
			height: 1px;
			opacity: 0;
			transform: translate(-50%, -50%);

			&:checked ~ i {
				border-color: $color-monza;
				background: $color-monza;

				svg {
					opacity: 1;
					visibility: visible;
				}
			}

			&:disabled {
				~ i {
					border-color: #ccc;
					background: #f8f8f8;

					svg {
						stroke: #ccc;
					}
				}
			}

			&.error ~ i {
				border-color: $color-monza;
			}
		}

		i {
			position: absolute;
			left: 0;
			top: -2px;
			border: solid 1px #ccc;
			border-radius: vm(8);
			width: vm(24);
			height: vm(24);
			background: $color-white;
			transition: $duration-normal;

			svg {
				position: absolute;
				left: 50%;
				top: 50%;
				width: vm(13);
				height: vm(10);
				stroke: $color-white;
				opacity: 0;
				visibility: hidden;
				transform: translate(-50%, -50%);
				transition: $duration-normal;

				@include desktop {
					width: vw(13);
					height: vw(10);
				}

				@include desktop-xl {
					width: 13px;
					height: 10px;
				}
			}

			@include desktop {
				border-radius: vw(8);
				width: vw(24);
				height: vw(24);
			}

			@include desktop-xl {
				border-radius: 8px;
				width: 24px;
				height: 24px;
			}
		}

		a {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 1px;
				background: rgba(0, 0, 0, 0.2);
				transition: $duration-normal;
			}

			&:hover {
				&::after {
					opacity: 0;
				}
			}
		}

		@include desktop {
			padding: 0 0 0 vw(40);

			&:hover {
				i {
					border-color: $color-black;
				}
			}
		}

		@include desktop-xl {
			padding: 0 0 0 40px;
		}
	}

	label.error {
		display: none !important;
	}

	@include desktop {
		margin-bottom: vw(16);
	}

	@include desktop-xl {
		margin-bottom: 16px;
	}
}
