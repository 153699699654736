.partners {
	overflow: hidden;
	margin-bottom: vm(40);
	padding: vm(36) 0;
	background: #f6f6f6;

	.swiper-container {
		overflow: visible;

		@include desktop {
			overflow: hidden;
			margin: 0 vw(-20) vw(-20);
			padding: 0 vw(20) vw(20);
		}

		@include desktop-xl {
			margin: 0 -20px -20px;
			padding: 0 20px 20px;
		}
	}

	.swiper-wrapper {
		@include desktop {
			margin-bottom: vw(-24);
		}

		@include desktop-xl {
			margin-bottom: -24px;
		}
	}

	.swiper-slide {
		width: auto;
	}

	&__title {
		margin: 0 0 vm(27);

		@include mobile {
			font-size: vm(22);
			line-height: vm(26);
		}

		@include desktop {
			margin-bottom: vw(40);
		}

		@include desktop-xl {
			margin-bottom: 40px;
		}
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: vm(8);
		width: vm(100);
		height: vm(100);
		background: $color-white;
		transition: $duration-normal;

		&__image {
			img {
				max-width: vm(80);
				max-height: vm(80);

				@include desktop {
					max-width: vw(282);
					max-height: vw(148);
				}

				@include desktop-xl {
					max-width: 282px;
					max-height: 148px;
				}
			}
		}

		@include desktop {
			border-radius: vw(16);
			width: vw(283);
			height: vw(283);

			&:hover {
				box-shadow: 0 vw(7) vw(10) rgba($color-black, 0.25);
			}
		}

		@include desktop-xl {
			border-radius: 16px;
			width: 283px;
			height: 283px;

			&:hover {
				box-shadow: 0 7px 10px rgba($color-black, 0.25);
			}
		}
	}

	&__item--big {
		width: vm(208);

		@include desktop {
			width: vw(587);
		}

		@include desktop-xl {
			width: 587px;
		}
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 vm(-4) vm(-8);
		width: vm(324);

		@include desktop {
			margin: 0 vw(-10);
			width: vw(910);
		}

		@include desktop-xl {
			margin: 0 -10px;
			width: 910px;
		}
	}

	&__col {
		margin-bottom: vm(8);
		padding: 0 vm(4);

		@include desktop {
			margin-bottom: vw(24);
			padding: 0 vw(10);
		}

		@include desktop-xl {
			margin-bottom: 24px;
			padding: 0 10px;
		}
	}

	&__line {
		display: flex;

		.js-marquee-wrapper {
			display: flex;
		}

		.js-marquee {
			display: flex;
			flex-shrink: 0;
		}

		.partners__col {
			flex-shrink: 0;
		}
	}

	@include desktop {
		margin-bottom: vw(160);
		padding: vw(120) 0;
	}

	@include desktop-xl {
		margin-bottom: 160px;
		padding: 120px 0;
	}
}

.partners--line {
	padding: vm(38) 0 vm(55);

	.swiper-wrapper {
		margin-bottom: 0;
	}

	.partners__item {
		width: auto;
		height: auto;

		&__image {
			img {
				max-width: 100%;
				max-height: none;
			}
		}
	}

	@include desktop {
		padding: vw(120) 0 vw(156);
	}

	@include desktop-xl {
		padding: 120px 0 156px;
	}
}
