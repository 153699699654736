.coming-soon {
	.swiper-container {
		overflow: visible;

		@include desktop {
			overflow: hidden;
			margin: 0 vw(-18);
			padding: 0 vw(18);
		}

		@include desktop-xl {
			margin: 0 -18px;
			padding: 0 18px;
		}
	}

	.swiper-wrapper {
		@include desktop {
			margin-bottom: vw(-62);
		}

		@include desktop-xl {
			margin-bottom: -62px;
		}
	}

	.swiper-slide {
		width: auto;
	}

	&__title {
		margin-bottom: vm(23);

		@include mobile {
			font-size: vm(22);
			line-height: vm(26);
		}

		@include desktop {
			margin-bottom: vw(40);
		}

		@include desktop-xl {
			margin-bottom: 40px;
		}
	}

	&__item {
		display: block;
		width: vm(136);
		box-sizing: border-box;

		&__image {
			position: relative;
			overflow: hidden;
			margin-bottom: vm(12);
			border-radius: vm(8);
			padding-bottom: 78%;
			background: 50% 50% no-repeat;
			background-size: cover;
			transition: $duration-normal;

			&__list {
				position: absolute;
				left: 0;
				top: 0;
				display: flex;
				width: 100%;
				height: 100%;
			}

			&__el {
				flex: 1;

				&__img {
					position: absolute;
					left: 0;
					top: 0;
					border-radius: vm(8);
					width: 100%;
					height: 100%;
					background: 50% 50% no-repeat;
					background-size: cover;
					pointer-events: none;

					@include desktop {
						display: none;
						border-radius: vw(16);
					}

					@include desktop-xl {
						border-radius: 16px;
					}
				}

				&:first-child &__img {
					display: block;
				}

				@include desktop {
					&:hover &__img {
						display: block;
					}
				}
			}

			@include desktop {
				margin-bottom: vw(20);
				border-radius: vw(16);
			}

			@include desktop-xl {
				margin-bottom: 20px;
				border-radius: 16px;
			}
		}

		&__play {
			position: absolute;
			left: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: vm(8);
			width: 100%;
			height: 100%;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));

			&__icon {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				width: vm(36);
				height: vm(36);
				background: #e30613;

				svg {
					width: vm(12);
					height: vm(14);

					@include desktop {
						width: vw(14);
						height: vw(16);
					}

					@include desktop-xl {
						width: 14px;
						height: 16px;
					}
				}

				@include desktop {
					width: vw(48);
					height: vw(48);
				}

				@include desktop-xl {
					width: 48px;
					height: 48px;
				}
			}

			@include desktop {
				border-radius: vw(16);
			}

			@include desktop-xl {
				border-radius: 16px;
			}
		}

		&__preview {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 5;
			width: 100%;
			height: 100%;
			opacity: 0;
			visibility: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&.is-show {
				opacity: 1;
				visibility: visible;
				transition: $duration-normal;
			}
		}

		&__date {
			margin-bottom: vm(8);

			span {
				display: inline-block;
				vertical-align: middle;
				border-radius: vm(16);
				padding: 0 vm(10);
				font-weight: 500;
				font-size: vm(9);
				line-height: vm(16);
				letter-spacing: 0.1em;
				text-transform: uppercase;
				color: $color-white;
				background: #e30613;

				@include desktop {
					border-radius: vw(16);
					padding: 0 vw(10);
					font-size: vw(12);
					line-height: vw(24);
				}

				@include desktop-xl {
					border-radius: 16px;
					padding: 0 10px;
					font-size: 12px;
					line-height: 24px;
				}
			}

			@include desktop {
				margin-bottom: vw(12);
			}

			@include desktop-xl {
				margin-bottom: 12px;
			}
		}

		&__title {
			font-weight: 700;
			font-size: vm(14);
			line-height: vm(17);

			@include desktop {
				font-size: vw(24);
				line-height: vw(30);
			}

			@include desktop-xl {
				font-size: 24px;
				line-height: 30px;
			}
		}

		@include desktop {
			margin-bottom: vw(62);
			width: auto;

			&:hover {
				.coming-soon__item__image {
					box-shadow: 0 vw(7) vw(10) rgba($color-black, 0.25);
				}
			}
		}

		@include desktop-xl {
			margin-bottom: 62px;

			&:hover {
				.coming-soon__item__image {
					box-shadow: 0 7px 10px rgba($color-black, 0.25);
				}
			}
		}
	}
}
