.is-hidden {
	// stylelint-disable-next-line declaration-no-important
	display: none !important;
}

.is-hide {
	// stylelint-disable-next-line declaration-no-important
	opacity: 0;
}

.for-desktop {
	@include devices {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}

.for-tablet {
	@include desktop {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}

	@include mobile {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}

.for-mobile {
	@include desktop {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}

.for-devices {
	@include desktop {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}

.clearfix {
	overflow: auto;

	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

.visually-hidden {
	position: absolute;
	overflow: hidden;
	margin: -1px;
	width: 1px;
	height: 1px;
	clip: rect(0 0 0 0);
}

/* stylelint-disable */
.dont-break-out {
	// Технически это одно и то же, но используйте и то, и другое
	overflow-wrap: break-word;
	word-wrap: break-word;
	// Это самое опасное в WebKit, так как оно ломает вещи везде, где бы они ни были
	word-break: break-all;
	// Вместо этого используйте этот нестандартный:
	word-break: break-word;
	// Добавляет дефис там, где слово ломается, если поддерживается
	hyphens: auto;
}
/* stylelint-enable */

.main {
	overflow: hidden;
}

.container {
	margin: 0 auto;
	padding: 0 vm(10);
	width: 100%;

	@include desktop {
		padding: 0;
		max-width: vw(1800);
	}

	@include desktop-xl {
		max-width: 1800px;
	}
}

.content {
	padding-top: vm(40);
	padding-bottom: vm(36);

	@include desktop {
		padding-top: 0;
		padding-bottom: vw(160);
		min-height: calc(100vh - #{vw(608)});
	}

	@include desktop-xl {
		padding-bottom: 160px;
		min-height: calc(100vh - 608px);
	}
}

.content--default {
	padding: vm(64) 0 vm(36);

	@include desktop {
		padding: vw(208) 0 vw(124);
	}

	@include desktop-xl {
		padding: 208px 0 124px;
	}
}

.content--404 {
	padding: 0;
}

.content--partners {
	padding-bottom: 0;
}

h1,
.h1 {
	margin: 0 0 vm(10);
	font-weight: 700;
	font-size: vm(40);
	line-height: vm(36);

	@include desktop {
		margin: 0 0 vw(8);
		font-size: vw(96);
		line-height: vw(96);
	}

	@include desktop-xl {
		margin: 0 0 8px;
		font-size: 96px;
		line-height: 96px;
	}
}

h2,
.h2 {
	margin: vm(48) 0 vm(24);
	font-weight: 700;
	font-size: vm(32);
	line-height: vm(32);

	a {
		border-bottom: solid 1px #d9d9d9;

		&:hover {
			border-bottom-color: $color-monza;
		}
	}

	&:first-child {
		margin-top: 0;
	}

	@include desktop {
		margin: vw(104) 0 vw(24);
		font-size: vw(64);
		line-height: vw(72);
	}

	@include desktop-xl {
		margin: 104px 0 24px;
		font-size: 64px;
		line-height: 72px;
	}
}

h3,
.h3 {
	margin: vm(48) 0 vm(24);
	font-weight: 700;
	font-size: vm(24);
	line-height: vm(24);

	a {
		border-bottom: solid 1px #d9d9d9;

		&:hover {
			border-bottom-color: $color-monza;
		}
	}

	&:first-child {
		margin-top: 0;
	}

	@include desktop {
		margin: vw(104) 0 vw(24);
		font-size: vw(32);
		line-height: vw(32);
	}

	@include desktop-xl {
		margin: 104px 0 24px;
		font-size: 32px;
		line-height: 32px;
	}
}

h4,
.h4 {
	margin: vm(48) 0 vm(20);
	font-weight: 700;
	font-size: vm(20);
	line-height: vm(24);

	a {
		border-bottom: solid 1px #d9d9d9;

		&:hover {
			border-bottom-color: $color-monza;
		}
	}

	&:first-child {
		margin-top: 0;
	}

	@include desktop {
		margin: vw(104) 0 vw(24);
		font-size: vw(24);
		line-height: vw(30);
	}

	@include desktop-xl {
		margin: 104px 0 24px;
		font-size: 24px;
		line-height: 30px;
	}
}

.page-title {
	margin-bottom: vm(32);

	@include desktop {
		margin-bottom: vw(64);
	}

	@include desktop-xl {
		margin-bottom: 64px;
	}
}

.text-lead {
	margin-bottom: vm(24);
	font-weight: 300;
	font-size: vm(18);
	line-height: vm(24);

	@include desktop {
		margin-bottom: vw(24);
		font-size: vw(20);
		line-height: vw(32);
	}

	@include desktop-xl {
		margin-bottom: 24px;
		font-size: 20px;
		line-height: 32px;
	}
}

.up-arrow {
	position: fixed;
	right: vm(10);
	bottom: vm(10);
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	border: none;
	border-radius: 50%;
	width: vm(48);
	height: vm(48);
	background: $color-monza;
	opacity: 0;
	visibility: hidden;

	svg {
		display: block;
		width: vm(36);
		height: vm(36);
		fill: none;

		@include desktop {
			width: vw(37);
			height: vw(37);
		}

		@include desktop-xl {
			width: 37px;
			height: 37px;
		}
	}

	&.is-show {
		opacity: 1;
		visibility: visible;
	}

	@include desktop {
		right: vw(60);
		bottom: vw(64);
		width: vw(72);
		height: vw(72);

		&:hover {
			background: darken($color: $color-monza, $amount: 5%);
		}
	}

	@include desktop-xl {
		right: 60px;
		bottom: 64px;
		width: 72px;
		height: 72px;
	}
}

.prev,
.next {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	outline: none;
	border: none;
	border-radius: 50%;
	padding: 0;
	width: vm(36);
	height: vm(36);
	background: $color-black;

	svg {
		margin-left: vm(-3);
		width: vm(8);
		height: vm(14);
		fill: none;

		@include desktop {
			margin-left: vw(-3);
			width: vw(10);
			height: vw(18);
		}

		@include desktop-xl {
			margin-left: -3px;
			width: 10px;
			height: 18px;
		}
	}

	&.swiper-button-disabled {
		opacity: 0;
		pointer-events: none;
	}

	@include desktop {
		width: vw(48);
		height: vw(48);

		&:hover {
			background: $color-monza;
		}
	}

	@include desktop-xl {
		width: 48px;
		height: 48px;
	}
}

.next {
	svg {
		margin-left: vm(3);

		@include desktop {
			margin-left: vw(3);
		}

		@include desktop-xl {
			margin-left: 3px;
		}
	}
}

.pager-slider {
	margin-top: vm(20);
	text-align: center;

	.swiper-pagination-bullet {
		display: inline-block;
		vertical-align: middle;
		border-radius: 50%;
		width: vm(5);
		height: vm(5);
		background: #c4c4c4;

		+ .swiper-pagination-bullet {
			margin-left: vm(8);
		}

		&.swiper-pagination-bullet-active {
			width: vm(8);
			height: vm(8);
			background: $color-monza;
		}
	}
}

.styled-list {
	ul,
	ol {
		font-size: vm(14);
		line-height: vm(20);

		@include desktop {
			font-size: vw(14);
			line-height: vw(24);
		}

		@include desktop-xl {
			font-size: 14px;
			line-height: 24px;
		}
	}

	ul {
		li {
			position: relative;
			padding-left: vm(22);

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: vm(8);
				border-radius: 50%;
				width: vm(6);
				height: vm(6);
				background: $color-monza;

				@include desktop {
					top: vw(8);
					width: vw(6);
					height: vw(6);
				}

				@include desktop-xl {
					top: 8px;
					width: 6px;
					height: 6px;
				}
			}

			+ li {
				margin-top: vm(4);

				@include desktop {
					margin-top: vw(4);
				}

				@include desktop-xl {
					margin-top: 4px;
				}
			}

			@include desktop {
				padding-left: vw(22);
			}

			@include desktop-xl {
				padding-left: 22px;
			}
		}

		ul {
			padding: vm(8) 0 vm(4);

			li {
				padding-left: vm(16);

				&::before {
					top: vm(11);
					width: vm(8);
					height: 1px;

					@include desktop {
						top: vw(11);
						width: vw(8);
					}

					@include desktop-xl {
						top: 11px;
						width: 8px;
					}
				}

				@include desktop {
					padding-left: vw(16);
				}

				@include desktop-xl {
					padding-left: 16px;
				}
			}

			@include desktop {
				padding: vw(8) 0 vw(4);
			}

			@include desktop-xl {
				padding: 8px 0 4px;
			}
		}
	}

	ol {
		counter-reset: list;

		li {
			&::before {
				counter-increment: list;
				content: counter(list) ". ";
				font-weight: 700;
				color: $color-monza;
			}

			+ li {
				margin-top: vm(8);

				@include desktop {
					margin-top: vw(8);
				}

				@include desktop-xl {
					margin-top: 8px;
				}
			}
		}

		ol {
			counter-reset: underlist;
			padding: vm(8) 0 0 vm(16);

			li {
				&::before {
					counter-increment: underlist;
					content: counter(list) "." counter(underlist) ". ";
				}
			}

			@include desktop {
				padding: vw(8) 0 0 vw(16);
			}

			@include desktop-xl {
				padding: 8px 0 0 16px;
			}
		}
	}
}

.platform {
	display: flex;

	&__item {
		flex-shrink: 0;

		img {
			height: vm(16);

			@include desktop {
				height: vw(16);
			}

			@include desktop-xl {
				height: 16px;
			}
		}

		& + & {
			margin-left: vm(10);

			@include desktop {
				margin-left: vw(16);
			}

			@include desktop-xl {
				margin-left: 16px;
			}
		}
	}
}

.nowrap {
	white-space: nowrap;
}

.styled-links {
	a {
		border-bottom: solid 1px currentColor;

		@include desktop {
			&:hover {
				color: $color-monza;
			}
		}
	}
}

a.is-pseudo {
	border-bottom: dashed 1px currentColor;

	@include desktop {
		&:hover {
			color: $color-monza;
		}
	}
}

a.is-arrow {
	border: none;

	svg {
		display: inline-block;
		vertical-align: middle;
		margin-top: vm(-2);
		margin-left: vm(4);
		width: vm(22);
		height: vm(22);

		@include desktop {
			margin-top: vw(-2);
			margin-left: vw(4);
			width: vw(24);
			height: vw(24);
		}

		@include desktop-xl {
			margin-top: -2px;
			margin-left: 4px;
			width: 24px;
			height: 24px;
		}
	}

	@include desktop {
		&:hover {
			color: $color-monza;
		}
	}
}

.wrapper {
	display: flex;

	&__content {
		width: 100%;
		min-width: 0;
	}

	&__side {
		@include desktop {
			flex-shrink: 0;
			margin-left: vw(172);
			width: vw(435);
		}

		@include desktop-xl {
			margin-left: 172px;
			width: 435px;
		}
	}

	@include mobile {
		flex-direction: column-reverse;
	}
}

.page-return {
	display: inline-flex;
	align-items: center;

	&__arrow {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: vm(16);
		border: 1px solid #ccc;
		border-radius: 50%;
		width: vm(35);
		height: vm(35);
		transition: $duration-normal;

		svg {
			width: vm(16);
			height: vm(16);
			transform: rotate(180deg);
			transition: $duration-normal;

			@include desktop {
				width: vw(19);
				height: vw(19);
			}

			@include desktop-xl {
				width: 19px;
				height: 19px;
			}
		}

		@include desktop {
			margin-right: vw(16);
			width: vw(40);
			height: vw(40);
		}

		@include desktop-xl {
			margin-right: 16px;
			width: 40px;
			height: 40px;
		}
	}

	&__text {
		transition: $duration-normal;
	}

	&:hover {
		.page-return__arrow {
			border-color: $color-monza;
			background: $color-monza;

			svg {
				color: $color-white;
			}
		}

		.page-return__text {
			color: $color-monza;
		}
	}
}

.fancybox-bg {
	background: rgba($color-black, 0.5);
}

.fancybox-slide--html {
	.fancybox-close-small {
		right: vm(20);
		top: vm(17);
		padding: 0;
		width: vm(24);
		height: vm(24);
		color: $color-black;

		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -1px;
			width: 100%;
			height: 1px;
			background: currentColor;
			transition: $duration-normal;
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}

		svg {
			display: none;
		}

		@include desktop {
			right: vw(40);
			top: vw(40);
			width: vw(24);
			height: vw(24);

			&:hover {
				color: $color-monza;
			}
		}

		@include desktop-xl {
			right: 40px;
			top: 40px;
			width: 24px;
			height: 24px;
		}
	}

	@include mobile {
		padding: 0;
	}
}

.sitemap {
	.styled-list {
		ul,
		ol {
			font-size: 18px;
			line-height: 1.5;
		}
	}
}
