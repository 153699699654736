.carousel {
	position: relative;

	&__nav {
		position: absolute;
		right: 0;
		top: vm(-56);
		z-index: 5;
		display: flex;

		@include desktop {
			top: vw(-98);
		}

		@include desktop-xl {
			top: -98px;
		}
	}

	&__prev,
	&__next {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: vm(8);
		outline: none;
		border: none;
		border-radius: 50%;
		padding: 0;
		width: vm(36);
		height: vm(36);
		background: $color-black;

		svg {
			width: vm(8);
			height: vm(14);
			fill: none;

			@include desktop {
				width: vw(10);
				height: vw(18);
			}

			@include desktop-xl {
				width: 10px;
				height: 18px;
			}
		}

		&.swiper-button-disabled {
			opacity: 0;
			pointer-events: none;
		}

		@include desktop {
			margin-left: vw(8);
			width: vw(48);
			height: vw(48);

			&:hover {
				background: $color-monza;
			}
		}

		@include desktop-xl {
			margin-left: 8px;
			width: 48px;
			height: 48px;
		}
	}

	&__prev {
		padding-right: vm(2);

		@include desktop {
			padding-right: vw(2);
		}

		@include desktop-xl {
			padding-right: 2px;
		}
	}

	&__next {
		padding-left: vm(2);

		@include desktop {
			padding-left: vw(2);
		}

		@include desktop-xl {
			padding-left: 2px;
		}
	}
}
