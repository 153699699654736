.about-game {
	position: relative;
	z-index: 5;
	margin: 0 vm(-10) vm(48);
	padding: vm(56) vm(10) vm(37);
	color: $color-white;
	background: $color-black;

	&__image {
		position: absolute;
		right: vm(-27);
		top: vm(-5);
		z-index: -1;
		pointer-events: none;

		img {
			width: vm(133);

			@include desktop {
				width: vw(171);
			}

			@include desktop-xl {
				width: 171px;
			}
		}

		@include desktop {
			right: vw(-24);
			top: vw(-24);
		}

		@include desktop-xl {
			right: -24px;
			top: -24px;
		}
	}

	&__title {
		margin-top: 0;

		@include mobile {
			margin-bottom: vm(16);
			font-size: vm(32);
			line-height: vm(32);
		}
	}

	&__date {
		margin-bottom: vm(24);

		@include desktop {
			margin-bottom: vw(30);
		}

		@include desktop-xl {
			margin-bottom: 30px;
		}
	}

	&__list {
		margin: 0 vm(-10);

		table {
			border-collapse: collapse;
			width: 100%;

			td {
				padding: vm(4) vm(10);

				@include desktop {
					padding: vw(2) vw(10);
				}

				@include desktop-xl {
					padding: 2px 10px;
				}
			}
		}

		&__key {
			color: #808080;
		}

		&__value {
			width: 100%;
		}

		@include desktop {
			margin: 0 vw(-10);
		}

		@include desktop-xl {
			margin: 0 -10px;
		}
	}

	&__limit {
		display: flex;
		align-items: center;
		margin-top: vm(28);

		&__age {
			display: flex;
			flex-shrink: 0;
			align-items: center;
			justify-content: center;
			margin-right: vm(12);
			border: solid 2px $color-white;
			border-radius: 50%;
			width: vm(41);
			height: vm(41);
			font-weight: 700;

			@include desktop {
				margin-right: vw(12);
				width: vw(41);
				height: vw(41);
			}

			@include desktop-xl {
				margin-right: 12px;
				width: 41px;
				height: 41px;
			}
		}

		&__text {
			width: 100%;
			font-weight: 700;
		}

		@include desktop {
			margin-top: vw(30);
		}

		@include desktop-xl {
			margin-top: 30px;
		}
	}

	&__button {
		margin-top: vm(32);

		.button {
			width: 100%;
			font-size: vm(14);
			letter-spacing: normal;

			@include desktop {
				font-size: vw(14);
			}

			@include desktop-xl {
				font-size: 14px;
			}
		}

		@include desktop {
			margin-top: vw(32);
		}

		@include desktop-xl {
			margin-top: 32px;
		}
	}

	@include desktop {
		margin: 0;
		border-radius: vw(16);
		padding: vw(56) vw(52);
	}

	@include desktop-xl {
		border-radius: 16px;
		padding: 56px 52px;
	}
}
