.form {
	&__submit {
		margin-top: vm(40);
		text-align: center;

		@include desktop {
			margin-top: vw(40);
		}

		@include desktop-xl {
			margin-top: 40px;
		}
	}
}
