.page-coming-soon {
	&__filter {
		margin-bottom: vm(24);

		&__item {
			display: flex;

			&__label {
				margin-right: vm(8);

				@include desktop {
					margin-right: vw(8);
				}

				@include desktop-xl {
					margin-right: 8px;
				}
			}

			&__select {
				.input {
					margin: 0;

					&__field {
						select {
							border: none;
							padding: 0 vm(18) 0 0;
							height: vm(20);
							font-weight: 700;
							background: url("../images/triangle.svg") #fff 100% 55% no-repeat;

							@include desktop {
								padding: 0 vw(18) 0 0;
								height: vw(24);
							}

							@include desktop-xl {
								padding: 0 18px 0 0;
								height: 24px;
							}
						}

						.select2-container--default {
							.select2-selection--single {
								height: vm(20);

								@include desktop {
									height: vw(24);
								}

								@include desktop-xl {
									height: 24px;
								}
							}

							.select2-selection__rendered {
								border: none;
								padding: 0 vm(16) 0 0;
								font-weight: 700;
								line-height: vm(20);

								@include desktop {
									padding: 0 vw(16) 0 0;
									line-height: vw(24);
								}

								@include desktop-xl {
									padding: 0 16px 0 0;
									line-height: 24px;
								}
							}

							.select2-selection__placeholder {
								color: inherit;
							}

							.select2-selection__arrow {
								right: 0;
							}

							.select2-dropdown {
								width: auto !important;
							}

							.select2-results__options {
								padding-right: vm(20);

								@include desktop {
									padding-right: vw(20);
								}

								@include desktop-xl {
									padding-right: 20px;
								}
							}
						}
					}
				}
			}

			& + & {
				margin-top: vm(16);

				@include desktop {
					margin-top: 0;
					margin-left: vw(32);
				}

				@include desktop-xl {
					margin-left: 32px;
				}
			}
		}

		@include desktop {
			display: flex;
			margin-bottom: vw(63);
		}

		@include desktop-xl {
			margin-bottom: 63px;
		}
	}

	&__item {
		position: relative;
		margin: 0 vm(-20) vm(20);
		padding: vm(200) vm(20) vm(32);
		color: $color-white;

		&__image {
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			background: $color-black 0 0 no-repeat;
			background-size: vm(500);

			@include desktop {
				background: 50% 50% no-repeat;
				background-size: cover;
			}
		}

		&__content {
			@include desktop {
				margin-left: vw(1136);
			}

			@include desktop-xl {
				margin-left: 1136px;
			}
		}

		&__title {
			margin-bottom: vm(8);

			@include desktop {
				margin-bottom: vw(16);
			}

			@include desktop-xl {
				margin-bottom: 16px;
			}
		}

		&__info {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: vm(22);

			@include desktop {
				margin-bottom: vw(22);
			}

			@include desktop-xl {
				margin-bottom: 22px;
			}
		}

		&__date {
			margin-right: vm(8);

			span {
				display: inline-block;
				vertical-align: middle;
				border: 1px solid $color-monza;
				border-radius: vm(16);
				padding: 0 vm(10);
				font-weight: 500;
				font-size: vm(10);
				line-height: vm(22);
				letter-spacing: 0.1em;
				text-transform: uppercase;
				color: $color-white;
				background: $color-monza;

				@include desktop {
					border-radius: vw(16);
					padding: 0 vw(10);
					font-size: vw(12);
					line-height: vw(22);
				}

				@include desktop-xl {
					border-radius: 16px;
					padding: 0 10px;
					font-size: 12px;
					line-height: 22px;
				}
			}

			@include desktop {
				margin-right: vw(8);
			}

			@include desktop-xl {
				margin-right: 8px;
			}
		}

		&__tag {
			display: inline-block;
			vertical-align: middle;
			margin-right: vm(8);
			border: solid 1px $color-white;
			border-radius: vm(16);
			padding: 0 vm(9);
			font-weight: 500;
			font-size: vm(10);
			line-height: vm(22);
			letter-spacing: 0.1em;
			text-transform: uppercase;

			@include desktop {
				margin-right: vw(8);
				border-radius: vw(16);
				padding: 0 vw(9);
				font-size: vw(12);
				line-height: vw(22);
			}

			@include desktop-xl {
				margin-right: 8px;
				border-radius: 16px;
				padding: 0 9px;
				font-size: 12px;
				line-height: 22px;
			}
		}

		&__list {
			margin: vm(18) vm(-8) vm(10);
			min-width: 0;

			table {
				border-collapse: collapse;
				width: 100%;

				td {
					padding: vm(6) vm(8);

					@include desktop {
						padding: vw(2) vw(18);
					}

					@include desktop-xl {
						padding: 2px 18px;
					}
				}
			}

			&__key {
				color: #808080;
			}

			&__value {
				width: 100%;
			}

			@include mobile {
				font-size: vm(13);
				line-height: vm(16);
			}

			@include desktop {
				margin: vw(22) vw(-18);
			}

			@include desktop-xl {
				margin: 22px -18px;
			}
		}

		&__limit {
			display: flex;
			align-items: center;
			margin-top: vm(22);

			&__age {
				display: flex;
				flex-shrink: 0;
				align-items: center;
				justify-content: center;
				margin-right: vm(12);
				border: solid 2px $color-white;
				border-radius: 50%;
				width: vm(38);
				height: vm(38);
				font-weight: 700;
				text-align: center;

				@include desktop {
					margin-right: vw(12);
					padding-top: 1px;
					width: vw(41);
					height: vw(41);
				}

				@include desktop-xl {
					margin-right: 12px;
					width: 41px;
					height: 41px;
				}
			}

			&__text {
				width: 100%;
				font-weight: 700;
			}

			@include desktop {
				margin-top: vw(45);
			}

			@include desktop-xl {
				margin-top: 45px;
			}
		}

		@include desktop {
			display: flex;
			margin: 0 0 vw(22);
			padding: vw(165) vw(80) vw(124);
			min-height: vw(650);
		}

		@include desktop-xl {
			margin: 0 0 22px;
			padding: 165px 80px 124px;
			min-height: 650px;
		}
	}

	.goods {
		margin-bottom: 0;
		padding: 0;
		background: transparent;

		&__item {
			background: #f8f8f8;
		}
	}
}
