.intro {
	position: relative;
	z-index: 5;
	display: flex;
	align-items: flex-end;
	margin-bottom: vm(10);
	padding: vm(28) 0;
	height: vm(222);
	color: $color-white;

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		overflow: hidden;
		width: 100%;
		height: 100%;
		background: 50% 50% no-repeat;
		background-size: cover;

		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: 5;
			width: 100%;
			height: 100%;
			box-shadow: inset 0 vm(40) vm(120) rgba(0, 0, 0, 0.5);
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));

			@include desktop {
				box-shadow: inset 0 vw(40) vw(120) rgba(0, 0, 0, 0.5);
			}

			@include desktop-xl {
				box-shadow: inset 0 40px 120px rgba(0, 0, 0, 0.5);
			}
		}

		video {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__title {
		margin: 0;
		padding: 0 vm(20);
		max-width: vm(200);
		font-weight: 700;
		font-size: vm(22);
		line-height: vm(30);

		@include desktop {
			padding: 0;
			max-width: vw(850);
			font-size: vw(96);
			line-height: vw(96);
		}

		@include desktop-xl {
			max-width: 850px;
			font-size: 96px;
			line-height: 96px;
		}
	}

	@include desktop {
		margin-bottom: vw(19);
		padding: vw(110) 0;
		height: 100vh;
	}

	@include desktop-xl {
		margin-bottom: 19px;
		padding: 110px 0;
	}
}
